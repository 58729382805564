import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import Header from './Header';

const ChartContainer = styled.div`
    box-sizing: border-box;
    height: 450px;
    margin-top: 25px;
    padding: 0 15px;
`;

// COMPONENTE PARA MOSTRAR EL GRAFICO CIRCULAR
const PieChartComponent = ({ data }) => {

    // COLORES DE LAS PORCIONES
    const COLORS = ['#add8e6', '#b19cd9', '#ffb6a7', '#ffd966', '#8fd8a8', '#fde8cd', '#f4a6a7', '#89c4f4', '#c3c6c9'];

    return (
        <>
            <Header titleFontSize={21} title={'Strategies Employed'} />
            <ChartContainer>
                <ResponsiveContainer height="100%">
                {data && data.length > 0 ? (
                    <PieChart>
                        <Pie
                            data={data}
                            labelLine={false}
                            outerRadius={170}
                            innerRadius={100}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip
                        formatter={(value, name) => [`${value}`, name]}
                        />
                        <Legend iconSize={25}
                            formatter={(value) => <strong style={{ color: 'black' }}>{value}</strong>}
                        />
                    </PieChart>
                ) : (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        No interventions for this period.
                    </div>
                )}
                </ResponsiveContainer> 
            </ChartContainer>
        </>
    );
};

export default PieChartComponent;
