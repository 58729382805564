import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Menu from '../components/Menu';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import Modal from '../components/Modal';
import { HelpOutline } from '@mui/icons-material';
import { IconButton, Popover, Typography } from '@mui/material';
import Spinner from '../components/Spinner';
import useVerifyTwitterUrl from '../utils/useVerifyTwitterUrl';

const HeaderContent = styled.div`
    margin-top: 50px;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px; 
`;

const Form = styled.form`
    width: 450px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    @media (max-width: 768px) {
        width: 70%;
    }
`;

const Title = styled.h2`
    margin-right: 15px;
    margin-bottom: 20px;
    color: #3498db;
    text-align: center;
`;

const Input = styled.input`
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 0.5px solid #ccc;
    @media (max-width: 768px) {
        width: 90%;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    margin-top: 5px;
    font-weight: lighter;
`;

const Button = styled.button`
    padding: 10px 20px;
    background-color: #1976d2;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    transition: background-color 1s; 
    cursor: pointer;

    &:hover {
        background-color: #1565c0; 
    }

    &:active {
        background-color: white; 
        color: #1976d2; 
    }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BringYourOwnTweet = () => {
    const [tweetURL, setTweetURL] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageModal, setMessageModal] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const { verifyUrl,  getTweetInfoFromTwitterUrl} = useVerifyTwitterUrl();


    const handleURLChange = (event) => {
        setTweetURL(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!verifyUrl(tweetURL)) {
            setErrorMessage('Please enter a valid Twitter or X URL.');
            return;
        }
        // Para extraer el username y Id del post
        const match = getTweetInfoFromTwitterUrl(tweetURL);

        if (match) {
            setLoading(true);

            const newProfileObj = {
                username: match[5],  // Captura el username
                addedManually: true,  // informa que el usuario es agregado mediante esta pantalla
            };
            const tweetId = match[6];  // Captura el ID del post

            try {
                //Llamada al endpoint para agregar y medir el post y crear el usuario toxico
                const response = await axios.post(`${apiUrl}/posts/addPostManually`, { tweetId, newProfileObj });
                const post = response.data;
                
                // Informa si se excedio del rate permitido
                if (post.code === 429) {
                     setMessageModal("Twitter rate limit exceeded, please try again in a few minutes.");
                } 
                // Informa Si fallo al calcualar los toxic Score
                else if (post.toxicScore === -100 || post.toxicPerspectiveScore === -100) {
                    setMessageModal("Error calculating toxic scores.");
                }
                // informa si por alguna razon no hay id del post
                else if (!post._id) {   
                    setMessageModal('Error loading Tweet.');                 
                }
                //va a tweetDashboard si no hay errores o si falla la generacion de TPC
                else {
                    window.location.href = `/tweet-dashboard/${post._id}`
                    return
                }
                setShowModal(true);

            } catch (error) {
                setMessageModal('Error loading Tweet.');
                setShowModal(true)
                console.error('Error al realizar la solicitud:', error);
            }
        }
        setErrorMessage('');
        setLoading(false);
    };

    // Funcion para cerrar el modal
    const handleCloseModal = () => {
        setMessageModal('');
        setTweetURL('');
        setShowModal(false);
    }

    const handleHelpButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpen(false); 
    };


    return (
        <>
            <Menu />
            <HeaderContent>
                <Header title="Bring your own tweet" />
            </HeaderContent>
            <Container>
                {loading ? (
                    <Spinner/>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <TitleContainer>
                            <Title>Enter URL of the toxic tweet:</Title>
                            <IconButton aria-describedby={open ? 'help-popover' : undefined} onClick={(e) => handleHelpButtonClick(e)} style={{ color: '#3498db' }}>
                                <HelpOutline fontSize='small'/>
                            </IconButton>
                        </TitleContainer>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Typography sx={{ p: 1, fontSize: '14px', maxWidth: '200px' }}>Paste the URL of any problematic tweet here to import it and get counterspeaking support</Typography>
                        </Popover>
                        <Input
                            type="text"
                            placeholder="Enter tweet URL"
                            value={tweetURL}
                            onChange={handleURLChange}
                        />
                        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        <Button type="submit">Import</Button>
                    </Form>)}
                <Modal
                    openModal={showModal}
                >
                    <strong>{messageModal}</strong>
                    <br></br>
                    <Button onClick={handleCloseModal}>CLOSE</Button>
                </Modal>
            </Container>
        </>
    );
};

export default BringYourOwnTweet;
