import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Menu from '../components/Menu';
import {
    Button,
    TextField,
    // Chip,
    Autocomplete,
    Select,
    MenuItem,
    Stack,
    Avatar
} from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
//import EditIcon from '@mui/icons-material/Edit';
import Modal from '../components/Modal';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../features/authSlice';
import { useLocation } from 'react-router-dom';
import Spinner from '../components/Spinner';

const PageContainer = styled.div`
    margin-top: 50px;
`;
const Container = styled.div`
    height: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 30px;
`;
const EditableContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 650px;
    padding: 0 50px 50px 30px;
`
const ProfileInfo = styled.div`
    margin-top: 20px;
    margin-left: 20px;
    text-align: flex-start;
    display: flex;
    flex-direction: column;
`;
// const TwitterList = styled.div`
//     margin-top: 30px;
//     margin-left: 30px;
// `;
const Label = styled.span`
    font-weight: bold;
    font-size: 18px;
`
const Wrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0 0;

    &:first-child {
        margin: 0;
    }
`
const PartisanshipContent = styled.div``
const ChecklistContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const SuccessMessage = styled.span`
    font-size: 14px;
    color: green;
    margin: 10px 15px;
`

const MyAccount = () => {
    /*const [twitterAccounts, setTwitterAccounts] = useState([]);*/
    /*const [newAccount, setNewAccount] = useState('');*/
    const [topics, setTopics] = useState([]);
    const [stylesData, setStylesData] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [selectedStyles, setSelectedStyles] = useState([]);
    // const [openEditPartisanship, setOpenEditPartisanship] = useState(false);
    const [newPartisanship, setNewPartisanship] = useState('');
    // const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isTwitterLoginVisible, setisTwitterLoginVisible] = useState(false);
    const [isTwitterVerified, setIsTwitterVerified] = useState(false);
    const [connectionStatusMessage, setConnectionStatusMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [userProfileSummary, setUserProfileSummary] = useState('');
    const [previousUserSummary, setPreviousUserSummary] = useState('');
    const [isUserSummaryEditable, setIsUserSummaryEditable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const location = useLocation();
    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch();

    const connectionMessage = "Connection established. The Detoxifier app can directly post to X/Twitter on your behalf, using this account. If you want to use a different X/Twitter account, disconnect this account and connect that other account.";

    const unconnectionMessage = "Connecting an X/Twitter account will allow the Detoxifier app to post directly to X/Twitter on your behalf, without having to switch between the two apps. By default, if you are already logged into an X/Twitter account in this browser, that account is the one which will be connected to your Detoxifier account.";

    useEffect(() => {
        getTopics();
        getStyles();
        updateCurrentUser();
        /*getTwitterAccounts();*/
    }, []);

    useEffect(() => {
        if(user.twitterToken && user.twitterTokenSecret){
            validateTwitterToken();
        } else {
            setisTwitterLoginVisible(true);
            setConnectionStatusMessage(unconnectionMessage);
        }
    }, [user.twitterToken]);

    useEffect(() => {
        // Detect when twitter auth redirects to our app
        const query = new URLSearchParams(location.search);
        if (query.get('twitterAuth') === 'success') {
          setShowModal(true);
        }
    }, [location]);

    useEffect(() => {
        if (user.userProfileSummary) {
            setUserProfileSummary(user.userProfileSummary);
            setPreviousUserSummary(user.userProfileSummary);
        }
    }, []);

    const validateTwitterToken = async () => {
        await axios.get(`${apiUrl}/twitterApi/validate-twitter-token`)
        .then(response => {
            setIsTwitterVerified(response.data.verified)
            setisTwitterLoginVisible(false);
            setConnectionStatusMessage(connectionMessage)
            setTimeout(() => {
                setConnectionStatusMessage("");
            }, 10000)
        })
        .catch(error => {        
            const status = error.response.status;
            const message = error.response.data.message;
    
            switch (status) {
                case 401:
                    setisTwitterLoginVisible(true);
                    setConnectionStatusMessage(unconnectionMessage)
                    console.error(message);
                    break;
                case 500:
                    console.error(message);
                    break;
                default:
                    console.error('An unexpected error occurred');
                    break;
            }
        });
    }

    const handleTwitterLogin = () => {
        window.location.href = `${apiUrl}/auth/twitter?returnTo=${encodeURIComponent(location.pathname)}`;
    };

    const handleUnlinkTwitter = async () => {
        try {
            const res = await axios.post(`${apiUrl}/twitterApi/unlink`)
            const updateUserData = {
                mainTwitterAccount: "",
                twitterToken: "",
                twitterTokenSecret: ""
            }
            dispatch(updateUser(updateUserData));
            setisTwitterLoginVisible(true);
            setConnectionStatusMessage(unconnectionMessage)
        } catch(err) {
            console.error('Failed to remove your Twitter account crendentials.', err);
        }
    }

    const updateCurrentUser = async () => {
        try {
            const res = await axios.get(`${apiUrl}/users/${user._id}`)
            const newUser = res.data;
            const updateUserData = {
                mainTwitterAccount: newUser.mainTwitterAccount,
                twitterToken: newUser.twitterToken,
                twitterTokenSecret: newUser.twitterTokenSecret,
                verified: isTwitterVerified         //guardo en el state si la cuenta de twitter es verificada
            }
            dispatch(updateUser(updateUserData));
        } catch(err) {
            console.error('Failed to update user.', err)
        }
    }

    /*const handleAddAccount = () => {
        if (newAccount === '') {
            setErrorMessage('Account name cannot be empty.');
            return;
        }
        
        const accountExists = twitterAccounts.includes(newAccount);
        if (accountExists) {
            setErrorMessage('This account already exists.');
            return;
        }

        setErrorMessage('')
    
        setTwitterAccounts([...twitterAccounts, newAccount]);
        setNewAccount('');
    };*/

    // const handleCloseEditPartisanship = () => {
    //     setOpenEditPartisanship(false);
    // };

    const handleChangeTopics = (event, newValue) => {
        setSelectedTopics(newValue);
    };

    const handleChangeStyles = (event, newValue) => {
        setSelectedStyles(newValue);
    };

    const getTopics = async () => {
        try {
            const res = await axios.get(`${apiUrl}/topics`)
            setTopics(res.data);
            if (user) {
                setSelectedTopics(res.data.filter(obj => user.topics.includes(obj._id)))
            }
        } catch (err) {
            console.error('Failed to fetch topics', err);
        }
    };

    /*const getTwitterAccounts = async () => {
        try {
            const res = await axios.get(`${apiUrl}/users/${user._id}`)
            setTwitterAccounts(res.data.twitterAccount);
        } catch(err) {
            console.error('Failed to fetch user data.', err);
        }
    };*/

    const getStyles = async () => {
        try {
            const res = await axios.get(`${apiUrl}/writingStyles/getStyles`);
            setStylesData(res.data);
            if (user) {
                setSelectedStyles(res.data.filter(obj => user.styles.includes(obj._id)))
            }
        } catch (err) {
            console.error('Failed to fetch styles: ', err)
        }
    };

    const saveProfile = async () => {
        let updateUserData = {
            id: user._id,
            username: user.username,
            newUserEmail: user.email,
            newUserRole: user.role,
            partisanship: newPartisanship ? newPartisanship : user?.partisanship,
            /*twitterAccounts: twitterAccounts,*/
            topics: selectedTopics.map(obj => obj._id),
            styles: selectedStyles.map(obj => obj._id)
        }
        try {
            await axios.patch(`${apiUrl}/users/`, updateUserData)
            dispatch(updateUser(updateUserData));
            setSuccessMessage('Profile has been saved.')
        } catch (err) {
            console.error('Failed to update user info.', err);
        }
    }

    const handleGenerateProfile = async () => {
        try {
            setIsLoading(true);   
            const res = await axios.post(`${apiUrl}/users/generateUserProfileSummary`, { userId: user._id })
            if (res.data?.statusCode === 429) {
                setErrorModal(true);
                setModalMessage(`There was a problem trying to generate your profile. Please, wait some minutes and try connecting your Twitter account again or you can write your own profile.`);
                setShowModal(false);
            } else if (res.data?.statusCode === 204) {
                setErrorModal(true);
                setModalMessage(res.data?.body)
                setShowModal(false)
            } else {
                setUserProfileSummary(res.data?.userProfileSummary);
                dispatch(updateUser({userProfileSummary: res.data?.userProfileSummary}));
                setShowModal(false);
            }
        } catch (err) {
            console.error('Failed to fetch user profile summary.', err);
        } finally {
            setIsLoading(false)
        }
    }

    const handleEditUserSummary = async () => {
        setPreviousUserSummary(userProfileSummary);
        setIsUserSummaryEditable(true);
    }

    const handleCancelEdit = () => {
        setUserProfileSummary(previousUserSummary);
        setIsUserSummaryEditable(false);
      };

    const handleSaveUserSummary = async () => {
        try {
            setIsUserSummaryEditable(false);
            const res = await axios.post(`${apiUrl}/users/updateUserProfileSummary`,
                {
                    userId: user._id,
                    profile: userProfileSummary
                }
            );
            dispatch(updateUser({userProfileSummary: userProfileSummary}));
        } catch (err) {
            console.error('Error ocurred while saving user profile summary.', err);
        }
    }

    const handleCloseErrorModal = async () => {
        setErrorModal(false);
    }

    return (
        <>
            <Menu />
            <PageContainer>
                <Header title='My account'/>
                <Container>
                    <ProfileContainer>
                        {user && (
                            <>
                                <Avatar
                                    src={user?.picture}
                                    alt={user?.name}
                                    style={{ width: '150px',
                                             height: '150px',
                                             marginLeft: '10px',
                                             boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)'}}
                                />
                                <ProfileInfo>
                                    <strong style={{ marginTop: '5px' }}>Username:</strong>
                                    <span style={{ marginTop: '3px' }}>{user.username}</span>
                                    <strong style={{ marginTop: '15px' }}>Role:</strong>
                                    <span style={{ marginTop: '3px' }}>{user.role === 'admin' ? 'Administrator' : 'Counterspeaker'}</span>
                                    <strong style={{ marginTop: '15px' }}>Email:</strong>
                                    <span style={{ marginTop: '3px' }}>{user.email}</span>
                                </ProfileInfo>
                                <SuccessMessage>{successMessage}</SuccessMessage>
                            </>
                        )}
                    </ProfileContainer>
                    <EditableContentWrapper>
                        <Wrapper>
                        { isTwitterLoginVisible ? (
                            <>
                                <Label>
                                    Connect your Twitter account:
                                </Label>
                                <Button variant="contained" onClick={handleTwitterLogin}>Twitter Connect</Button>
                            </>
                        ) : (
                            <>
                                <Label>
                                    <span>You're connected as: </span> 
                                    <a target="_blank" href={`https://x.com/${user.mainTwitterAccount}`}>
                                     {user.mainTwitterAccount}
                                    </a>
                                </Label>
                                <Button onClick={handleUnlinkTwitter} variant="contained">Disconnect Twitter Account</Button>
                            </>
                        )}
                        </Wrapper>
                        <div style={{ paddingTop: "12px" }}>
                            {connectionStatusMessage &&
                            <ErrorOutlineOutlinedIcon style={{ color: '#3498db', paddingRight: '3px', fontSize: '22px', marginBottom: '-4px', marginLeft: '-2px' }}>
                            </ErrorOutlineOutlinedIcon>
}
                            {connectionStatusMessage}
                        </div>
                        <Label style={{ marginTop: '25px' }}>
                            Twitter account description:
                        </Label>
                        <div style={{ paddingTop: '10px' }}>
                            <textarea
                                value={userProfileSummary}
                                onChange={(event) => setUserProfileSummary(event.target.value)}
                                style={{ width: '100%', height: '95px', padding: '10px 15px', boxSizing: 'border-box', resize:'none' }}
                                disabled={!isUserSummaryEditable}
                                placeholder= {isTwitterLoginVisible? "Please connect your Twitter account to automatically generate your profile." : "Generate a description with the button."}
                            />
                            <Stack direction="row" spacing={2} style={{ marginTop: '8px' , display:"flex"}}>
                                {isLoading ? (
                                    <Spinner/>
                                ) : (
                                    <>
                                        {!isTwitterLoginVisible && !isUserSummaryEditable && (
                                            <Button variant="contained" color="primary" onClick={handleGenerateProfile}>
                                                Generate Description
                                            </Button>
                                        )}
                                        {!isUserSummaryEditable ? (
                                            <Button variant="contained" color="primary" onClick={handleEditUserSummary}>
                                                Edit
                                            </Button>
                                        ) : (
                                            <>
                                                <Button variant="contained" color="primary" onClick={handleSaveUserSummary}>
                                                    Save
                                                </Button>
                                                <Button variant="contained" color="secondary" onClick={handleCancelEdit}>
                                                    Cancel
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </Stack>
                        </div>
                        <Wrapper style = {{borderTop: "1px solid #b0b0b0", paddingTop:"15px"}}>
                            <Label>
                                Select your partisanship:
                            </Label>
                            <PartisanshipContent>
                                <Select
                                    autoFocus
                                    value={newPartisanship ? newPartisanship : user?.partisanship}
                                    onChange={(e) => { setNewPartisanship(e.target.value) }}
                                    fullWidth
                                >   
                                    <MenuItem value='center'>Center</MenuItem>
                                    <MenuItem value='left'>Left</MenuItem>
                                    <MenuItem value='right'>Right</MenuItem>
                                </Select>
                            </PartisanshipContent>
                            {/* <EditIcon
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: '5px',
                                    fontSize: 'medium',
                                }}
                                onClick={() => setOpenEditPartisanship(true)}
                            />*/}
                        </Wrapper>
                        {/*<TwitterList>
                            <TextField
                                error={!!errorMessage}
                                helperText={errorMessage}
                                label='Twitter Accounts'
                                value={newAccount}
                                onChange={(e) => {setNewAccount(e.target.value); setErrorMessage('')}}
                                style={{ marginLeft: '10px' }}
                            />
                            <Button
                                variant='contained'
                                onClick={handleAddAccount}
                                sx={{mt: 1, ml: 1}}
                            >
                                ADD ACCOUNT
                            </Button>
                            <div>
                                {twitterAccounts.map((account, index) => (
                                    <Chip
                                        key={index}
                                        label={account}
                                        onDelete={() => {
                                            setTwitterAccounts(
                                                twitterAccounts.filter(
                                                    (_, i) => i !== index
                                                )
                                            );
                                        }}
                                        style={{
                                            margin: '5px',
                                            backgroundColor: '#d7e3fc',
                                        }}
                                    />
                                ))}
                            </div>
                            </TwitterList>*/}
                       { /*<Wrapper>    OCULTO MOMENTANEAMENTE SELECT TOPICS y STYLES hasta darles uso
                            <Label>Select your topics: </Label>
                            <ChecklistContainer>
                                <Autocomplete
                                    multiple
                                    options={topics}
                                    disableCloseOnSelect
                                    filterSelectedOptions
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={selectedTopics}
                                    value={selectedTopics}
                                    onChange={handleChangeTopics}
                                    style={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label='Topics' />
                                    )}
                                />
                            </ChecklistContainer>
                        </Wrapper>
                        <Wrapper>
                        <Label>Select your styles: </Label>
                            <ChecklistContainer>
                                <Autocomplete
                                    multiple
                                    options={stylesData}
                                    disableCloseOnSelect
                                    filterSelectedOptions
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={selectedStyles}
                                    value={selectedStyles}
                                    onChange={handleChangeStyles}
                                    style={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label='Styles' />
                                    )}
                                />
                            </ChecklistContainer>
                        </Wrapper>*/}
                        <Button sx={{ mt: 2, width: '150px' }} onClick={saveProfile} variant="contained">Save Changes</Button>
                    </EditableContentWrapper>
                </Container>
            </PageContainer>
            {showModal && (
                <Modal
                    openModal={showModal}
                    showActions={true}
                    handleSubmit={handleGenerateProfile}
                    buttonAction={'OK'}
                    isDisabled={isLoading}
                    handleCloseModal={() => setShowModal(false)}
                >
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <p>
                            Your Twitter account has successfully been connected. <br />
                            Do you want your user profile to be automatically generated?
                        </p>
                    )}
                </Modal>

            )}
            {errorModal && (
                <Modal
                    openModal={errorModal}
                    maxwidth={'450px'}
                >
                    <>
                        <p>{modalMessage}</p>
                        <Button onClick={handleCloseErrorModal}>OK</Button>
                    </>
                </Modal>
            )}
            {/* <Modal
                openModal={openEditPartisanship}
                handleCloseModal={handleCloseEditPartisanship}
                title={'Edit Partisanship'}
                showActions={false}
            >

                <Button variant="contained" sx={{ mt: 2 }} onClick={() => setOpenEditPartisanship(false)}>Cancel</Button>
            </Modal> */}
        </>
    );
};

export default MyAccount;
