import { userLogin } from './authActions';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    isAuthenticated: false,
    isSessionExpired: false,
    isLoading: false,
};

export const userSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setLogout: (state) => {
            state.user = null;
            state.isAuthenticated = false;
            state.isSessionExpired = false;
        },
        setSessionExpired: (state) => {
            state.isSessionExpired = true;
        },
        updateUser: (state, action) => {
            state.user = { ...state.user, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.isLoading = true;
                state.isSessionExpired = false;
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = true;
                state.user = action.payload;
            })
            .addCase(userLogin.rejected, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = false;
                state.isSessionExpired = false;
                state.error = action.payload;
            });
    },
});

export const { setLogout, setSessionExpired, updateUser } = userSlice.actions;
export default userSlice.reducer;
