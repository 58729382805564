import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Icon,
    Box,
    Alert,
    Collapse,
    Snackbar
} from '@mui/material';
import { hide, clear } from '../../features/toastSlice';
import PropTypes from 'prop-types';

const TOAST_TIMEOUT = 6000;

export const CustomToast = ({ id, type, title, message, autodismissTime = TOAST_TIMEOUT}) => {

    const dispatch = useDispatch();
    const toast = useSelector(state => state.toasts.list.find(toast => toast.id === id));
    const isVisible = toast?.visible;

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                dispatch(hide({id}));
            }, autodismissTime);

            return () => clearTimeout(timer);
        }
    }, [id, isVisible, dispatch]);

    useEffect(() => {
        if (!isVisible) {
            dispatch(clear({id}));
        }
    }, [id, isVisible, dispatch]);

    const handleClose = () => {
        dispatch(hide({id}));
    };

    return (
        <Snackbar open={isVisible} autoHideDuration={autodismissTime} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert severity={type} onClose={handleClose}>
                    <div>
                        {title && <div><b>{title}</b></div>}
                        {message && <div dangerouslySetInnerHTML={{ __html: message }} />}
                    </div>
            </Alert>
        </Snackbar>    
    );
};

CustomToast.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    autodismiss: PropTypes.number,
};