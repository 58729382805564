import styled from 'styled-components';
import Header from './Header';
import ToxicTweet from './ToxicTweet';
import useVerifyTwitterUrl from '../utils/useVerifyTwitterUrl'
import { Link } from 'react-router-dom';

const WhiteBoxContainer = styled.div`
display: flex;
flex-direction: column;
padding: 10px 40px;
@media (max-width: 768px) {
    margin-bottom: 30px
  }
`;

const LabelContainer = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 120px 20px;
  text-align: center;
  
  @media (max-width: 768px) {
    padding: 70px 20px;
  }
`;

const OtherPost = ({ interventionList= [] }) => {
    const { getTweetIdFromTwitterUrl } = useVerifyTwitterUrl();
    return (
    <>
      <Header title="Interventions" subtitle= "to this post" />
      {interventionList && interventionList?.length > 0 ? (
        interventionList.map((item) => {
            const tweetId = getTweetIdFromTwitterUrl(item.responseUrl);
            return (
              <WhiteBoxContainer key={item._id}>
                <ToxicTweet tweetId={tweetId} />
              </WhiteBoxContainer>
            );
          })
      ) : (
        <LabelContainer>
          <label>
          You will be the first person to respond to this tweet! If you would like to see and engage with other recent responses (to other tweets) you can view these responses on the <Link to="/my-impact" state={{ selectValue: 'all' }}>all impact screen</Link>
          </label>
        </LabelContainer>
      )}
    </>
  );
};

export default OtherPost;
