import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
`;

const Unauthorized = () => {

    return (
        <Container>
            <h2>You don't have access to this content.</h2>
            <Link to="/">Go back</Link>
        </Container>
    );
};

export default Unauthorized;
