import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from 'recharts';
import styled from 'styled-components';

const ChartWrapper = styled.div`
    margin: 40px auto;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    height: 200px;
    width: 65%;

    &:before {
        content: '';
        float: none;
        clear: both;
    }
`;


const Chart = ({ data, grid, dataKey, stroke }) => {
    return (
        <ChartWrapper>
            <ResponsiveContainer width='100%' height='100%'>
                <LineChart label="Tweets" data={data}>
                    <XAxis dataKey='_id' />
                    <YAxis />
                    <Line type='monotone' dataKey={dataKey} stroke={stroke} />
                    <Tooltip />
                    <Legend />
                    {grid && <CartesianGrid strokeDasharray='5 5' />}
                </LineChart>
            </ResponsiveContainer>
        </ChartWrapper>
    );
};

export default Chart;
