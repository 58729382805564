import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HeaderContainer = styled.div`
text-align: flex-start;
`;

const Title = styled.h1`
font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '28px'};
color: #3498db;
margin-top: 0px;
margin-bottom: 2px;
margin-left: 40px;
`;

const SeparatorLine = styled.div`
width: 70%;
height: 1px;
background-color: #3498db;
margin-left: 0px;
`;

const Subtitle = styled.h2`
font-size: 18px;
color: #3498db;
margin-left: 40px;
margin-top: 5px;
`;

const Header = ({ title, subtitle, titleFontSize }) => {

return (
<HeaderContainer>
<Title fontSize={titleFontSize}>{title}</Title>
<SeparatorLine />
{subtitle && <Subtitle>{subtitle}</Subtitle>}
</HeaderContainer>
);
};

Header.propTypes = {
title: PropTypes.string.isRequired,
subtitle: PropTypes.string,
};

export default Header;