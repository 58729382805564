import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../components/Modal';
import { CheckCircle, HelpOutline } from '@mui/icons-material';
import { IconButton, Popover, Typography, Button } from '@mui/material';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Spinner from '../components/Spinner';
import WhiteBox from '../components/WhiteBox'; // Importa el componente WhiteBox
import useVerifyTwitterUrl from '../utils/useVerifyTwitterUrl';
import { apiUrl, removeQuotes } from '../utils/common';
import trackEvent from '../utils/trackEvent';


const HeaderContent = styled.div`
    margin-top: 50px;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px; 
`;

const Form = styled.form`
    width: 450px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    @media (max-width: 768px) {
        width: 70%;
    }
`;

const Title = styled.h2`
    margin-right: 15px;
    margin-bottom: 20px;
    color: #3498db;
    text-align: center;
`;

const Input = styled.input`
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 0.5px solid #ccc;
    @media (max-width: 768px) {
        width: 90%;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    margin-top: 5px;
    font-weight: lighter;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResponseContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    gap: 10px; /* Espacio entre cajas */
    margin-bottom: 10px;
`;
const SuccessMessage = styled.div`
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0 20px 0;
`;
const EditableText = styled.textarea`
    box-sizing: border-box;
    font-size: 14px;
    line-height: 21px;
    min-height: 200px;
    padding: 10px;
    resize: vertical;
    width: 90%;
`
const EditButtonsWrapper = styled.div`
    margin: 15px 0 15px 0;
    text-align: center;

    button:last-child {
        margin-left: 20px;
    }
`
const NoTwitterAccountMessage = styled.p`
    color: red;
    font-size: 12px;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
`

const BoostingCounterspeaking = () => {
    const user = useSelector((state) => state.auth.user);
    const { verifyUrl, resetError, getTweetIdFromTwitterUrl } = useVerifyTwitterUrl();
    const [tweetURL, setTweetURL] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageModal, setMessageModal] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [supportResponses, setSupportResponses] = useState([]); // Estado para almacenar las respuestas generadas
    const [openModal, setOpenModal] = useState(false);
    const [tweetText, setTweetText] = useState('');
    const [editText, setEditText] = useState('');
    const [isEditableAnswer, setIsEditableAnswer] = useState(false);
    const [twitterPostId, setTwitterPostId] = useState(null);
    const [openManualTweetModal, setOpenManualTweetModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [tweetUrl, setTweetUrl] = useState('');


    const handleURLChange = (event) => {
        setTweetURL(event.target.value);
    };

    const handleSubmit = async (event) => {
        setSupportResponses([]);
        event.preventDefault();

        if (!verifyUrl(tweetURL)) {
            setErrorMessage('Please enter a valid Twitter or X URL.');
        } else {
            setLoading(true);
            const tweetId = getTweetIdFromTwitterUrl(tweetURL);  // Retorna el ID del post
            setTwitterPostId(tweetId);
            try {

                const response = await axios.post(`${apiUrl}/boostCounterSpeak`,  { tweetId } );
                const supportResponses = response.data

                if (!supportResponses.length) {
                    setMessageModal('No support responses generated.');
                    setShowModal(true);
                } else {
                    const quotesRemovedFromResponses = removeQuotes(supportResponses);
                    setSupportResponses(quotesRemovedFromResponses); // Almacena las respuestas generadas en el estado
                }

            } catch (error) {
                setMessageModal('Error loading Tweet.');
                setShowModal(true);
                console.error('Error during the request:', error);
            }
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setMessageModal('');
        setTweetURL('');
        setShowModal(false);
    }

    const handleHelpButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleCloseTweetModal = () => {
        setEditText(tweetText);
        setIsEditableAnswer(false);
        setOpenModal(false);
        setOpenManualTweetModal(false);
        resetError();
    };

    const postBoostAnswer = async (text) => {
        if (user.mainTwitterAccount) {
            setEditText(text);
            setTweetText(text);
            setOpenModal(true);
        } else {
            const twitterLink = `https://x.com/intent/tweet?text=${encodeURIComponent(
                text
            )}&in_reply_to=${twitterPostId}`;
            window.open(twitterLink, '_blank');
        }
    };

    const handleEditAnswer = () => {
        setEditText(tweetText)
        setIsEditableAnswer(true)
    }

    const handleEditTextChange = (e) => {setEditText(e.target.value)}

    const cancelEditAnswer = () => {
        setEditText(tweetText);
        setIsEditableAnswer(false);
    }

    const saveEditAnswer = () => {
        setTweetText(editText);
        setIsEditableAnswer(false);
    }

    const handleSubmitTweet = async () => {
        try {
            const tweet = await axios.post(`${apiUrl}/twitterApi/post-tweet`, { tweetText, twitterPostId });
            if(tweet){
                const tweetUrlId = tweet.data?.detail?.data?.id;
                setIsSuccess(true);
                setTweetUrl(`https://x.com/${user.mainTwitterAccount}/status/${tweetUrlId}`);
            }
        } catch (error) {
            console.error('Error posting tweet:', error);
        }
    }

    const handleTracking = (path) => {
        trackEvent('navigation', path, user.email );
    };

    return (
        <>
            <Menu />
            <HeaderContent>
                <Header title="Support an anti-toxic tweet" />
            </HeaderContent>
            <Container>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <Form onSubmit={handleSubmit}>
                            <TitleContainer>
                                <Title>Enter URL of the tweet to support:</Title>
                                <IconButton aria-describedby={open ? 'help-popover' : undefined} onClick={(e) => handleHelpButtonClick(e)} style={{ color: '#3498db' }}>
                                    <HelpOutline fontSize='small' />
                                </IconButton>
                            </TitleContainer>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Typography sx={{ p: 1, fontSize: '14px', maxWidth: '200px' }}>Paste the URL of an anti-toxic or bridging tweet to get recommendations of supportive replies</Typography>
                            </Popover>
                            <Input
                                type="text"
                                placeholder="Enter tweet URL"
                                value={tweetURL}
                                onChange={handleURLChange}
                            />
                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                            <Button type="submit" variant='contained'>get supportive replies</Button>
                        </Form>
                    </>
                )}
                <Modal
                    openModal={showModal}
                >
                    <strong>{messageModal}</strong>
                    <br></br>
                    <Button onClick={handleCloseModal}>CLOSE</Button>
                </Modal>
            </Container>
            {supportResponses.length > 0 && (
                <>
                {!user.mainTwitterAccount && (
                    <NoTwitterAccountMessage>If you want to post a reply automatically. Please, go to 'My Account' and link your Twitter account.</NoTwitterAccountMessage>
                )}
                <ResponseContainer>
                    {supportResponses.map((response, index) => (
                        response && (
                            <WhiteBox key={index} buttonText="Select" text={response} onButtonClick={() =>
                                postBoostAnswer(response)
                            }>
                                
                            </WhiteBox>
                        )
                    ))}
                </ResponseContainer>
                </>
            )}
            <Modal
                openModal={openModal}
                title={isSuccess ? '' : 'Use this reply'}
                showActions={isSuccess ? false : true}
                handleSubmit={handleSubmitTweet}
                handleCloseModal={handleCloseTweetModal}
                buttonAction={'Confirm'}
                maxwidth={'600px'}
                isDisabled={isEditableAnswer}
            >
                     {isSuccess && !openManualTweetModal ? (
                    <>
                        <SuccessMessage>
                            <CheckCircle color='success' fontSize='large' />
                            <br />
                            Success! Your answer has been posted.
                            {tweetUrl && <a href={tweetUrl} target="_blank" rel="noopener noreferrer">See your Tweet</a>}
                            <br />
                            <Link to={'/recent-toxic-content-dashboard'} onClick={() => handleTracking('/recent-toxic-content-dashboard')}>Go to Recent Content</Link>
                        </SuccessMessage>
                    </>
                ) : (
                    <>
                        {isEditableAnswer ? (
                            <>
                                <EditableText onChange={handleEditTextChange} value={editText} />
                                <EditButtonsWrapper>
                                    <Button sx={{height: '30px'}} variant='outlined' onClick={cancelEditAnswer}>Cancel</Button>
                                    <Button sx={{height: '30px'}} variant='contained' disabled={editText === tweetText} onClick={saveEditAnswer}>Save</Button>
                                </EditButtonsWrapper>
                            </>
                        ) : (
                            <>
                                <Typography>{tweetText}</Typography>
                                <EditButtonsWrapper>
                                    <Button sx={{height: '30px'}} variant='outlined' onClick={handleEditAnswer}>Edit Answer</Button>
                                </EditButtonsWrapper>
                            </>
                        )}
                    </>
                )}
            </Modal>
        </>
    );
};

export default BoostingCounterspeaking;