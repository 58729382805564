import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import placeholderImg from '../assets/images/user-placeholder.png';

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top:10px;
  margin-left: 10px;
`;


const UserName = styled.div`
  font-weight: bold;
  margin-left: 10px;
  font-size: 16px;
`;

const UserInfo = ({ avatar, name }) => {
    return (
        <UserInfoWrapper>
            <Avatar src={avatar || placeholderImg}/>
            <UserName>{name}</UserName>
        </UserInfoWrapper>
    );
};

export default UserInfo;
