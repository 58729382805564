import styled from 'styled-components';
import UserOrPostSummary from './UserOrPostSummary';
import {PersonOutlineOutlined, CampaignOutlined, Event, LocationOn, PeopleOutline} from '@mui/icons-material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';


const StyledBox = styled(({ buttonText, boxDescription, ...rest }) => <div {...rest} />)`
  background-color: #f5f5f5; /* Fondo gris más claro VER*/ 
  border: 0.5px solid #3498db; /* Borde Azul VER*/
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); 
  padding-top: ${({boxDescription }) => (!boxDescription ? '20px' : '8px')};
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: ${({ buttonText, boxDescription }) => (buttonText ? '45px' : (!boxDescription ? '20px' : '8px'))};
  flex: 1;
  position: relative;
  min-width: 200px;
`;

const WhiteContainer = styled.div`
  background-color: #fff;
  padding: 1px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  
`;

const BlueButton = styled.button`
  position: absolute;
  bottom: 10px; 
  right: 20px; 
  background-color: #3498db; /* Fondo azul VER*/
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-top: 10px; 
  height:25px;  
  font-weight: bold;
  &:enabled:active {
    background-color: white; 
    color: #3498db; /* Color azul VER*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  &:disabled {
    background-color: #ddd;
    cursor: default;
  }
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding-bottom:5px;
  padding-left:5px;
  align-items: center;
  display: flex;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const TextWithIcon = styled.div`
  display: flex;
  align-items: center;
  color: #3498db;
  font-weight: bold;
`;

const WhiteBox = ({ tpc, text, buttonText, onButtonClick, toxicLevel, boxDescription, Followers, Following, creation, site, place, birth, toxicPerspectiveScore, isDisabled, toxicScoreMax }) => {
  const handleButtonClick = () => {
    onButtonClick();
  };

  return (
    <StyledBox buttonText={buttonText} boxDescription={boxDescription}>
      {(toxicPerspectiveScore !== undefined && tpc) && (
        <UserOrPostSummary
          toxicLevel={toxicLevel}
          toxicPerspectiveScore={toxicPerspectiveScore}
          toxicScoreMax={toxicScoreMax}
          tpc={tpc}
          showGptBar={true}
        />
      )}
      {boxDescription && (
        <Label>
          {boxDescription === "Audience summary" ? (
            <CampaignOutlined style={{ color: '#3498db', paddingRight: '12px' }} />
          ) : (
            <PersonOutlineOutlined style={{ color: '#3498db', paddingRight: '12px' }} />
          )}
          {boxDescription}
        </Label>
      )}
      <WhiteContainer>
        <p style={{whiteSpace: 'pre-wrap'}}>{text}</p>
        {site && (
          <FlexContainer>
            <TextWithIcon>            
              <InsertLinkIcon style={{ marginRight:'10px' }} />
              {site}
            </TextWithIcon>
          </FlexContainer>
        )}
        {place && (
          <FlexContainer>
            <TextWithIcon>
              <LocationOn style={{ marginRight:'10px' }} />
              {place}
            </TextWithIcon>
          </FlexContainer>
        )}
        {birth && (
          <FlexContainer>
            <TextWithIcon>
              <Event style={{ marginRight:'10px' }} />
              {birth}
            </TextWithIcon>
          </FlexContainer>
        )}
        {Following && (
          <FlexContainer>
            <TextWithIcon>
              <PeopleOutline style={{ marginRight:'10px' }}/>
              Following: {Following}
            </TextWithIcon>
          </FlexContainer>
        )}
        {Followers && (
          <FlexContainer>
            <TextWithIcon>
              <PeopleOutline style={{ marginRight:'10px' }}/>
              Followers: {Followers}
            </TextWithIcon>
          </FlexContainer>
        )}
        {creation && (
          <FlexContainer>
            <TextWithIcon>
              <Event style={{ marginRight:'10px' }}/>
              Joined: {creation}
            </TextWithIcon>
          </FlexContainer>
        )}
      </WhiteContainer>
      {buttonText && <BlueButton disabled={isDisabled} onClick={handleButtonClick}>{buttonText}</BlueButton>}
    </StyledBox>
  );
};

export default WhiteBox;
