import { useState } from "react";

const useVerifyTwitterUrl = () => {
    const [verifyError, setVerifyError] = useState(null);

    const verifyUrl = (url) => {
        const pattern = new RegExp(/^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/\w+\/status\/\d+(\?s=\d+)?$/);

        if (!pattern.test(url)) {
            setVerifyError('Invalid URL. Please enter a valid URL.')
            return false;
        } else {
            setVerifyError(null)
            return true;
        }
    };

    const resetError = () => setVerifyError(null);

    const getTweetIdFromTwitterUrl = (tweetURL) => {
        const pattern = new RegExp(/^((https?:\/\/)?(www\.)?(twitter\.com|x\.com))\/(@?[a-zA-Z0-9_]+)\/status\/(\d+(\?s=\d+)?)$/);

        const match = tweetURL.match(pattern);

        return match[6];
    }


    const getTweetInfoFromTwitterUrl = (tweetURL) => {
        const pattern = new RegExp(/^((https?:\/\/)?(www\.)?(twitter\.com|x\.com))\/(@?[a-zA-Z0-9_]+)\/status\/(\d+(\?s=\d+)?)$/);
                  
        const match = tweetURL.match(pattern);

        return match;
    }

    return { verifyError, verifyUrl, resetError, getTweetIdFromTwitterUrl, getTweetInfoFromTwitterUrl };
};

export default useVerifyTwitterUrl;