import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoutes from './utils/privateRoutes';
import Home from './pages/Home';
import RecentContent from './pages/recentContent';
import TweetDashboard from './pages/TweetDashboard';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';
import CreateToxicProfile from './pages/CreateToxicProfile';
import PostAnalytics from './pages/PostAnalytics';
import UserAnalytics from './pages/UserAnalytics';
import AuthCallback from './pages/AuthCallback';
import UserScreen from './pages/UserScreen';
import Strategies from './pages/ResponsesStrategies';
import Categories from './pages/TPCCategories';
import MeasureToxicity from './pages/MeasureToxicity';
import AdminSettings from './pages/AdminSettings';
import Interventions from './pages/Interventions';
import AddTweet from './pages/AddTweet';
import Unauthorized from './pages/Unauthorized';
import './utils/interceptor';
import LoginFailed from './pages/LoginFailed';
import MyAccount from './pages/MyAccount';
import SessionExpiredAlert from './components/SessionExpiredAlert';
import Topics from './pages/Topics';
import WritingStyles from './pages/WritingStyles';
import BoostingCounterspeaking from './pages/BoostingCounterspeaking';
import MyImpact from './pages/MyImpact';
import Articles from './pages/ArticlesView';
import Toasts from './components/toast';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes allowedRoles={['admin', 'collaborator']} />}>
          <Route path="/" element={<Home />} />
          <Route path="/recent-toxic-content-dashboard" element={<RecentContent />} />
          <Route path="/add-tweet" element={<AddTweet />} />
          <Route path="/tweet-dashboard/:id" element={<TweetDashboard />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/boosting-counterspeaking" element={<BoostingCounterspeaking />} />
          <Route path="/my-impact" element={<MyImpact />} />
          <Route path="/articles/:tag" element={<Articles />} />
        </Route>
        <Route element={<PrivateRoutes allowedRoles={['admin']} />}>
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/admin/settings" element={<AdminSettings />} />
          <Route path="/tweet-dashboard/:id" element={<TweetDashboard />} />
          <Route path="/watchlist-profiles" element={<CreateToxicProfile />} />
          <Route path="/analytics-posts" element={<PostAnalytics />} />
          <Route path="/analytics-users" element={<UserAnalytics />} />
          <Route path="/user-screen" element={<UserScreen />} />
          <Route path="/responses-strategies" element={<Strategies/>} />
          <Route path="/tpc-categories" element={<Categories/>} />
          <Route path="/measure-toxicity" element={<MeasureToxicity/>} />
          <Route path="/interventions" element={<Interventions/>} />
          <Route path="/topics" element={<Topics />} />
          <Route path='/writing-styles' element={<WritingStyles />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/login/success" element={<AuthCallback />} />
        <Route path="/login/failed" element={<LoginFailed />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        
      </Routes>
      <SessionExpiredAlert />
      <Toasts />
    </Router>
  );
}

export default App;
