import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
    @media (max-width: 768px) {
        margin-top: 50%;
      }
`;

const Spinner = () => {
    return (
        <SpinnerContainer>
            <CircularProgress />
        </SpinnerContainer>
    );
};


export default Spinner;