import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Blog from '../components/Blog';

const HeaderContent = styled.div`
    margin-top: 50px;
`;

const Articles = () => {
    return (
        <>
            <Menu />
            <HeaderContent>
                <Header title="Articles" />
            </HeaderContent>
            <Blog/>
        </>
    );
};

export default Articles;
