import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import Header from './Header';

const ChartContainer = styled.div`
    box-sizing: border-box;
    height: 450px;
    margin-top: 25px;
    padding: 0 15px;
`;


// COMPONENTE PARA MOSTRAR EL GRAFICO DE BARRAS HORIZONTAL
const BarChartComponent = ({ data }) => {
    // Extracción de todas las estrategias posibles
    let allStrategies;
    if (data){
        allStrategies = data.reduce((acc, cur) => {
            Object.keys(cur).forEach(key => {
                if (key !== 'date' && !acc.includes(key)) {
                    acc.push(key);
                }
            });
            return acc;
        }, []);
    };

    const COLORS = ['#add8e6', '#b19cd9', '#ffb6a7', '#ffd966', '#8fd8a8', '#fde8cd', '#f4a6a7', '#89c4f4', '#c3c6c9'];

    return (
        <>
            <Header titleFontSize={21} title={'Interventions'} />
            <ChartContainer>
                <ResponsiveContainer width="98%" height="100%">
                    {data && data.length > 0 ? (
                        <BarChart layout="vertical" data={data}>
                            <XAxis type="number"/>
                            <YAxis dataKey="date" type="category" width={75}/>
                            <Tooltip />
                            {allStrategies.map((strategy, index) => (
                                <Bar key={strategy} dataKey={strategy} stackId="a" fill={COLORS[index % COLORS.length]} />
                            ))}
                        </BarChart>
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            No interventions for this period.
                        </div>
                    )}
                </ResponsiveContainer>
            </ChartContainer>
        </>
    );
};

export default BarChartComponent;
