import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Rectangle } from 'recharts';
import { IconButton, Dialog, DialogContent, Box, Typography } from '@mui/material';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const ImpactChart = ({ allUsers = false, userId, data, showLeaderBoard = false }) => {
    const [openZoom, setOpenZoom] = useState(false);

    const filteredData = Array.isArray(data)
    ? allUsers
    ? data
    : data.filter((entry) => entry.userData?._id === userId)
    : [];

    let minEngagement = filteredData.reduce((min, entry) => {
        const engagementValue = entry.monitorData?.metrics?.engagement ?? Infinity;
        return engagementValue > 0 && engagementValue < min ? engagementValue : min;
    }, Infinity) || 1;

    minEngagement = minEngagement === Infinity ? 1 : minEngagement;

    const processedData = filteredData.map((entry, index, array) => {
        const hasEngagement = typeof entry.monitorData?.metrics?.engagement === 'number';
        let engagementValue = hasEngagement ? entry.monitorData.metrics.engagement : minEngagement;

        engagementValue = Math.min(engagementValue, 1000);

        return {
            ...entry,
            engagementValue,
            displayDate: index > 0 && entry.date === array[index - 1].date ? '' : entry.date,
            interventionText: entry.interventionText || 'No text available',
            responseUrl: entry.responseUrl || '#',
            isPending: !hasEngagement,
        };
    });

    const handleZoom = () => setOpenZoom((prev) => !prev);

    const handleBarClick = (responseUrl) => {
        if (responseUrl) {
            window.open(responseUrl, '_blank');
        }
    };

    const ChartContent = () => (
        <div style={{ marginLeft: '-20px', width: '100%', height: '100%' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={processedData}
                    barCategoryGap="2%"
                    barGap={0}
                    margin={{ top: 10, right: 25, left: 0, bottom: 10 }}
                >
                    <XAxis
                        dataKey="displayDate"
                        tick={{
                            fontSize: 10,
                            angle: -45,
                            textAnchor: 'end',
                        }}
                        tickLine={false}
                        interval={0}
                    />
                    <YAxis dataKey="engagementValue" />
                    <Tooltip
                        cursor={{ fill: '#f5f5f5' }}
                        content={({ payload }) => {
                            if (payload && payload.length) {
                                const { payload: item } = payload[0];
                                const engagementValue = item.isPending ? 'pending' : item.engagementValue;

                                return (
                                    <div
                                        style={{
                                            background: '#fff',
                                            padding: '10px',
                                            border: '1px solid #ddd',
                                            maxWidth: '300px',
                                        }}
                                        onClick={() => handleBarClick(item.responseUrl)}
                                    >
                                        <div>
                                            <strong>Engagement:</strong> {engagementValue}
                                        </div>
                                        <div>
                                            <strong>Intervention Text:</strong> {item.interventionText}
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />

                    <Bar dataKey="engagementValue" shape={<CustomBarShape />} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );

    const CustomBarShape = (props) => {
        const { x, y, width, height, payload } = props;
        const fillColor = payload.isPending ? '#FFA500' : (payload.userData?._id === userId ? '#3498db' : '#C0C0C0');

        return (
            <g>
                <Rectangle
                    x={x}
                    y={0}
                    width={width}
                    height={props.y + props.height}
                    fill="transparent"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleBarClick(payload.responseUrl)}
                />
                <Rectangle x={x} y={y} width={width} height={height} fill={fillColor} onClick={() => handleBarClick(payload.responseUrl)} style={{ cursor: 'pointer' }} />
            </g>
        );
    };

    return (
        <>
            {processedData.length > 0 ? (
                <>
                    <div style={{ width: '100%', height: 300, marginTop: allUsers ? 70 : 10, position: 'relative' }}>
                        <IconButton
                            onClick={handleZoom}
                            style={{ position: 'absolute', right: 15, top: 5, zIndex: 10 }}
                        >
                            <ZoomOutMapOutlinedIcon />
                        </IconButton>
                        <ChartContent />
                    </div>

                    {/* Zoom */}
                    <Dialog open={openZoom} onClose={handleZoom} maxWidth="lg" fullWidth>
                        <DialogContent
                            style={{
                                background: 'white',
                                position: 'relative',
                                padding: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '90vh',
                                overflow: 'hidden'
                            }}
                        >
                            <Box position="absolute" top={10} right={10} zIndex={1000}>
                                <IconButton onClick={handleZoom} style={{ color: 'black' }}>
                                    <CloseOutlinedIcon />
                                </IconButton>
                            </Box>
                            <Box
                                width="95%"
                                height="90%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <ChartContent />
                            </Box>
                        </DialogContent>
                    </Dialog>
                </>
            ) : (
                !showLeaderBoard && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height={350}
                        width="96%"
                        bgcolor="#E0E0E0"
                        borderRadius={3}
                        marginTop={2}
                    >
                        <Typography variant="h6" color="textSecondary">
                            No interventions available
                        </Typography>
                    </Box>
                )
            )}
        </>
    );
};

export default ImpactChart;
