import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filters: null,
    savedFilters: false,
    pagination: { pageIndex: 0, pageSize: 10 }
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        updateFilters: (state, action) => {
            state.filters = action.payload;
            state.savedFilters = true;
        },
        clearFilters: (state, action) => {
            state.filters = action? action.payload : null;
            state.savedFilters = false;
        },
        updatePagination: (state, action) => {
            state.pagination = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = { pageIndex: 0, pageSize: 10 };
        }
    }
})

export const { updateFilters, clearFilters, updatePagination, clearPagination } = filtersSlice.actions;

export default filtersSlice.reducer;