import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomToast } from "./CustomToast";
import axios from 'axios';
import { show } from '../../features/toastSlice';
import { useLocation } from 'react-router-dom';

const Toasts = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const shownToasts = useRef(new Set()); // Track paths that have already shown a toast

    const handleErrorToast = (error, currentPath) => {
        if (shownToasts.current.has(currentPath)) {
            return; // Skip if a toast has already been shown for this path
        }

        let title = '';
        let message = '';
        
        if (error.response) {
            title = 'Error response from server';
            message = 'Bad Request';
        } else if (error.request) {
            title = 'No response received from server';
            message = 'The system is currently unable to fulfill this request. We will restore services as soon as possible.';
        } else {
            title = 'Error setting up the request';
            message = error.message;
        }

        // Dispatch the toast and add the path to the Set
        dispatch(show({
            type: 'error',
            title,
            message,
            path: currentPath,
        }));

        shownToasts.current.add(currentPath); // Mark this path as having shown a toast

        // Optionally remove the path from the Set after a certain timeout (e.g., 10 seconds)
        setTimeout(() => {
            shownToasts.current.delete(currentPath);
        }, 10000); // Adjust this timeout as necessary
    };

    axios.interceptors.response.use(
        function (response) {
            return response; 
        },
        function (error) {
            const currentPath = location.pathname;
            handleErrorToast(error, currentPath); // Use helper function with current path
            return Promise.reject(error);
        }
    );
      
    const toasts = useSelector((state) => state.toasts.list);

    if (!toasts.length) return null;

    return (
        <>
            {toasts.map(toast => (
                <CustomToast key={toast.id} {...toast} />
            ))}
        </>
    );
}
export default Toasts;
