import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { ArrowBack, Clear, GifBoxOutlined } from '@mui/icons-material';

const gf = new GiphyFetch('SKi7ViEPo7fyepQvPq7lVehFNjiVZjB9');

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
`;

const GifSelector = ({ onSelect, selectedGifUrl }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [categories, setCategories] = useState([]);
    const [showCategories, setShowCategories] = useState(true);
    const [ isGifSelected, setIsGifSelected ] = useState(false);

    const openModal = () => {
        setIsOpen(true);
        fetchCategories();
    };

    const closeModal = () => {
        setIsOpen(false);
        setShowCategories(true);
        setQuery('');
    };

    const fetchGifs = (offset) => gf.search(searchQuery, { offset, limit: 10 });

    const fetchCategories = async () => {
        try {
            const { data } = await gf.categories();
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleCategoryClick = (categoryName) => {
        setQuery(categoryName);
        setSearchQuery(categoryName);
        setShowCategories(false);
    };

    const handleBackToCategories = () => {
        setQuery('');
        setSearchQuery('');
        setShowCategories(true);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (query) {
                setSearchQuery(query);
                setShowCategories(false);
            } else {
                setShowCategories(true);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    useEffect(() => {
        if (!selectedGifUrl) {
            setIsGifSelected(false);
        }
    }, [selectedGifUrl]);

    return (
        <div>
            {!isGifSelected && (
                <IconButton onClick={openModal} aria-label="Add GIF">
                    <GifBoxOutlined fontSize="large" color="primary" />
                </IconButton>
            )}
            <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
                <DialogContent>
                    <SearchContainer>
                        {!showCategories && (
                            <IconButton
                                aria-label='Back'
                                onClick={handleBackToCategories}
                            >
                                <ArrowBack />
                            </IconButton>
                        )}
                        <TextField
                            label='Search GIFs'
                            variant='outlined'
                            fullWidth
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            InputProps={{
                                endAdornment:
                                    query && !showCategories ? (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={handleBackToCategories}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null,
                            }}
                        />
                    </SearchContainer>
                    {showCategories && categories.length > 0 ? (
                        <Box mt={2}>
                            <Typography variant='h6'>
                                Popular Categories
                            </Typography>
                            <Box display='flex' flexWrap='wrap' gap={1} mt={1}>
                                {categories.map((category) => (
                                    <Button
                                        key={category.name}
                                        variant='outlined'
                                        onClick={() =>
                                            handleCategoryClick(category.name)
                                        }
                                    >
                                        {category.name}
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    ) : (
                        <Grid
                            width={400}
                            columns={2}
                            fetchGifs={fetchGifs}
                            onGifClick={(gif, e) => {
                                e.preventDefault();
                                onSelect(gif.images.original.url);
                                setIsGifSelected(true);
                                closeModal();
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default GifSelector;
