export const apiUrl = process.env.REACT_APP_API_URL;

export const removeQuotes = (arr) => {
    return arr.map(str => {
        if (str.startsWith('"') && str.endsWith('"')) {
          return str.slice(1, -1);
        } else if (str.startsWith("'") && str.endsWith("'")) {
          return str.slice(1, -1);
        } else {
          return str;
        }
    });
}