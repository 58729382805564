import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background-color: #f5f5f5;
    border: 0.5px solid #3498db;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    position: relative;
    min-width: 200px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const WhiteContainer = styled.div`
    background-color: #fff;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: justify;
    width: 100%;
`;

const EditableAnswer = styled.textarea`
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    height: 120px;
    line-height: 1.2;
    margin: 0;
    padding: 10px;
    width: 100%;
    resize: none;
`;

const Button = styled.button`
  background-color: #3498db; /* Fondo azul VER*/
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-top: 10px; 
  height: 25px;
  width: 60px;
  font-weight: bold;
  &:enabled:active {
    background-color: white; 
    color: #3498db; /* Color azul VER*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  &:disabled {
    background-color: #ddd;
    cursor: default;
  }
`;

const CustomAnswer = ({ onSubmit, isDisabled }) => {
    const [answer, setAnswer] = useState('');

    const handleTextChange = (e) => {
        const text = e.target.value;
        setAnswer(text);
    };

    const handleSubmit = () => {
        onSubmit(answer);
    };

    return (
        <Container>
            <WhiteContainer>
                <EditableAnswer
                    value={answer}
                    onChange={handleTextChange}
                    maxLength={280}
                />
            </WhiteContainer>
            <Button disabled={answer.length === 0 || isDisabled} onClick={handleSubmit}>Select</Button>
        </Container>
    );
};

export default CustomAnswer;
