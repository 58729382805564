import styled, { css } from 'styled-components';
import { Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`

const ModalContent = styled(Paper)`
  box-sizing: border-box;
  padding: 25px 40px;
  border: 1px solid #3498db;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${props => props.maxwidth ? props.maxwidth : 'fit-content'};
  text-align: center;

  @media (max-width: 768px) {
    padding: 25px 20px;
    margin-top: 20px;
    max-width: 350px;
  }
`
const ModalTitle = styled.h2`
    font-size: 18px;
    text-transform: uppercase;
`
const ModalSubtitle = styled.h2`
    font-size: 14px;
    ${props => props.style && css`${props.style}`};
`
const ModalActions = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    & > button{
        &:first-child{
            margin-right: 20px;
        }
    }
`
const ModalWrapper = styled.div`
    min-width: 350px;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: #3498db;
  margin-right: 20px; 
  margin-top: 12px;
  &:hover {
      text-decoration: none;
  }
`;

const Modal = ({ openModal, children, title, subtitle, subtitleStyle, showActions, handleCloseModal, handleSubmit, isDisabled, buttonAction, maxwidth, extraActions }) => {
    if (!openModal) return null;

    return (
        <ModalOverlay>
            <ModalContent maxwidth={maxwidth} onClick={(e) => e.stopPropagation()}>
                {title && (<ModalTitle>{title}</ModalTitle>)}
                {subtitle && (<ModalSubtitle style={subtitleStyle}>{subtitle}</ModalSubtitle>)}
                <ModalWrapper>
                    {children}
                </ModalWrapper>
                {showActions &&
                    <ModalActions>
                        <StyledLink onClick={handleCloseModal} component="button">
                            CANCEL
                        </StyledLink>
                        {extraActions}
                        <Button variant="contained" disabled={isDisabled} color="primary" onClick={handleSubmit}>{buttonAction ?? 'Submit'}</Button>
                    </ModalActions>
                }
            </ModalContent>
        </ModalOverlay>
    );
};

export default Modal;
