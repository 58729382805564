import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Button, TextField, MenuItem, InputLabel, FormControl, Select, Box } from '@mui/material';
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import Header from '../components/Header';
import { apiUrl } from '../utils/common';
import Menu from '../components/Menu';
import Modal from '../components/Modal';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 0;
`;

const RedLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: red;
`;

const GreenLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: green;
`;

const AddStrategyForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin:20px 0;
`;

const BoxesContainer = styled.div`
display:flex;
flex-direction: row;
margin-bottom:20px;

@media (max-width: 768px) {
  flex-direction: column;
  margin-bottom:10px;
}
`;

const Strategies = () => {
    const [strategies, setStrategies] = useState([]);
    const [newName, setNewName] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [newUiDescription, setNewUiDescription] = useState('');
    const [onOff, setOnOff] = useState(false);
    const [editingId, setEditingId] = useState('');
    const [showModal, setShowModal] = useState(null);
    const [showModalConfirm, setShowModalConfirm] = useState(null);
    const [deletingRow, setDeletingRow] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        getStrategies();
    }, []);

    const handleNewName = (e) => {
        e.preventDefault();
    };

    const handleNewDescription = (e) => {
        e.preventDefault();
    };

    const handleNewUiDescription = (e) => {
        e.preventDefault();
    };

    async function getStrategies() {
        try {
            const response = await axios.get(`${apiUrl}/strategies/getStrategies`);
            const data = await response.data;
            setStrategies(data);
        } catch (error) {
            console.error('Error al obtener las estrategias:', error);
        }
    };

    const handleCreateStrategy = async (e) => {
        e.preventDefault();
        const newStrategyObj = {
            name: newName,
            description: newDescription,
            uiDescription: newUiDescription,
            disabled: onOff,
        };

        const existingStrategy = strategies.find(strategies => strategies.name.toLowerCase() === newStrategyObj.name.toLowerCase());
        if (!existingStrategy) {
            try {
                const response = await axios.post(`${apiUrl}/strategies/createStrategy`, newStrategyObj);
                if (response) {
                    getStrategies();
                    handleCancel();
                }
            } catch (error) {
                console.error('Error al realizar la solicitud:', error);
            }
        } else {
            setMessage("Strategy already created");
        }
    };

    const handleEdit = (row) => {
        setShowModal(true);
        const editingStrategy = row.original;
        setEditingId(row.original._id);
        setNewName(editingStrategy.name || '');
        setNewDescription(editingStrategy.description || '');
        setNewUiDescription(editingStrategy.uiDescription || '');
        setOnOff(editingStrategy.disabled);
    };

    const handleUpdateStrategy = async (e) => {
        e.preventDefault();

        const updatedStrategyObj = {
            strategyId: editingId,
            updatedStrategy: {
                name: newName,
                description: newDescription,
                uiDescription: newUiDescription,
                disabled: onOff,
            },
        };

        const otherStrategies = strategies.filter(s => s._id !== editingId);
        const existingStrategy = otherStrategies.find(s => s.name.toLowerCase() === newName.toLowerCase());
        if (!existingStrategy) {
        try {
            const response = await axios.patch(`${apiUrl}/strategies/`, updatedStrategyObj);

            if (response) {
                getStrategies();
                handleCancel();
            } else {
                console.error('Error al actualizar la estrategia:', response.statusText);
            }
        } catch (error) {
            console.error('Error al realizar la solicitud:', error);
        }
    } else {
        setMessage("Strategy already created");
    }
    };

 /*    const handleDelete = async (row) => {
        setShowModalConfirm(true);
        setDeletingRow(row);
    }; */

    const handleCancelDelete = () => {
        setDeletingRow(null);
        setShowModalConfirm(false);
    };

    const handleConfirmDelete = async () => {
        const strategyId = deletingRow.original._id;

        try {
            const response = await axios.delete(`${apiUrl}/strategies/${strategyId}`);

            if (response) {
                getStrategies();
                setShowModalConfirm(false);
            } else {
                console.error('Error al eliminar la estrategia:', response.statusText);
            }
        } catch (error) {
            console.error('Error al realizar la solicitud:', error);
        }
    };

    const handleCancel = () => {
        setNewName('');
        setNewDescription('');
        setNewUiDescription('');
        setEditingId('');
        setShowModal(false);
        setMessage('');
        setOnOff(false);
    };

    const handleModal = () => {
        setShowModal(true);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                id: 'name',
                header: 'Name',
                size: 50,                
            },
            {
                accessorKey: 'description',
                id: 'description',
                header: 'LLM Description',
                size: 250,               
            },
            {
                accessorKey: 'uiDescription',
                id: 'uiDescription',
                header: 'UI Description',
                size: 250, 
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <div style={{ overflowY: 'auto', maxWidth: '700px' }}>
                            {row.original.uiDescription}
                        </div>
                    </Box>
                ),              
            },
            {
                accessorKey: 'disabled',
                id: 'disabled',
                header: 'State',
                size: 40,
                Cell: ({ row }) => (
                    <>
                        {row.original.disabled ? <RedLabel>Disabled</RedLabel> : <GreenLabel>Enabled</GreenLabel>}
                    </>
                )
            },

            {
                accessorKey: 'Edit',
                header: 'Edit',
                size: 40,
                enableSorting: false,
                Cell: ({ row }) => (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(row)}
                    >
                        Edit
                    </Button>
                ),
            },

       /*      {
                accessorKey: 'delete',
                header: 'Delete',
                size: 50,
                enableSorting: false,
                Cell: ({ row }) => (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDelete(row)}
                    >
                        Delete
                    </Button>
                ),
            },  */

        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: strategies,
        initialState: {
            density: 'compact',
            columnPinning: { left: ['name'] },
        },
    });


    return (
        <>
        <Menu/>
        <Container>
            <Header title={'Response Strategies'} />
                <Button style={{margin: "40px 0px", width: "150px", alignSelf: "center"}} onClick={handleModal} variant="contained" color="primary" >
                    Add Strategy
                </Button>
            {showModal && message === "" &&
                    <Modal 
                        openModal={showModal && message === ""} 
                        title={editingId !== '' ? "Edit Strategy" : "Add Strategy"} 
                        showActions={true} handleCloseModal={handleCancel} 
                        handleSubmit={editingId !== '' ? handleUpdateStrategy : handleCreateStrategy} 
                        isDisabled={!newName || !newDescription || !newName.trim() || !newDescription.trim()} 
                        buttonAction={editingId !== '' ? 'Update' : 'Create'} 
                    >
                            <AddStrategyForm onSubmit={editingId !== '' ? handleUpdateStrategy : handleNewName}>
                                <TextField
                                    label="Name"
                                    type="text"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    required
                                />
                            </AddStrategyForm>
                            <AddStrategyForm onSubmit={editingId !== '' ? handleUpdateStrategy : handleNewDescription}>
                                <TextField
                                    label="LLM Description"
                                    type="text"
                                    value={newDescription}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    required
                                />
                            </AddStrategyForm>
                            <AddStrategyForm onSubmit={editingId !== '' ? handleUpdateStrategy : handleNewUiDescription}>
                                <TextField
                                    label="UI Description"
                                    type="text"
                                    value={newUiDescription}
                                    onChange={(e) => setNewUiDescription(e.target.value)}
                                />
                            </AddStrategyForm>
                            <AddStrategyForm>
                                <FormControl>
                                    <InputLabel>Enable/Disable</InputLabel>
                                    <Select
                                        label="Enable/Disable"
                                        type="bool"
                                        value={onOff}
                                        onChange={(e) => setOnOff(e.target.value)}
                                        required
                                    >
                                        <MenuItem value={false}>Enable</MenuItem>
                                        <MenuItem value={true}>Disable</MenuItem>
                                    </Select>
                                </FormControl>
                            </AddStrategyForm>
                    </Modal>
            }
            {showModalConfirm &&
                    <Modal openModal={showModalConfirm} showActions={false} subtitle={`Are you sure to delete this Strategy?`} >
                            <strong >{deletingRow.original.name}</strong>
                            <BoxesContainer style={{marginTop:"20px"}}>
                                <Button style={{marginRight: "30px"}} onClick={handleConfirmDelete} variant="contained" color="primary" >
                                    Yes
                                </Button>
                                <Button onClick={handleCancelDelete} variant="contained" color="primary" >
                                    No
                                </Button>
                            </BoxesContainer>
                    </Modal>
            }
            {message !== "" &&
                    <Modal openModal={message !== ""} showActions={false}>
                            <strong >{message}</strong>
                            <BoxesContainer style={{marginTop:"20px", justifyContent:"center"}}>
                                <Button onClick={handleCancel} variant="contained" color="primary" >
                                    OK
                                </Button>
                            </BoxesContainer>
                    </Modal>
            }
            <div style={{margin: "0px 30px"}}>
                <MaterialReactTable table={table} />
            </div>
        </Container>
        </>
    );
};

export default Strategies;
