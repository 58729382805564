import React, { useState } from 'react';
import styled from 'styled-components';
import ToxicityBar from './ToxicityBar';
import { InfoOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { Typography, IconButton, Box, Tooltip, Grid } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-right: 15px;
  margin-top: 10px;
  font-size: 12px;
  position: relative;
`;

const Label = styled.div`
  margin-right: 2px;
  font-weight: 600;
  white-space: nowrap;
`;

const BoldValue = styled.div`
  font-weight: bold;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
`;

const ScoreInfo = styled.div`
  position: absolute;
  margin-bottom:50px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
  border-radius:10px;
  top: -50px;
  right: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); 
  text-align: left;
`;


const ToxicityContainer = styled.div``

const UserOrPostSummary = ({ tpc, typeScore, toxicLevel, toxicPerspectiveScore, showGptBar, toxicScoreMax }) => {

  const [showScoreInfo, setShowScoreInfo] = useState(false);
  const handleOpen = () => {
    setShowScoreInfo(true);
  };
  const handleClose = () => {
    setShowScoreInfo(false);
  };

  return (
    <div>
      <RowWrapper>
        <Label>Toxicity:</Label>
        <BoldValue>{toxicScoreMax !== 0 ? toxicScoreMax : 0}</BoldValue>
        <ToxicityBar score={toxicScoreMax} typeScore={'Toxic Score'} />
        <div onClick={handleOpen} style={{
          color: '#3498db',
          paddingLeft: '12px',
          paddingBottom: '0px',
          cursor: 'pointer'
        }}>
          <Tooltip title="More details"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },],
              },
            }}>
            <WarningAmberOutlined />
          </Tooltip>
        </div>
        {showScoreInfo && (
          <Box sx={{ left: '0px', zIndex: 10, width: '150px'}}>
            <ClickAwayListener onClickAway={handleClose}>
              <ScoreInfo>
                <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <CloseOutlinedIcon fontSize='small'/>
                </IconButton>

                <Grid container spacing={2} sx={{marginTop: '2px', marginBottom: '5px'}}>
                  <Grid item>
                    <Typography fontSize={'small'}>GPT Score: <strong>{toxicLevel}</strong></Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontSize={'small'}>Perspective Score: <strong>{toxicPerspectiveScore}</strong></Typography>
                  </Grid>
                </Grid>

                {tpc && tpc.length > 0 ? (
                  tpc.every(item => item.categoryScore === -1) ? (
                    <Typography sx={{ p: 0 }}>This post doesn't have content to show</Typography>
                  ) : (
                    tpc.map(item => (
                      item.categoryScore !== -1 && (
                        <ToxicityContainer key={item._id}>
                          <Typography>Score: <strong>{item.categoryScore}</strong></Typography>
                          <ToxicityBar score={item.categoryScore} />
                          <Typography>{item.categoryId?.name}</Typography>
                          <InfoOutlined sx={{ color: '#3498db', pr: 0.5, fontSize: 18, mb: -0.5, ml: -0.25 }} />
                          {item.explanation}
                        </ToxicityContainer>
                      )
                    ))
                  )
                ) : (
                  <Typography sx={{ p: 0 }}>This post doesn't have content to show</Typography>
                )}
              </ScoreInfo>
            </ClickAwayListener>
          </Box>
        )}
      </RowWrapper>
    </div>
  );
};

export default UserOrPostSummary;
