import React, { useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import { Chip, Button, Box, Tooltip, IconButton, Link } from '@mui/material';
import ToxicTweet from './ToxicTweet';
import UserInfo from './UserInfo';
import UserOrPostSummary from './UserOrPostSummary';
import ContentBox from './ContextBox';
import Spinner from './Spinner';
import formatText from '../utils/formatText';
import  InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { WavingHandOutlined } from '@mui/icons-material';


const SpaceBetweenComponents = styled(({ toxicLevel, ...rest }) => <div {...rest} />)`
  margin-bottom: 15px;
  margin-top:${({ toxicLevel }) => (toxicLevel ? '-25px' : '0px')};
  position: relative; 
`;

const SpaceLeftComponents = styled(({ marginLeft, boxDescription, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  margin-left: ${({ marginLeft, boxDescription }) => (marginLeft ? '100px' : boxDescription ? '40px' : '30px')};
  font-weight: 500;
  font-size: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PaddingHorizontal = styled.div`
  padding-right: 20px;
  padding-left: 15px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -8px;
  @media (max-width: 768px) {
    margin-top: 0px;
    max-width: 75%;
  }
`;

const UserInfoWithIcon = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  color: #3498db !important;
  margin-left: 5px !important;
  margin-top: 7px !important;
  font-weight: normal !important;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 12px;
  transform: scaleX(1.2);
  color: #3498db;
`;

const NotificationWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff4b8;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 20px;
    margin: 20px auto 10px;
    max-width: 550px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    width: 96%;
`;

const EmojiWrapper = styled.div`
    font-size: 24px;
    margin-right: 10px;
`;

const StyledWavingHandIcon = styled(WavingHandOutlined)({
    fontSize: '36px !important',
    marginRight: '10px',
    transform: 'scaleX(-1)',
});

const MessageText = styled.div`
    font-size: 14px;
    color: #333;
    line-height: 1.5;

    a {
        color: #1a73e8; /* Link color */
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const PostColumn = ({ post, handleTweetContext, showButtonContext, content, context, showSpinner, toxicUserIntervened }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Header title="Post" />
            <SpaceLeftComponents>
                <SpaceBetweenComponents>
                    <ClickAwayListener onClickAway={handleClose}>
                        <div>
                            <UserInfoWithIcon>
                                <UserInfo name={post.userDetails?.name} avatar={post.userDetails?.profile_image_url} />
                                <Tooltip title="Summary Profile"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -25],
                                                    },
                                                },],
                                        },
                                    }}>
                                    <StyledButton
                                        aria-describedby={open ? 'popover' : undefined}
                                        onClick={handleClick}
                                        sx={{ borderRadius: '50%' }}
                                    >
                                        <InfoOutlinedIcon fontSize='medium' />
                                    </StyledButton>
                                </Tooltip>
                            </UserInfoWithIcon>
                            {open && (
                                <Box sx={{
                                    p: 1,
                                    fontSize: '14px',
                                    width: '300px',
                                    backgroundColor: '#ffff',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                                    borderColor: 'black',
                                    borderRadius: '5px',
                                    position: 'absolute',
                                    whiteSpace: 'pre-wrap',
                                    zIndex: '999',
                                    right: '20%',
                                    transform: 'translateX(50%)',
                                    paddingTop: '20px',
                                    top: '50px',
                                }}>
                                    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                        <CloseOutlinedIcon fontSize='small'/>
                                    </IconButton>
                                    {formatText(post.userDetails?.llmProfile)}
                                </Box>
                            )}
                        </div>
                    </ClickAwayListener>
                </SpaceBetweenComponents>
            </SpaceLeftComponents >
            <SpaceLeftComponents marginLeft={true}>
                <SpaceBetweenComponents toxicLevel={post.toxicScoreMax}>
                    <UserOrPostSummary
                        toxicLevel={post.toxicScore}
                        toxicPerspectiveScore={post.toxicPerspectiveScore}
                        toxicScoreMax={post.toxicScoreMax}
                        categoryTweet={post.isToxic ? 'Toxic' : 'Non Toxic'}
                        tpc={post.tpc}
                        showGptBar={true}
                    />
                </SpaceBetweenComponents>
            </SpaceLeftComponents>
            {
                post.topics?.length > 0 &&
                <SpaceLeftComponents marginLeft={true}>
                    <span style={{ marginRight: '10px' }}>Topics:</span>
                    <ChipContainer>
                        {post.topics.map((topic, index) => (
                            <Chip key={index} label={topic.name} style={{ marginBottom: '5px' }} />
                        ))}
                    </ChipContainer>
                </SpaceLeftComponents>
            }
            <PaddingHorizontal>
                {!toxicUserIntervened.isIntervened && (
                    <NotificationWrapper>
                        <EmojiWrapper><StyledWavingHandIcon /></EmojiWrapper>
                        <MessageText>
                            Attention: you're about to make your first interaction with this account.
                            <br />
                            Consider replying to some of <Link target="_blank" href={`https://www.x.com/${post.userDetails?.username}`}>their other content</Link> first.
                        </MessageText>
                    </NotificationWrapper>                
                )}
                <ToxicTweet tweetId={post.twitterPostId} />
                {showButtonContext && !showSpinner &&
                    <Button variant='contained' onClick={handleTweetContext}>
                        what is this tweet about?
                    </Button>}
                {!showButtonContext && !showSpinner &&
                    <ContentBox content={content} context={context} />}
                {showSpinner && <Spinner />}
            </PaddingHorizontal>
        </>
    );
};

export default PostColumn;