import { useMemo, useEffect, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Box, Link, Button, Avatar } from '@mui/material';
import { formatNumber } from '../utils/formatNumbers';
import styled from 'styled-components';
import axios from 'axios';
import dayjs from 'dayjs';
import Header from '../components/Header';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { FileDownload } from '@mui/icons-material';
import { apiUrl } from '../utils/common';
import Menu from '../components/Menu';
import placeholderImg from '../assets/images/user-placeholder.png';

const Container = styled.div`
    margin: 50px 50px;
`;

const HeaderContainer = styled.div`
    margin-top: 50px;
`;

const TableContainer = styled.div`
    margin: 30px 0 0 0;
`;

const csvConfig = mkConfig({
    filename: `Users - ${dayjs().format('h:mmA - MMM D, YYYY')}`,
    fieldSeparator: ',',
    useKeysAsHeaders: true,
});

const UserAnalytics = () => {
    const [toxicUsers, setToxicUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const url = new URL(`${apiUrl}/toxicUsers`);
            url.searchParams.set('page', `${pagination.pageIndex + 1}`);
            url.searchParams.set('pageSize', `${pagination.pageSize}`);
            url.searchParams.set('search', globalFilter ?? '');
            url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            await axios
                .get(url)
                .then((response) => {
                    setToxicUsers(response.data.data);
                    setRowCount(response.data.totalCount);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('There was an error.', error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, [pagination.pageIndex, pagination.pageSize, globalFilter, sorting]);

    const handleExportData = async () => {
        await axios.get(`${apiUrl}/toxicUsers/getAllToxicUsers`)
        .then((response) => {
            let csvData = response.data;
            let formattedData = csvData.map(({ name, createdAt, partisanship }) => ({
                'User': name,
                'Created Date': dayjs(createdAt).format('h:mmA - MMM D, YYYY'),
                'Partisanship': partisanship,
            }));
            const csv = generateCsv(csvConfig)(formattedData);
            download(csvConfig)(csv);
        })
        .catch((err) => {
            console.error("There was an error", err);
        })
    };

    const csvConfigBlockData = mkConfig({
        filename: `Blocking Users Report`,
        title: `Blocking Users Report`,
        fieldSeparator: ',',
        useKeysAsHeaders: true,
    });
    
      const handleExportBlockdata = async () => {
        await axios.get(`${apiUrl}/users/getInfoBlockingUsers`)
        .then((response) => {
            let csvData = response.data;
            
            const formattedData = csvData.map((item) => {
                const lastIntervention = item.interventions[0] || {};
                const blockedAt = lastIntervention.userData?.blockedUsers?.find(blockedUser => blockedUser.userId === lastIntervention.toxicUserDetails?._id)?.blockedAt || '';
                return {
                    'Blocked Username': lastIntervention.userData?.mainTwitterAccount || '',
                    'Blocked Name': lastIntervention.userData?.name || '',
                    'Blocker Username': lastIntervention.toxicUserDetails?.username || '',
                    'Block Discovered At': blockedAt,
                    'Url Last Intervention': lastIntervention.responseUrl || '',
                    'Strategy': lastIntervention.appliedStrategy?.name || '',
                    'Intervention 1': item.interventions[1]?.responseUrl || '',
                    'Strategy 1': item.interventions[1]?.appliedStrategy?.name || '',
                    'Intervention 2': item.interventions[2]?.responseUrl || '',
                    'Strategy 2': item.interventions[2]?.appliedStrategy?.name || '',
                    'Intervention 3': item.interventions[3]?.responseUrl || '',
                    'Strategy 3': item.interventions[3]?.appliedStrategy?.name || '',
                    'Intervention 4': item.interventions[4]?.responseUrl || '',
                    'Strategy 4': item.interventions[4]?.appliedStrategy?.name || '',
                };
            });
            
            const csv = generateCsv(csvConfigBlockData)(formattedData);
            download(csvConfigBlockData)(csv, 'Blocking Users Report');
        })
        .catch((err) => {
            console.error("There was an error to export", err);
        })    
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'User',
                size: 200,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Avatar
                            src={row.original.profile_image_url || placeholderImg}
                            loading='lazy'
                        />
                        <Link
                            href={`https://x.com/${row.original.username}`}
                        >
                            {row.original.name}
                        </Link>
                    </Box>
                ),
            },
            {
                accessorKey: 'partisanship',
                header: 'Partisanship',
                size: 150,
            },
            {
                accessorFn: (row) => formatNumber(row.followers),
                id: 'followers',
                header: 'Followers',
                size: 100,
            },
            {
                accessorFn: (row) => formatNumber(row.following),
                id: 'following',
                header: 'Following',
                size: 100,
            },
            {
                accessorFn: (row) =>
                    dayjs(row.date).format('h:mmA - MMM D, YYYY'),
                id: 'createdAt',
                header: 'Created Date',
                size: 110,
            },
            {
                accessorKey: 'LLMProfileSummary',
                header: 'LLM ProfileSummary',
                size: 350,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '1rem',
                        }}
                    >
                        <div style={{ whiteSpace: 'wrap' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorKey: 'LLMAudienceSummary',
                header: 'LLM Audience Summary',
                size: 350,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '1rem',
                        }}
                    >
                        <div style={{ whiteSpace: 'wrap' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorFn: (row) => formatNumber(row.toxicPosts),
                id: 'toxicPosts',
                header: '#Toxic Posts',
                size: 100,
            },
            {
                accessorFn: (row) => formatNumber(row.toxicRate),
                id: 'toxicRate',
                header: 'Mean Toxic Rate',
                size: 100,
            },
            {
                accessorKey: 'authoritarianism',
                header: 'Mean Authoritarian Rate',
                size: 250,
                Cell: ({ cell }) => (
                    <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() > 5
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 0
                                    ? theme.palette.warning.dark
                                    : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                ),
            },
            {
                accessorKey: 'hatefulScore',
                header: 'Mean Toxic Hateful Rate',
                size: 110,
                Cell: ({ cell }) => (
                    <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() > 5
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 0
                                    ? theme.palette.warning.dark
                                    : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                ),
            },
            {
                accessorKey: 'violenceScore',
                header: 'Mean Violence Authoritarian Rate',
                size: 80,
                Cell: ({ cell }) => (
                    <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() > 5
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 0
                                    ? theme.palette.warning.dark
                                    : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                ),
            },
            {
                accessorFn: (row) => formatNumber(row.likes),
                id: 'likes',
                header: 'Mean Likes',
                size: 100,
            },
            {
                accessorFn: (row) => formatNumber(row.shares),
                id: 'shares',
                header: 'Mean Shares',
                size: 95,
            },
            {
                accessorFn: (row) => formatNumber(row.comments),
                id: 'comments',
                header: 'Mean Comments',
                size: 120,
            },
            {
                accessorFn: (row) => formatNumber(row.impressions),
                id: 'impressions',
                header: 'Mean Impressions',
                size: 130,
            },
            {
                accessorFn: (row) => formatNumber(row.interventions),
                id: 'interventions',
                header: '#Interventions',
                size: 150,
            },
        ],
        []
    );

    const data = toxicUsers;

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: {
            density: 'compact',
            columnPinning: { left: ['user'] },
        },
        enablePagination: true,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        rowCount,
        state: { pagination, isLoading, globalFilter, sorting },
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button onClick={() => {handleExportData(); handleExportBlockdata()}} startIcon={<FileDownload />}>
                    Export
                </Button>
            </Box>
        ),
    });

    return (
    <>
    <Menu />
        <HeaderContainer>
            <Header title='Users Analytics' />
        </HeaderContainer>
        <Container>
            <TableContainer>
                <MaterialReactTable table={table} />
            </TableContainer>
        </Container>
    </>    
    );
};

export default UserAnalytics;
