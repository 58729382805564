import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import Menu from '../components/Menu';
import MeasureBox from '../components/MeasureBox';


const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 0;
`;

const CenterHorizontally = styled.div`
  justify-content: center;
  margin: 40px 35%;

  @media (max-width: 768px) {
    margin: 40px 0px;
  }
`;


const MeasureToxicity = () => {
    const [toxicities, setToxicities] = useState('');

// Función para realizar una solicitud get al endpoint y obtener el nivel de toxicidad del texto ingresado
    const onButtonClick = async (tweetText, selectedGPT) => {
        try {
            const response = await axios.get(`${apiUrl}/measureToxicity/getToxicityByText`, {
                params: {
                    text: tweetText,
                    gpt: selectedGPT
                }
            });
            const data = response.data;
            setToxicities(data);
        } catch (error) {
            console.error('Error al obtener la Toxicidad:', error);
        }
    };

// Funcion para limpiar el nivel de toxicidad devuelto
    const clearToxicity = () => {
        setToxicities('');
    };


    return (
        <>
            <Menu />
            <Container>
                <Header title={'Measure toxicity'} />
                <CenterHorizontally>
                    <MeasureBox onSubmit={onButtonClick} toxicities={toxicities} clearToxicity={clearToxicity} />
                </CenterHorizontally>
            </Container>
        </>
    );
};

export default MeasureToxicity;
