import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = ({allowedRoles}) => {
    const user = useSelector((state) => state.auth.user)

    return (
        allowedRoles.includes(user?.role)
            ? <Outlet />
            : user
                ? <Navigate to="/unauthorized" />
                : <Navigate to="/login" />
    )
}

export default PrivateRoutes