import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: []
}

const toastSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        // Action to show a new toast notification.
        show: (state, action) => {
            const existingToast = state.list.find(toast => toast.path === action.payload.path && toast.type === action.payload.type);
            if (!existingToast) {
                state.list.push({
                    id: Date.now() + Math.random(), // Unique ID for the toast
                    type: action.payload.type,       // Type of the toast (e.g., success, error)
                    title: action.payload.title,     // Title of the toast
                    message: action.payload.message, // Message content of the toast
                    autodismissTime: action.payload.autodismissTime, // Time before automatic dismissal
                    visible: true,                     // Sets the toast to be visible
                    path: action.payload.path         // URL path to track duplicate toasts
                });
            }
        },
        // Action to hide a toast notification.
        hide: (state, action) => {
            state.list = state.list.map(toast =>
                toast.id === action.payload.id ? { ...toast, visible: false } : toast
            );
        },
        // Action to clear a specific toast notification by ID.
        clear: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload.id);
        },
        // Action to reset the list of toasts.
        reset: (state, action) => {
            state.list = [];
        },
    }
})

export const { show, hide, clear } = toastSlice.actions;

export default toastSlice.reducer;