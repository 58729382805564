import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userLogin } from '../features/authActions';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateUser } from '../features/authSlice';
import { clearFilters, clearPagination } from '../features/filtersSlice';
import trackEvent from '../utils/trackEvent';


const AuthCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(userLogin())
            .unwrap()
            .then((user) => {
                const { search } = location;
                const params = new URLSearchParams(search);
                const token = params.get('token');
                dispatch(updateUser({token: token}));
                const blockingUserIds = user.blockedUsers?.map(user => user.userId);
                
                const clearedFilters = {
                    selectedPeriod: 'yesterday',
                    addedManually: false,
                    intervened: 'notIntervened',
                    selectedTopics: [],
                    selectedToxicUser: 'all', 
                    partisanship: 'all',
                    minimumToxicScore: 5,
                    blockingUsers: blockingUserIds
                }
                dispatch(clearFilters(clearedFilters));
                dispatch(clearPagination());

                trackEvent('login', 'success', user.email);
                navigate('/');
            })
            .catch((error) => {
                console.error('Failed to fetch user data: ', error);
            });
    }, [dispatch, navigate]);

    return (
        (
            <div>Processing login...</div>
        )
    )
};

export default AuthCallback;
