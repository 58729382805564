import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../features/authSlice';
import { clearFilters, clearPagination } from '../features/filtersSlice';

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: block;
    background: rgba(0, 0, 0, 0.8);
`
const Container = styled.div`
    width: 100%;
    height: 150px;
    max-width: 500px;
    background: #fff;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
`

const SessionExpiredAlert = () => {
    const isSessionExpired = useSelector(state => state.auth.isSessionExpired)
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(setLogout());
        dispatch(clearFilters());
        dispatch(clearPagination());
    }

    return (
        <>
          {isSessionExpired && (
            <Overlay>
                <Container>
                    <p>Your session has expired. Please <a href="#" onClick={handleLogout}>logout</a> and login again.</p>
                </Container>
            </Overlay>
          )}
        </>
      );
}

export default SessionExpiredAlert