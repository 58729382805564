import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import WhiteBox from './WhiteBox';
import { apiUrl } from '../utils/common';
import axios from 'axios';
import CustomAnswer from './CustomAnswer';
import Modal from './Modal';
import {
    IconButton,
    Button,
    Typography,
    Box,
    TextField,
    Grid
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CheckCircle, Clear, HelpOutline, InfoOutlined } from '@mui/icons-material';
import useVerifyTwitterUrl from '../utils/useVerifyTwitterUrl';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from './Spinner';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import trackEvent from '../utils/trackEvent';
import { updateUser } from '../features/authSlice';
import GifSelector from './GifSelector';

const Container = styled.div`
    box-sizing: border-box;
    padding: 0 40px;
    width: 100%;
`;

const WhiteBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 0px;
`;
const Title = styled.h2`
  font-size: 20px;
  color: #3498db;
  margin-top: 20px;
`
const SuccessMessage = styled.div`
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0 20px 0;
`;
const ErrorMessage = styled.span`
    font-size: 12px;
    margin-top: 15px;
    color: red;
`;
const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CenterHorizontally = styled.div`
    display: flex;
    justify-content: center;
`;
const EditableText = styled.textarea`
    box-sizing: border-box;
    font-size: 14px;
    line-height: 21px;
    min-height: 200px;
    padding: 10px;
    resize: vertical;
    width: 90%;
`
const AnswerHeaderWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;
const CharCounter = styled.div`
  margin-top: 8px;
  margin-right: 25px;
  font-size: 14px;
  color: #555;
  text-align: right;
  transition: color 0.3s ease;

  ${(props) =>
    props.isOverLimit &&
    `
    color: red;
  `}
`;

const PossibleAnswers = ({
    postId,
    twitterPostId,
    handleNextTweet,
    showButton,
    post,
    toxicUserId,
    isBlocked
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [url, setUrl] = useState('');
    const { verifyError, verifyUrl, resetError } = useVerifyTwitterUrl();
    const [responseId, setResponseId] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const userId = useSelector((state) => state.auth.user._id);
    const [intervened, setIntervened] = useState(false);
    const [declined, setDeclined] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [canFetchMoreAnswers, setCanFetchMoreAnswers] = useState(true);
    const user = useSelector((state) => state.auth.user);
    const description = useSelector((state) => state.auth.user.userProfileSummary);
    const [tweetUrl, setTweetUrl] = useState('');
    const [tweetText, setTweetText] = useState('');
    const [editText, setEditText] = useState(tweetText);
    const [originalResponseText, setOriginaResponseText] = useState('');
    const [textModified, setTextModified] = useState(false);
    const [isEditableAnswer, setIsEditableAnswer] = useState(false);
    const [openManualTweetModal, setOpenManualTweetModal] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [showModalBeMoreLikeMe, setShowModalBeMoreLikeMe] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openStrategyInfo, setOpenStrategyInfo] = useState(() => answers.map(() => false));
    const [userDescription, setUserDescription] = useState(description);
    const [isAutodescription, setIsAutodescription] = useState(false);
    const [sameResponseUsedWarned, setSameResponseUsedWarned] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isTwitterVerified = user.verified;
    //Messages to show in case of error when posting directly
    const blockedMessage = "This user has blocked you in X. We will not display this user’s posts to you in the future.";
    const authenticateMessage = "This action could not be performed because the X/Twitter account is no longer active and has been disconnected. You can connect a different X/Twitter account on the profile screen if you would like.";
    const revokedMessage = "This action could not be performed because the X/Twitter account has revoked its delegation of API authority to the Social Media Detoxifier, which breaks its connection to this app. You can reconnect this X/Twitter account, or connect a different X/Twitter account, from the Profile screen by re-authenticating on Twitter when redirected.";
    const longTweetMessage = `This tweet is ${tweetText?.length - (isTwitterVerified ? 4000 : 280)} characters over the maximum length of ${isTwitterVerified ? 4000 : 280} characters. You can shorten it here, or go to X/Twitter to review and complete this response.`;
    const otherErrorMessage ="This response could not be posted directly. You may go to X/Twitter and attempt to complete it there, or cancel.";
    const InterventionErrorMessage = "Failed to create the intervention."
    const GenericErrorMessage = "An unexpected error occurred. Please try again later.";
    const charCount = editText.length;
    const maxLength = 280;
    const isOverLimit = charCount > maxLength;
    const [selectedGifUrl, setSelectedGifUrl] = useState('');
    
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    //Function to get if a post was declined
    const IsPostDeclined = async () => {
        try {
            const res = await axios.get(`${apiUrl}/posts/${postId}/isDeclined/${userId}`);
            if (res) {
                setDeclined(res.data);
            }
        } catch (err) {
            console.error('Failed fetching declines: ', err);
        }
    };

    //Function to get if a post was intervened
    const isPostIntervened = async () => {
        try {
            const res = await axios.get(`${apiUrl}/posts/${postId}/isIntervened/${userId}`);
            if (res) {
                setIntervened(res.data);
            }
        } catch (err) {
            console.error('Failed fetching interventions: ', err);
        }
    };

    useEffect(() => {
        getAnswers();
        isPostIntervened();
        setCanFetchMoreAnswers(true);
        //Si no fue intervenido busca si fue rechazado
        if (!intervened) {
            IsPostDeclined();
        }
    }, [postId]);

    const getAnswers = async () => {
        if (postId) {
            try {
                const res = await axios.get(`${apiUrl}/responses/${postId}`, {
                    params: { partisanship: user.partisanship || 'center' }
                });
                const totalAnswers = res.data;
                setAnswers(totalAnswers);
            } catch (err) {
                console.error('Failed fetching possible answers: ', err);
            }
        }
    };

    const openTwitterLink = (text, id) => {
        const twitterLink = `https://x.com/intent/tweet?text=${encodeURIComponent(text)}&in_reply_to=${twitterPostId}`;
        window.open(twitterLink, '_blank');
        setResponseId(id);
        setOpenManualTweetModal(true);
    };

    const openTwitterQuote = (text) => {
        const tweetLink = `https://x.com/${post.userDetails?.username}/status/${twitterPostId}`;
        const fullText = `${text} ${tweetLink}`;
        const twitterLink = `https://x.com/intent/tweet?text=${encodeURIComponent(fullText)}`;
        window.open(twitterLink, '_blank');
        setResponseId(responseId);
        setOpenManualTweetModal(true);
    };
    
    const checkResponseAndPost = async (text, id) => {
        try {
            if(id){
                const response = await axios.get(`${apiUrl}/interventions/findByResponse`, {
                    params: { responseId: id }
                });
        
                if (response.data === null) {
                    openTwitterLink(text, id);
                }
            }
        } catch (error) {
            if (error.response?.status === 400 && error.response?.data?.detail?.warned) {
                setSameResponseUsedWarned(error.response?.data?.detail?.warned);
                setShowModalError(true);
                setErrorMessage(error.response?.data?.message);
            } else {
                setShowModalError(true);
                setErrorMessage(GenericErrorMessage);
            }
        }
    };

    const onButtonClick = async (text, id) => {
    if (user.mainTwitterAccount) {
        setEditText(text);
        setTweetText(text);
        setOriginaResponseText(text);
        setResponseId(id);
        setOpenModal(true);
    } else {
        if (!id) {
            openTwitterLink(text);
        } else if (sameResponseUsedWarned) {
            openTwitterLink(text, id);
        } else {
            await checkResponseAndPost(text, id);
        }
    }
};

const postTweetAndHandleResponse = async () => {
    setIsLoading(true);
    try {
        const tweet = await axios.post(`${apiUrl}/twitterApi/post-tweet`, { tweetText, twitterPostId, responseId, postId, selectedGifUrl });
        if (tweet.data.success) {
            const responseUrl = tweet.data?.intervention?.responseUrl;
            if (responseUrl && responseId) {
                await replaceStrategyResponse(responseUrl, responseId);
            }else{
                setIsLoading(false);
                setTweetUrl(responseUrl);
                setOpenModal(true);
                setIntervened(true);
                setIsSuccess(true);
                setSameResponseUsedWarned(false);
            }
        } else {
            handleError(InterventionErrorMessage);
        }
    } catch (error) {
        handleTweetError(error);
    }
};

const handleTweetError = (error) => {
    setIsLoading(false);
    setShowModalError(true);
    if (error.response?.status === 403) {
        setErrorMessage(error.response?.data?.blocked ? blockedMessage : authenticateMessage);
    } else if (error.response?.status === 401) {
        setErrorMessage(revokedMessage);
    } else if (error.response?.status === 400) {
        setErrorMessage(longTweetMessage);
    } else {
        setErrorMessage(otherErrorMessage);
    }
    console.error('Error posting tweet:', error);
};

const checkResponseAndPostTweet = async () => {
    try {
        if(responseId){
            const response = await axios.get(`${apiUrl}/interventions/findByResponse`, {
                params: { responseId: responseId }
            });
    
            if (response.data === null) {
                await postTweetAndHandleResponse();
            }
        } else {
            setSameResponseUsedWarned(true);              
            await postTweetAndHandleResponse();
        }
    } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.detail?.warned) {
            setSameResponseUsedWarned(error.response?.data?.detail?.warned);
            setShowModalError(true);
            setErrorMessage(error.response?.data?.message);
        } else {
            setShowModalError(true);
            setErrorMessage(GenericErrorMessage);
        }
    }
};

const handleSubmitTweet = async () => {
    if (sameResponseUsedWarned) {
        await postTweetAndHandleResponse();
    } else {
        await checkResponseAndPostTweet();
    }
};

const handleError = (message) => {
    setIsLoading(false);
    setShowModalError(true);
    setErrorMessage(message);
};

    const replaceStrategyResponse = async (responseUrl, responseId) => {
        try {
            setIsLoading(false);
            setTweetUrl(responseUrl);
            setOpenModal(true);
            setIntervened(true);
            setIsSuccess(true);
            setSameResponseUsedWarned(false);
            setSelectedGifUrl('');

            // Generar nueva respuesta en reemplazo de la estrategia ya usada
            const newResponse = await axios.post(`${apiUrl}/responses/replaceStrategyResponse`, {
                postId,
                responseId,
                userId
            });
        } catch (error) {
            console.error('Error creating intervention:', error);
            setIsLoading(false);
            setShowModalError(true);
            setErrorMessage(InterventionErrorMessage);
        }
        // Llamar trackEvent después de crear la intervención con éxito independiente de de si se reemplaza la respuesta
        trackEvent('intervention created', {
            responseUrl: responseUrl
        }, user.email);
    }

    const handleChange = (e) => {
        setUrl(e.target.value);
    };

    const handleCloseModal = () => {
        setEditText("");
        setTweetText("");
        setIsEditableAnswer(false);
        setOpenModal(false);
        setOpenManualTweetModal(false);
        setErrorMessage('');
        resetError();
        setUrl('');
        setShowModalBeMoreLikeMe(false);
        setUserDescription('');
        setIsAutodescription(false);
        setTextModified(false);
        setResponseId('');
        setSelectedGifUrl('');
    };

    // Submit tweet via modal
    const handleSubmitManualTweet = async (e) => {
        e.preventDefault();
        if (verifyUrl(url)) {
            setIsLoading (true);
            try {
                const res = await axios.post(
                    `${apiUrl}/interventions/createIntervention`,
                    {
                        responseUrl: url,
                        responseId: responseId,
                        userId: userId,
                        postId: postId,
                        manualResponse: true,
                    }
                );
                if(res){
                    setIsLoading(false);
                    setIntervened(true);
                    setIsSuccess(true);
                    setSameResponseUsedWarned(false);

                    trackEvent('intervention created', {
                        responseUrl: url
                    }, user.email);

                    // Generar nueva respuesta en reemplazo de la estrategia ya usada
                    if (responseId) {
                        try {
                            await axios.post(`${apiUrl}/responses/replaceStrategyResponse`, {
                                postId,
                                responseId,
                                userId
                            });
                        } catch (error) {
                            console.error('Error replacing strategy response:', error);
                        }
                    }
                }
                setUrl('');
                resetError('');
            } catch (error) {
                console.error('Failed to create a new intervention: ', error);
                setIsLoading(false);
                setShowModalError(true);
                setErrorMessage(InterventionErrorMessage);
            }
        }
    };

    //Funcion para crear documento en la coleccion decline
    const handleDecline = async () => {
        try {
            const res = await axios.post(`${apiUrl}/declines/createDecline`, {
                postId: postId,
                userId: userId,
            });
            if (res) {
                setDeclined(true);
            }
        } catch (error) {
            console.error('Failed to create Decline: ', error);
        }
    };

    const fetchMoreAnswers = async () => {
        if (!canFetchMoreAnswers) return;
        setIsLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/responses/generateMoreResponses`, { postId: postId });
            getAnswers();
        } catch (error) {
            console.error('Failed to fetch more answers:', error);
            setCanFetchMoreAnswers(false);
        } finally {
            setIsLoading(false);
        }
    }

    const getHumorResponse = async () => {
        setIsLoading(true);
        try {
            const params = {
                tweetText: post.content,
                partisanship: user.partisanship || 'center',
                postId: post._id,
            };
            await axios.post(`${apiUrl}/responses/generateHumorResponse`, params);
            getAnswers();
            setIsLoading(false);
        } catch (err) {
            console.error('Failed creating humor response.', err);
            setIsLoading(false);
        }
    };
    
    const beMoreLikeMe = async () => {
        setShowModalBeMoreLikeMe(false);
        setIsLoading(true);
        try {
            const params = {
                dbPost: post,
                toxicUser: post.userDetails,
                description: userDescription,
                isAutodescription: isAutodescription,
                userId: userId
            };

            if (isAutodescription){
                dispatch(updateUser({ userProfileSummary: userDescription }));
            };
            
            await axios.post(`${apiUrl}/responses/generateBeMoreLikeMe`, params);
            getAnswers();
            setIsLoading(false);
        } catch (err) {
            console.error('Failed creating response.', err);
            setIsLoading(false);
        }
    };

    const getPolarityResponse = async () => {
        setIsLoading(true);
        try {
            const params = {
                tweetText: post.content,
                partisanship: user.partisanship || 'center',
                post: post,
            };
            await axios.post(`${apiUrl}/responses/generatePolarityResponse`, params);
            getAnswers();
            setIsLoading(false);
        } catch (err) {
            console.error('Failed creating polarity response.', err);
            setIsLoading(false);
        }
    };

    const handleAutodescriptionChange = (e) => {
        setUserDescription(e.target.value);
        setIsAutodescription(true);
    };

    const handleEditTextChange = (e) => { setEditText(e.target.value); setTweetText(e.target.value) }

    const handleTracking = (path) => {
        trackEvent('navigation', path, user.email);
    };

    const TwitterAccountStatus = () => {
        if (user.mainTwitterAccount) {
            return (
                <Typography style={{ padding: "15px 30px 15px 25px", fontSize: "14px" }}>
                    Your response will be posted directly to Twitter on your behalf as <a href={`https://x.com/${user.mainTwitterAccount}`} target="_blank" rel="noopener noreferrer">{user.mainTwitterAccount}</a>. You can change this on the <Link to="/my-account">Profile screen</Link>, or you can always choose to be redirected to the Twitter app to complete a particular response instead of posting directly.
                </Typography>
            );
        } else {
            return (
                <Typography style={{ padding: "15px 30px 15px 25px", fontSize: "14px" }}>
                    You will be redirected to Twitter to complete your response. If you would like to post directly to Twitter without redirecting each time, you can connect your Twitter account on the <Link to="/my-account">Profile screen</Link>.
                </Typography>
            );
        }
    };

    const openTwitterLinkManual = (isQuoteValue) => {
        if (isQuoteValue) {
            openTwitterQuote(editText);
        }
        else {
            const twitterLink = `https://x.com/intent/tweet?text=${encodeURIComponent(editText)}&in_reply_to=${twitterPostId}`;
            window.open(twitterLink, '_blank');
            setOpenManualTweetModal(true);
        }
        setOpenModal(false);
    };

    const checkResponseAndOpenTwitterLink = async (isQuoteValue = false) => {
        try {
            if(responseId){
                const response = await axios.get(`${apiUrl}/interventions/findByResponse`, {
                    params: { responseId: responseId }
                });
        
                if (response.data === null) {
                    openTwitterLinkManual(isQuoteValue);
                }
            } else {
                setSameResponseUsedWarned(true);              
                openTwitterLinkManual(isQuoteValue);
                
            }
        } catch (error) {
            if (error.response?.status === 400 && error.response?.data?.detail?.warned) {
                setSameResponseUsedWarned(error.response?.data?.detail?.warned);
                setShowModalError(true);
                setErrorMessage(error.response?.data?.message);
            } else {
                setShowModalError(true);
                setErrorMessage(GenericErrorMessage);
            }
        }
    };
    
    const handlePreviewAndPost = async () => {
        try {
            if (sameResponseUsedWarned) {
                openTwitterLink(editText, responseId);
            } else {
                await checkResponseAndOpenTwitterLink();
            }
        } catch (error) {
            if (error.response?.status === 400 && error.response?.data?.detail?.warned) {
                setSameResponseUsedWarned(error.response?.data?.detail?.warned);
                setShowModalError(true);
                setErrorMessage(error.response?.data?.message);
            } else {
                setShowModalError(true);
                setErrorMessage(GenericErrorMessage);
            }
        }
    };

    const handlePreviewAndQuote = async () => {
        try {
            if (sameResponseUsedWarned) {
                openTwitterQuote(editText);
            } else {
                await checkResponseAndOpenTwitterLink(true);
            }
        } catch (error) {
            if (error.response?.status === 400 && error.response?.data?.detail?.warned) {
                setSameResponseUsedWarned(error.response?.data?.detail?.warned);
                setShowModalError(true);
                setErrorMessage(error.response?.data?.message);
            } else {
                setShowModalError(true);
                setErrorMessage(GenericErrorMessage);
            }
        }
    };
    

    const handleToggleInfo = (index) => {
        const newOpenStrategyInfo = [...openStrategyInfo];
        newOpenStrategyInfo[index] = !newOpenStrategyInfo[index];
        setOpenStrategyInfo(newOpenStrategyInfo);
    };

    const handleCloseInfo = (index) => {
        const newOpenStrategyInfo = [...openStrategyInfo];
        newOpenStrategyInfo[index] = false;
        setOpenStrategyInfo(newOpenStrategyInfo);
    };

    return (
        <>
            <HeaderContainer>
                <Header title='Suggested Replies' />
                <ClickAwayListener onClickAway={handleClickAway} touchEvent={false}>
                    <Box sx={{ position: 'relative' }}>
                        <IconButton
                            aria-describedby={open ? 'help-popover' : undefined}
                            onClick={handleClick}
                            style={{ color: '#3498db', marginRight: '20px' }}
                        >
                            <HelpOutline fontSize='small' />
                        </IconButton>

                        {open ? (
                            <Box sx={{
                                p: 1,
                                fontSize: '14px',
                                width: '200px',
                                backgroundColor: '#ffff',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                                borderColor: 'black',
                                borderRadius: '5px',
                                position: 'absolute',
                                zIndex: '999',
                                right: '-100%',
                            }}>
                                You may select any of the suggested replies and edit it on
                                Twitter before posting, or write your own reply from
                                scratch.
                            </Box>
                        ) : null}
                    </Box>
                </ClickAwayListener>
            </HeaderContainer>
            <TwitterAccountStatus />
            <Title style={{ marginTop: '5px', marginBottom: '0px', padding: '0 25px' }}>Suggested Strategies</Title>
            <CenterHorizontally style={{margin: (declined || intervened)? '25px 0px' : '0px'}}>
                {intervened && (
                    <strong>You have responded to this tweet.</strong>
                )}
                {declined && (
                    <strong>This post has been declined to reply.</strong>
                )}
            </CenterHorizontally>

            {answers && !declined && (
                <>
                    {answers.map((item, index) => (
                        <Container key={index}>
                            <AnswerHeaderWrapper>
                            <h3 style={{marginBottom: '0px'}}>{item.styleId? `${item.strategyId?.name} with ${item.styleId.name}` : `Personalized ${item.strategyId?.name}` }</h3>
                                <Box sx={{ position: 'relative' }}>
                                <ClickAwayListener onClickAway={() => handleCloseInfo(index)} touchEvent={false}>
                                    <Box>
                                    <IconButton
                                        aria-describedby={openStrategyInfo[index] ? 'info-popover' : undefined}
                                        onClick={() => handleToggleInfo(index)}
                                        style={{ color: '#3498db', marginLeft: '10px', padding: 0 }}
                                        >
                                        <InfoOutlined fontSize='small' />
                                    </IconButton>
                                    {openStrategyInfo[index] ? (
                                        <Box sx={{
                                            padding: '15px',
                                            fontSize: '14px',
                                            width: '180px',
                                            backgroundColor: '#ffff',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                                            borderColor: 'black',
                                            borderRadius: '5px',
                                            position: 'absolute',
                                            whiteSpace: 'pre-wrap',
                                            zIndex: '999',
                                        }}>
                                            {item.strategyId?.uiDescription}
                                        </Box>
                                    ) : null}
                                    </Box>
                                    </ClickAwayListener>
                                </Box>
                            </AnswerHeaderWrapper>
                            <WhiteBoxContainer>
                                <WhiteBox
                                    text={item.text}
                                    onButtonClick={() =>
                                        onButtonClick(item.text, item._id)
                                    }
                                    buttonText='Select'
                                />
                            </WhiteBoxContainer>
                        </Container>
                    ))}

                    {isLoading && <Spinner />}
                    <Container>
                    <Title style={{ marginTop: '30px' }}>Get More Suggestions</Title>
                    {(canFetchMoreAnswers && answers?.length < 6) && (
                            <CenterHorizontally style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <Button onClick={fetchMoreAnswers} disabled={isLoading || !canFetchMoreAnswers || isBlocked} variant="contained">More Strategies</Button>
                            </CenterHorizontally>
                    )}
                    </Container>
                    <CenterHorizontally style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <Button variant="contained" disabled={isLoading || isBlocked} onClick={getHumorResponse}>Use Humor</Button>
                    </CenterHorizontally>
                    <CenterHorizontally style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <Button variant="contained" disabled={isLoading || isBlocked} onClick={() => description? beMoreLikeMe() : setShowModalBeMoreLikeMe(true)}>Personalized response</Button>
                    </CenterHorizontally>
                    <CenterHorizontally style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <Button variant="contained" disabled={isLoading || isBlocked} onClick={getPolarityResponse}>Use polarity</Button>
                    </CenterHorizontally>
                </>
            )}
            {(!answers || answers?.length < 1) && (
                <CenterHorizontally style={{ margin: '40px 0px' }}>
                    <strong>All responses has been used.</strong>
                </CenterHorizontally>
            )}
            {!declined && !intervened && (
                <Container>
                    <Title>Write my own</Title>
                    <WhiteBoxContainer>
                        <CustomAnswer onSubmit={onButtonClick} />
                    </WhiteBoxContainer>
                    <CenterHorizontally style={{ marginTop: '30px', marginBottom: '25px' }}>
                        <Button
                            onClick={() => handleDecline()}
                            variant='contained'
                            color='primary'
                        >
                            Decline to respond
                        </Button>
                    </CenterHorizontally>
                </Container>
            )}
            <Modal
                openModal={openModal}
                title={isSuccess ? 'Response posted' : 'Use this reply'}
                showActions={isSuccess ? false : true}
                handleSubmit={handleSubmitTweet}
                handleCloseModal={handleCloseModal}
                buttonAction={'Post Directly'}
                maxwidth={'600px'}
                isDisabled={isEditableAnswer || (textModified && tweetText===originalResponseText) || isBlocked }
                extraActions={
                    <>
                        <Button
                            onClick={handlePreviewAndQuote}
                            style={{ marginRight: '10px', marginTop:'3px'}}
                            disabled = {isBlocked}
                        >
                            Quote in X
                        </Button>
                        <Button
                            onClick={handlePreviewAndPost}
                            style={{ marginRight: '10px', marginTop:'3px'}}
                            disabled = {isBlocked}
                        >
                            Reply in X
                        </Button>
                    </>
                }

            >
            {isLoading ? (
                <Spinner />
            ) : (
                isSuccess && !openManualTweetModal ? (
                    <>
                        <SuccessMessage>
                            <CheckCircle color='success' fontSize='large' />
                            <br />
                            Success! Your response has been posted on X/Twitter and recorded for tracking.
                        </SuccessMessage>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                            {tweetUrl && (<a href={tweetUrl} target="_blank" rel="noopener noreferrer">View in X</a>)}
                            <Link to={'/recent-toxic-content-dashboard'} onClick={() => handleTracking('/recent-toxic-content-dashboard')}>Recent Content</Link>
                            {showButton && (
                                <Button
                                    variant='contained'
                                    onClick={() => { handleNextTweet(); setOpenModal(false); setIsSuccess(false) }}
                                >
                                    Next Tweet
                                </Button>
                            )}
                        </Box>
                    </>
                ) : (
                    <>
                        <EditableText maxLength={280} onChange={handleEditTextChange} value={editText} />
                        <CharCounter isOverLimit={isOverLimit}>
                            {charCount}/{maxLength}
                        </CharCounter>
                        <GifSelector onSelect={setSelectedGifUrl} selectedGifUrl={selectedGifUrl} />
                        {selectedGifUrl && (
                            <Box sx={{ mt: 0, ml: 3, display: 'flex', justifyContent: 'center', position: 'relative', width: 'fit-content' }}>
                                <img src={selectedGifUrl} alt="GIF preview" style={{ maxWidth: '100%', maxHeight: '80px', borderRadius: '8px' }} />
                                <IconButton
                                    onClick={() => setSelectedGifUrl(null)}
                                    sx={{
                                        position: 'absolute',
                                        top: -8,
                                        right: -8,
                                        backgroundColor: 'white',
                                        color: 'gray',
                                        '&:hover': { backgroundColor: 'lightgray' },
                                        boxShadow: 1,
                                    }}
                                    size="small"
                                >
                                    <Clear fontSize="small" />
                                </IconButton>
                            </Box>
                        )}
                        <Typography sx={{ mt: 2, mb: 2, fontSize: '11px', color: 'gray' }}>
                            You can post directly, or if you want to preview your response before posting you can go to X/Twitter to complete your response.<br/> IMPORTANT: GIFs are only available to post directly.
                        </Typography>
                    </>
                )
            )}
            </Modal>
            <Modal
                openModal={openManualTweetModal}
                title={'Add your intervention URL'}
                subtitle={'Responses posted directly in X/Twitter will not be tracked unless you copy-paste the URL of the response back to us here. This step is not required if you connect your twitter account and we post directly on your behalf from inside this app.'}
                subtitleStyle={{ fontSize: '10px', color: 'grey', fontWeight: '300', width: '300px' }}
                showActions={isSuccess ? false : true}
                handleCloseModal={handleCloseModal}
                handleSubmit={handleSubmitManualTweet}
                isDisabled={!url || isLoading}
                maxwidth={'600px'}
            >
                {isSuccess && openManualTweetModal && !isLoading ? (
                    <>
                        <SuccessMessage>
                            <CheckCircle color='success' fontSize='large' />
                            <br />
                            Success! Your intervention has been saved.
                            {tweetUrl && <a href={tweetUrl} target="_blank">See your Tweet</a>}
                            <Link to={'/recent-toxic-content-dashboard'}>Go to Recent Content</Link>
                        </SuccessMessage>
                        {showButton && (
                            <Button
                                variant='contained'
                                style={{ marginTop: '15px' }}
                                onClick={() => {
                                    handleNextTweet();
                                    setOpenModal(false);
                                    setOpenManualTweetModal(false);
                                    setIsSuccess(false);
                                }}
                            >
                                Next Tweet
                            </Button>
                        )}
                    </>
                ) : isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <TextField
                            label='Intervention URL'
                            type='text'
                            variant='outlined'
                            onFocus={resetError}
                            onChange={handleChange}
                            value={url}
                            required
                            fullWidth
                        />
                        {verifyError && (
                            <ErrorMessage>{verifyError}</ErrorMessage>
                        )}
                    </>
                )}
            </Modal>
            {showModalError && (
                <Modal
                    openModal={showModalError}
                    showActions={false}
                    maxwidth={'400px'}>
                    <SuccessMessage>
                        <h3>{errorMessage}</h3>
                    </SuccessMessage>
                    {sameResponseUsedWarned || longTweetMessage ?
                        <Button variant="contained" onClick={() => {setShowModalError(false); setTextModified(true)}}>Go back</Button>
                        :
                        <Button variant="contained" onClick={() => navigate('/recent-toxic-content-dashboard')}>Go back to recent content</Button>
                    }
                </Modal>
            )}
            {!openModal && !openManualTweetModal && !isSuccess && showButton && (
                <Button
                    variant='contained'
                    style={{
                        position: 'fixed',
                        zIndex: '1',
                        bottom: '15px',
                        right: '15px',
                    }}
                    onClick={() => handleNextTweet()}
                >
                    next tweet
                </Button>
            )}
            {showModalBeMoreLikeMe && !description && (
                <Modal
                    openModal={showModalBeMoreLikeMe}
                    showActions={true}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={beMoreLikeMe}
                    subtitle={"Autodescription"}
                    subtitleStyle={{ fontSize: '18px', color: '#3498db' }}
                    maxwidth={'400px'}>
                    <EditableText
                        value={userDescription}
                        onChange={handleAutodescriptionChange}
                    />
                </Modal>
            )}
        </>
    );
};

export default PossibleAnswers;
