import { createClient } from 'contentful';

const SPACE_ID = 'lkmhyfg1if9p'
const ACCESS_TOKEN = '65pbbmAPeO3yNfD6j1f_uV4Nx4a2TYBCQQ2isygMnWI';
const ENVIRONMENT = 'master'

const client = createClient({
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN,
    environment: ENVIRONMENT
})

export default client;
