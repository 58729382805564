import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Button, TextField, MenuItem, InputLabel, FormControl, Select, Box } from '@mui/material';
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import Header from '../components/Header';
import { apiUrl } from '../utils/common';
import Menu from '../components/Menu';
import Modal from '../components/Modal';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 0;
`;

const CenterHorizontally = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0px  
`;

const MarginRight = styled.div`
  margin-right:30px;
`;

const MarginHorizontal = styled.div`
  margin: 0px 50px;
`;

const RedLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: red;
`;

const GreenLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: green;
`;

const AddCategoryForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin:20px 0;
`;

const StyledFormSelect = styled(Select)`
  width: 100%;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  width: 150px;
  align-self: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.35) !important; 
`;

const BoxesContainer = styled.div`
display:flex;
flex-direction: row;
margin-bottom:20px;

@media (max-width: 768px) {
  flex-direction: column;
  margin-bottom:10px;
}
`;

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [newName, setNewName] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [onOff, setOnOff] = useState(false);
    const [editingId, setEditingId] = useState('');
    const [showModal, setShowModal] = useState(null);
    const [showModalConfirm, setShowModalConfirm] = useState(null);
    const [deletingRow, setDeletingRow] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        getCategories();
    }, []);

    const handleNewName = (e) => {
        e.preventDefault();
    };

    const handleNewDescription = (e) => {
        e.preventDefault();
    };

    async function getCategories() {
        try {
            const response = await axios.get(`${apiUrl}/categories/getCategories`);
            const data = await response.data;
            setCategories(data);
        } catch (error) {
            console.error('Error al obtener las categorias:', error);
        }
    };

    const handleCreateCategory = async (e) => {
        e.preventDefault();
        const newCategoryObj = {
            name: newName,
            description: newDescription,
            disabled: onOff,
        };

        const existingCategory = categories.find(categories => categories.name.toLowerCase() === newCategoryObj.name.toLowerCase());
        if (!existingCategory) {
            try {
                const response = await axios.post(`${apiUrl}/categories/createCategory`, newCategoryObj);
                if (response) {
                    getCategories();
                    handleCancel();
                }
            } catch (error) {
                console.error('Error al realizar la solicitud:', error);
            }
        } else {
            setMessage("Category already created");
        }
    };

    const handleEdit = (row) => {
        setShowModal(true);
        const editingCategory = row.original;
        setEditingId(row.original._id);
        setNewName(editingCategory.name || '');
        setNewDescription(editingCategory.description || '');
        setOnOff(editingCategory.disabled);
    };

    const handleUpdateCategory = async (e) => {
        e.preventDefault();

        const updatedCategoryObj = {
            categoryId: editingId,
            updatedCategory: {
                name: newName,
                description: newDescription,
                disabled: onOff,
            },
        };

        const otherCategories = categories.filter(c => c._id !== editingId);
        const existingCategory = otherCategories.find(c => c.name.toLowerCase() === newName.toLowerCase());
        if (!existingCategory) {
            try {
                const response = await axios.patch(`${apiUrl}/categories/`, updatedCategoryObj);

                if (response) {
                    getCategories();
                    handleCancel();
                } else {
                    console.error('Error al actualizar la categoria:', response.statusText);
                }
            } catch (error) {
                console.error('Error al realizar la solicitud:', error);
            }
        } else {
            setMessage("Category already created");
        }
    };

    /*    const handleDelete = async (row) => {
           setShowModalConfirm(true);
           setDeletingRow(row);
       }; */

    const handleCancelDelete = () => {
        setDeletingRow(null);
        setShowModalConfirm(false);
    };

    const handleConfirmDelete = async () => {
        const categoryId = deletingRow.original._id;

        try {
            const response = await axios.delete(`${apiUrl}/categories/${categoryId}`);

            if (response) {
                getCategories();
                setShowModalConfirm(false);
            } else {
                console.error('Error al eliminar la categoria:', response.statusText);
            }
        } catch (error) {
            console.error('Error al realizar la solicitud:', error);
        }
    };

    const handleCancel = () => {
        setNewName('');
        setNewDescription('');
        setEditingId('');
        setShowModal(false);
        setMessage('');
        setOnOff(false);
    };

    const handleModal = () => {
        setShowModal(true);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                id: 'name',
                header: 'Name',
                size: 150,
            },
            {
                accessorKey: 'description',
                id: 'description',
                header: 'Description',
                size: 400,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '1rem',
                        }}
                    >
                        <div style={{ whiteSpace: 'wrap' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorKey: 'disabled',
                id: 'disabled',
                header: 'State',
                size: 40,
                Cell: ({ row }) => (
                    <>
                        {row.original.disabled ? <RedLabel>Disabled</RedLabel> : <GreenLabel>Enabled</GreenLabel>}
                    </>
                )
            },

            {
                accessorKey: 'Edit',
                header: 'Edit',
                size: 40,
                enableSorting: false,
                Cell: ({ row }) => (
                    <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(row)}
                    >
                        Edit
                    </StyledButton>
                ),
            },

            /*      {
                     accessorKey: 'delete',
                     header: 'Delete',
                     size: 50,
                     enableSorting: false,
                     Cell: ({ row }) => (
                         <StyledButton
                             variant="contained"
                             color="primary"
                             onClick={() => handleDelete(row)}
                         >
                             Delete
                         </StyledButton>
                     ),
                 },  */

        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: categories,
        initialState: {
            density: 'compact',
            columnPinning: { left: ['name'] },
        },
    });


    return (
        <>
            <Menu />
            <Container>
                <Header title={'Toxicity Types'} />
                <CenterHorizontally>
                    <StyledButton onClick={handleModal} variant="contained" color="primary" >
                        Add Category
                    </StyledButton>
                </CenterHorizontally>
                {showModal && message === "" &&
                    <Modal openModal={showModal && message === ""} title={editingId !== '' ? "Edit Category": "Add Category"} showActions={true} handleCloseModal={handleCancel} handleSubmit={editingId !== '' ? handleUpdateCategory : handleCreateCategory} isDisabled={!newName || !newDescription || !newName.trim() || !newDescription.trim()} buttonAction={editingId !== '' ? 'Update' : 'Create'} >
                            <AddCategoryForm onSubmit={editingId !== '' ? handleUpdateCategory : handleNewName}>
                                <TextField
                                    label="Name"
                                    type="text"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    required
                                />
                            </AddCategoryForm>
                            <AddCategoryForm onSubmit={editingId !== '' ? handleUpdateCategory : handleNewDescription}>
                                <TextField
                                    label="Description"
                                    type="text"
                                    value={newDescription}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    required
                                />
                            </AddCategoryForm>
                            <AddCategoryForm>
                                <FormControl>
                                    <InputLabel>Enable/Disable</InputLabel>
                                    <StyledFormSelect
                                        label="Enable/Disable"
                                        type="bool"
                                        value={onOff}
                                        onChange={(e) => setOnOff(e.target.value)}
                                        required
                                    >
                                        <MenuItem value={false}>Enable</MenuItem>
                                        <MenuItem value={true}>Disable</MenuItem>
                                    </StyledFormSelect>
                                </FormControl>
                            </AddCategoryForm>
                    </Modal>
                }
                {showModalConfirm &&
                    <Modal openModal={showModalConfirm} showActions={false} subtitle={`Are you sure to delete this category?`} >
                            <BoxesContainer>
                                <label>
                                    <strong>{deletingRow.original.name}</strong>
                                </label>
                            </BoxesContainer>
                            <BoxesContainer>
                                <MarginRight>
                                    <StyledButton onClick={handleConfirmDelete} variant="contained" color="primary" >
                                        Yes
                                    </StyledButton>
                                </MarginRight>
                                <StyledButton onClick={handleCancelDelete} variant="contained" color="primary" >
                                    No
                                </StyledButton>
                            </BoxesContainer>
                    </Modal>
                }
                {message !== "" &&
                    <Modal openModal={message !== ""} showActions={false}>
                            <BoxesContainer>
                                <label>
                                    {message}
                                </label>
                            </BoxesContainer>
                            <BoxesContainer>
                                <StyledButton onClick={handleCancel} variant="contained" color="primary" >
                                    OK
                                </StyledButton>
                            </BoxesContainer>
                    </Modal>
                }
                <MarginHorizontal>
                    <MaterialReactTable table={table} />
                </MarginHorizontal>
            </Container>
        </>
    );
};

export default Categories;
