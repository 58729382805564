import styled from 'styled-components';
import { Link } from '@mui/material';
import placeholderImg from '../assets/images/placeholder-300x194.png';

const ContainerWrapper = styled.div`
    background-color: #f5f5f5;
    border: 0.5px solid #3498db;
    border-radius: 10px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
`;
const Container = styled.div`
    /* background-color: #fff; */
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
`
const Title = styled.h2`
  font-size: 20px;
  color: #3498db;
  margin-top: 0;
`
const ArticleWrapper = styled.div`
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.25);
    display: flex;
    margin: 20px 0;

    `
const ArticleImageWrapper = styled.div`
    max-width: 120px;
    min-width: 120px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        object-fit: cover;
        font-size: 10.5px;
        height: 100%;
        max-height: 120px;
        width: auto;
    }
`
const ArticleContentWrapper = styled.div`
    box-sizing: border-box;
    padding: 15px;

    & > a {
        color: #419EDD;
        text-decoration: none;

        > h4 {
            margin: 0 0 10px 0;
            max-height: 4.5em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    & p {
        font-size: 12px;
        margin: 0;
        max-height: 4.5em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
`

const ContextBox = ({ content, context }) => {
    return (
        <ContainerWrapper>
            <Container>
                <Title>What is this Tweet about?</Title>
                <p style={{ whiteSpace: 'pre-wrap' }}>{content}</p>
                {Array.isArray(context) && context.length > 0 ? (
                    context
                    .filter(item => !item.url.endsWith('.pdf'))
                    .map((item, index) => {
                        const hasMetadata = item.metadata?.title;
                        const imageUrl = item.metadata?.image || placeholderImg;
                        return hasMetadata ? (
                            <ArticleWrapper key={index}>
                                <ArticleImageWrapper>
                                    <img src={imageUrl} alt={item.metadata?.title} />
                                </ArticleImageWrapper>
                                <ArticleContentWrapper>
                                    <Link href={item.url} target="_blank">
                                        <h4>
                                            {item.metadata?.title}
                                        </h4>
                                    </Link>
                                    <p>
                                        {item.metadata?.description}
                                    </p>
                                </ArticleContentWrapper>
                            </ArticleWrapper>
                        ) : null;
                    })
                ) : (
                    <p>No articles to display</p>
                )}
            </Container>
        </ContainerWrapper>
    );
};

export default ContextBox;
