import { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Box, Link, Button, Avatar } from '@mui/material';
import { formatNumber } from '../utils/formatNumbers';
import dayjs from 'dayjs';
import styled from 'styled-components';
import axios from 'axios';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { FileDownload } from '@mui/icons-material';
import { apiUrl } from '../utils/common';
import Header from '../components/Header';
import Menu from '../components/Menu';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';  //Estilos para el calendario
import 'react-date-range/dist/theme/default.css'; 
import Spinner from '../components/Spinner';
import placeholderImg from '../assets/images/user-placeholder.png';

const Container = styled.div`
    margin: 50px 50px;
`;
const CalendarContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    flex-direction: column;
`;

const DateRangeContainer = styled.div`
    z-index: 1000;
`;


//Para que el Calendar no se cierre al seleccionar fecha
const handleDateRangeClick = (e) => {
    e.stopPropagation();
};

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
const StyledButton = styled(Button)`
    width: 160px;
    align-self: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.35) !important; 
    background-color: #3498db !important;
    color: #ffffff !important; 
`;

const HeaderContainer = styled.div`
    margin-top: 50px;
`;

const TableContainer = styled.div`
    margin: 30px 0 0 0;
`

const csvConfigPost = mkConfig({
    filename: `Posts - ${dayjs().format('h:mmA - MMM D, YYYY')}`,
    fieldSeparator: ',',
    useKeysAsHeaders: true,
});

const PostAnalytics = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [loading, setLoading] = useState(false);
    
    //Captura las fechas seleccionadas
    const handleDateRangeChange = (ranges) => {
        setDateRange([ranges.selection]);
    };

    const handleExportAll = () => {
        handleExportData();
    };

    const handleShowCalendar = () => {
        setShowCalendar(true);
    };
    const handleCloseCalendar = () => {
        setShowCalendar(false);
    };


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const url = new URL(`${apiUrl}/posts`);
            url.searchParams.set('page', `${pagination.pageIndex + 1}`);
            url.searchParams.set('pageSize', `${pagination.pageSize}`);
            url.searchParams.set('search', globalFilter ?? '');
            url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            await axios
                .get(url)
                .then((response) => {
                    setData(response.data.data);
                    setRowCount(response.data.totalCount);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('There was an error.', error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, [pagination.pageIndex, pagination.pageSize, globalFilter, sorting]);

    const handleExportData = async (dateRange) => {
        handleCloseCalendar();
        setLoading(true);
        try {
            let response = await axios.get(`${apiUrl}/posts/getAllPosts`, {
                params: {
                    dateRange: JSON.stringify(dateRange)
                }
            });

            const posts = response.data.map(item => ({
                    'User': item.userDetails.name,
                    'User url': `https://x.com/${item.userDetails.username}`,
                    'Partisanship': item.userDetails?.partisanship?? '',
                    'Date': dayjs(item.twitterCreatedAt).format('h:mmA - MMM D, YYYY'),
                    'Text': item.content,
                    'Tweet url': `https://x.com/${item.userDetails.username}/status/${item.twitterPostId}`,
                    'Type': item.isToxic === true ? 'Toxic' : 'Non Toxic',
                    'Added Manually': item.addedManually === true ? 'Yes' : 'No',
                    'Likes': item.postsToxicMonitor?.likes,
                    'Comments': item.postsToxicMonitor?.comments,
                    'Shares': item.postsToxicMonitor?.shares,
                    'Impressions': item.postsToxicMonitor?.impressions,
                    'Reponse Strategy 1': item.strategies[0]?.name,
                    'Response Text 1' : item.responses[0]?.text, 
                    'Reponse Strategy 2': item.strategies[1]?.name,
                    'Response Text 2' : item.responses[1]?.text,
                    'Reponse Strategy 3': item.strategies[2]?.name,
                    'Response Text 3' : item.responses[2]?.text,
                    'Toxicity Mean Score': item.toxicScore,
                    'Toxic Perspective Score': item.toxicPerspectiveScore,
                    'Republic Norms Score': item.tpc[0]?.categoryScore,
                    'Republic Norms Description': item.tpc[0]?.explanation,
                    'Partisan Violence Score': item.tpc[1]?.categoryScore,
                    'Partisan Violence Description': item.tpc[1]?.explanation,
                    'Outpary Perceptions Score': item.tpc[2]?.categoryScore,
                    'Outpary Perceptions Description': item.tpc[2]?.explanation,
                    'Moral Outrage Score': item.tpc[3]?.categoryScore,
                    'Moral Outrage Description': item.tpc[3]?.explanation,
                    'Dehumanization Score': item.tpc[4]?.categoryScore,
                    'Dehumanization Description': item.tpc[4]?.explanation,
                })
            );

            if (posts.length) {
                const csvData = generateCsv(csvConfigPost)(posts);
                download(csvConfigPost)(csvData, 'posts');
            } else  {
                alert('There are no posts on your current selection. Please make another selection.');
            }
            setLoading(false);
        } catch (error) {
            console.error("There was an error", error);
        }
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'userDetails.name',
                header: 'User',
                size: 200,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Avatar
                            src={row.original.userDetails.profile_image_url || placeholderImg}
                            loading='lazy'
                        />
                        <Link
                            href={`https://x.com/${row.original.userDetails.username}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {row.original.userDetails.name}
                        </Link>
                    </Box>
                ),
            },
            {
                accessorKey: 'userDetails.partisanship',
                header: 'Partisanship',
                size: 150,
                Cell: ({ row }) => (
                    <span>{row.original.userDetails?.partisanship}</span>
                ),
            },
            {
                accessorKey: 'twitterCreatedAt',
                header: 'Date',
                size: 140,
                Cell: ({ renderedCellValue, row }) => (
                    <a
                        href={`https://x.com/${row.original.userDetails.username}/status/${row.original.twitterPostId}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {dayjs(renderedCellValue).format('h:mmA - MMM D, YYYY')}
                    </a>
                ),
            },
            {
                accessorKey: 'content',
                header: 'Text',
                enableSorting: false,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorKey: 'toxicScoreMax',
                header: 'Toxic Score',
                size: 200,
                Cell: ({ cell }) => (
                    <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() > 5
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 0
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                ),
            },
            {
                accessorKey: 'type',
                header: 'Type',
                size: 100,
            },
            {
                accessorKey: 'addedManualy',
                id: 'addedManually',
                header: 'Added Manually',
                size: 30,
                Cell: ({ row }) => (
                  <>
                    {row.original.addedManually ? "Yes" : "No"}
                  </>
                )
              },
            {
                accessorFn: (row) => formatNumber(row.postsToxicMonitor?.likes),
                id: 'likes',
                header: 'Likes',
                size: 100,
            },
            {
                accessorFn: (row) => formatNumber(row.postsToxicMonitor?.comments),
                id: 'comments',
                header: 'Comments',
                size: 120,
            },
            {
                accessorFn: (row) => formatNumber(row.postsToxicMonitor?.shares),
                id: 'shares',
                header: 'Shares',
                size: 95,
            },
            {
                accessorFn: (row) => formatNumber(row.postsToxicMonitor?.impressions),
                id: 'impressions',
                header: 'Impressions',
                size: 130,
            },
            {
                accessorFn: row => row?.strategies[0]?.name,
                id: 'responseStrategy1',
                header: 'Response Strategy 1',
                size: 200,
            },
            {
                accessorFn: row => row?.responses[0]?.text,
                id: 'responseText1',
                header: 'Response Text 1',
                size: 250,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorFn: row => row?.strategies[1]?.name,
                id: 'responseStrategy2',
                header: 'Response Strategy 2',
                size: 200,
            },
            {
                accessorFn: row => row.responses[1]?.text,
                id: 'responseText2',
                header: 'Response Text 2',
                size: 250,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorFn: row => row.strategies[2]?.name,
                id: 'responseStrategy3',
                header: 'Response Strategy 3',
                size: 200,
            },
            {
                accessorFn: row => row.responses[2]?.text,
                id: 'responseText3',
                header: 'Response Text 3',
                size: 250,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorFn: row => row.tpc[0]?.categoryScore,
                id: 'republicNormsScore',
                header: 'Republic Norms Score',
                size: 200,
                Cell: ({ cell }) => (
                    cell.getValue() !== undefined && (
                        <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() > 5
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 0
                                    ? theme.palette.warning.dark
                                    : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                    )                
                ),
            },
            {
                accessorFn: row => row.tpc[0]?.explanation,
                id: 'republicNormsDescription',
                header: 'Republic Norms Description',
                size: 200,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorFn: row => row.tpc[1]?.categoryScore,
                id: 'partisanViolenceScore',
                header: 'Partisan Violence Score',
                size: 200,
                Cell: ({ cell }) => (
                    cell.getValue() !== undefined && (
                        <Box
                            component='span'
                            sx={(theme) => ({
                                backgroundColor:
                                    cell.getValue() > 5
                                        ? theme.palette.error.dark
                                        : cell.getValue() >= 0
                                            ? theme.palette.warning.dark
                                            : theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '9ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue()}
                        </Box>
                    )
                    
                ),
            },
            {
                accessorFn: row => row.tpc[1]?.explanation,
                id: 'partisanViolenceDescription',
                header: 'Partisan Violence Description',
                size: 200,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorFn: row => row.tpc[2]?.categoryScore,
                id: 'outparyPerceptionsScore',
                header: 'Outpary Perceptions Score',
                size: 200,
                Cell: ({ cell }) => (
                    cell.getValue() !== undefined && (
                        <Box
                            component='span'
                            sx={(theme) => ({
                                backgroundColor:
                                    cell.getValue() > 5
                                        ? theme.palette.error.dark
                                        : cell.getValue() >= 0
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '9ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue()}
                        </Box>
                    )
                ),
            },
            {
                accessorFn: row => row.tpc[2]?.explanation,
                id: 'outparyPerceptionsDescription',
                header: 'Outpary Perceptions Description',
                size: 200,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorFn: row => row.tpc[3]?.categoryScore,
                id: 'moralOutrageScore',
                header: 'Moral Outrage Score',
                size: 200,
                Cell: ({ cell }) => (
                    cell.getValue() !== undefined && (
                    <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() > 5
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 0
                                    ? theme.palette.warning.dark
                                    : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                    )
                ),
            },
            {
                accessorFn: row => row.tpc[3]?.explanation,
                id: 'moralOutrageDescription',
                header: 'Moral Outrage Description',
                size: 200,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
            {
                accessorFn: row => row.tpc[4]?.categoryScore,
                id: 'dehumanizationScore',
                header: 'Dehumanization Score',
                size: 200,
                Cell: ({ cell }) => (
                    cell.getValue() !== undefined && (
                    <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() > 5
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 0
                                    ? theme.palette.warning.dark
                                    : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                    )
                ),
            },
            {
                accessorFn: row => row.tpc[4]?.explanation,
                id: 'dehumanizationDescription',
                header: 'Dehumanization Description',
                size: 200,
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                            {cell.getValue()}
                        </div>
                    </Box>
                ),
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: {
            density: 'compact',
            columnPinning: { left: ['user'] },
        },
        enablePagination: true,
        manualPagination: true,
        rowCount,
        state: { pagination, isLoading, globalFilter, sorting },
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button onClick={handleShowCalendar} startIcon={<FileDownload />}>
                    Export
                </Button>
            </Box>
        ),
    });

    return (
        <>
            <Menu />
            <HeaderContainer>
                <Header title='Posts Analytics' />
            </HeaderContainer>
            <Container>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        {showCalendar && (
                            <CalendarContainer onClick={handleCloseCalendar}>
                                <DateRangeContainer onClick={handleDateRangeClick}>
                                    <DateRangePicker
                                        onChange={handleDateRangeChange}
                                        ranges={dateRange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        direction="horizontal"
                                        showDateDisplay={false}
                                    />
                                </DateRangeContainer>
                                <ButtonContainer>
                                    <StyledButton onClick={handleExportAll} style={{ marginRight: '5px', marginTop: '10px' }}>EXPORT ALL POST</StyledButton>
                                    <StyledButton onClick={() => handleExportData(dateRange)} style={{ marginLeft: '5px', marginTop: '10px' }}>EXPORT SELECTION</StyledButton>
                                </ButtonContainer>
                            </CalendarContainer>
                        )}
                        <TableContainer>
                            <MaterialReactTable table={table} />
                        </TableContainer>
                    </>
                )}
                </Container>
        </>
    );
};

export default PostAnalytics;
