import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Button, TextField, FormControl, Select, RadioGroup, Radio, FormLabel, FormControlLabel, Avatar } from '@mui/material';
import { PersonOutlineOutlined, CampaignOutlined, FileDownload } from '@mui/icons-material';
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import { Box, Link } from '@mui/material';
import Header from '../components/Header';
import { apiUrl } from '../utils/common';
import Menu from '../components/Menu';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import placeholderImg from '../assets/images/user-placeholder.png';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import dayjs from 'dayjs';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 0;
`;

const CenterHorizontally = styled.div`
  display: flex;
  justify-content: center;
`;

const SmallBox = styled.div` 
  border: 0.5px solid #3498db; /* Borde Azul VER*/
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); 
  padding: 15px;
  margin: 20px 0px;
  width:700px;

  @media (max-width: 768px) {
    width:75vw;
    margin: 5px 5px;
    align-self: center;
  }
  `;

const WhiteContainer = styled.div`
  background-color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;  
`;

const MarginRight = styled.div`
  margin-right:30px;
`;

const MarginHorizontal = styled.div`
  margin: 0px 50px;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding-top:10px;
  padding-left:5px;
  align-items: center;
  display: flex;
`;

const TableLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const AddProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin:20px 0;
`;

const AddSummaryForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin:20px 0;
`;

const BoxesContainer = styled.div`
display:flex;
flex-direction: row;
margin-bottom:20px;
justify-content: center;

@media (max-width: 768px) {
  flex-direction: column;
  margin-bottom:10px;
}
`;

const CenterText = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const csvConfigAccounts = mkConfig({
  filename: `Evaluated accounts - ${dayjs().format('h:mmA - MMM D, YYYY')}`,
  fieldSeparator: ',',
  useKeysAsHeaders: true,
});

const CreateToxicProfile = () => {
  const [newProfile, setNewProfile] = useState([]);
  const [profile, setProfile] = useState([]);
  const [toxicUserSelected, setToxicUserSelected] = useState();
  const [profileSummary, setProfileSummary] = useState('');
  const [audienceSummary, setAudienceSummary] = useState('');
  const [partisanship, setPartisanship] = useState('center');
  const [editingId, setEditingId] = useState('');
  const [showModal, setShowModal] = useState(null);
  const [showModalConfirm, setShowModalConfirm] = useState(null);
  const [showModalInfo, setShowModalInfo] = useState(null);
  const [deletingRow, setDeletingRow] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [regeneratingProfile, setRegeneratingProfile] = useState(false);
  const [toxicUserIsActive, setToxicUserIsActive] = useState(true);

  useEffect(() => {
    getToxicUsers();
  }, []);

  const handleAddProfile = (e) => {
    e.preventDefault();
  };

  const handleAddProfileSummary = (e) => {
    e.preventDefault();
  };

  const handleAddAudienceSummary = (e) => {
    e.preventDefault();
  };

  async function getToxicUsers() {
    try {
      const response = await axios.get(`${apiUrl}/toxicUsers/getAllToxicUsers`);
      const data = await response.data;
      setProfile(data);
    } catch (error) {
      console.error('Error al obtener los toxicUsers:', error);
    }
  };

  const handleCreateProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newProfileObj = {
      username: newProfile,
      partisanship: partisanship,
      llmProfile: profileSummary,
      llmSummaryOfTheLikelyAudienceProfile: audienceSummary,
    };

    const existingProfile = profile.find(p => p.username.toLowerCase() === newProfile.toLowerCase());
    if (existingProfile) {
      setMessage("User already created");
      setIsLoading(false);
      setShowModal(false);
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/toxicUsers/addToxicUser`, newProfileObj);
      if (response.data?.code !== 429){
        const { message } = response.data;
        setMessage(message);
        if (!message) {
          getToxicUsers();
          handleCancel();
        }
      } else {
        setMessage("Twitter rate limit exceeded, please try again in a few minutes.");
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    } finally {
      setIsLoading(false);  // detiene el spinner y cierra el modal
      setShowModal(false);  // cierra el modal después de la operación
      setMessage(
        <>
          User created. <br /> Audience Summary for this user will be created in a few moments.
        </>
      )
    }
  };

  const handleUpdateProfile = async (e) => {

    const updatedProfileObj = {
      userId: editingId,
      updatedProfile: {
        username: newProfile,
        partisanship: partisanship,
        llmProfile: profileSummary === "Error to generate profile summary, please try again in a few minutes."? '' : profileSummary,
        llmSummaryOfTheLikelyAudienceProfile: audienceSummary === "Error to generate audience summary, please try again in a few minutes."? '' : audienceSummary,
        active: toxicUserIsActive
      },
    };

    const otherProfiles = profile.filter(p => p._id !== editingId);
    const existingProfile = otherProfiles.find(p => p.username.toLowerCase() === newProfile.toLowerCase());
    if (!existingProfile) {
      try {
       const response = await axios.patch(`${apiUrl}/toxicUsers/`, updatedProfileObj);

        if (response) {
          getToxicUsers();
          handleCancel();
        } else {
          console.error('Error al actualizar el usuario tóxico:', response.statusText);
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      }
    } else {
      setMessage("User already created");
    }
  };

  const handleDelete = async (row) => {
    setShowModalConfirm(true);
    setDeletingRow(row);
  };

  const handleCancelDelete = () => {
    setDeletingRow(null);
    setShowModalConfirm(false);
  };

  const handleConfirmDelete = async () => {
    const userId = deletingRow.original._id;

    try {
      const response = await axios.delete(`${apiUrl}/toxicUsers/${userId}`);

      if (response) {
        getToxicUsers();
        setShowModalConfirm(false);
      } else {
        console.error('Error al eliminar el usuario tóxico:', response.statusText);
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  const handleCancel = () => {
    setNewProfile('');
    setPartisanship('center');
    setProfileSummary('');
    setAudienceSummary('');
    setEditingId('');
    setShowModal(false);
    setShowModalInfo(false);
    setMessage('');
    setToxicUserSelected();
    setRegeneratingProfile(false);
    setToxicUserIsActive (true);
  };

  const handleModal = () => {
    setShowModal(true);
  };

  const handleRowClick = async (row) => {
    setShowModalInfo(true);
    setShowModal(true);
    const editingProfile = row;
    setToxicUserSelected(editingProfile);
    setEditingId(editingProfile._id);
    setNewProfile(editingProfile.username || '');
    setPartisanship(editingProfile.partisanship || 'unspecified');
    setProfileSummary(editingProfile.llmProfile || '');
    setAudienceSummary(editingProfile.llmSummaryOfTheLikelyAudienceProfile || '');
    setToxicUserIsActive (editingProfile.active);
  };

  const handleGenerateProfileSummary = async () => {
     if (profileSummary){
        setRegeneratingProfile(true);
        setShowModalConfirm(true);
     } else {
        generateProfileSummary();
     }
  };  
  const handleGenerateAudienceSummary = async () => {
    if (audienceSummary){
        setRegeneratingProfile(false);
        setShowModalConfirm(true);
    } else {
      generateAudienceSummary();
    } 
  };

  const generateProfileSummary = async () => {
      setIsLoading(true);
      setShowModalConfirm(false);
      try {
          const response = await axios.patch(`${apiUrl}/toxicUsers/generateProfileSummary`, { toxicUserId: editingId });
          setIsLoading(false);
          if (response.data.updatedUser) {
              getToxicUsers();
              setProfileSummary(response.data.updatedUser?.llmProfile);
          } else {
            setProfileSummary("Error to generate profile summary, please try again in a few minutes.")
              console.error('Error to generate profile summary', response.statusText);
          }
      } catch (error) {
          console.error('Error to generate profile summary', error);
      }
  };  
  const generateAudienceSummary = async () => {
      setIsLoading(true);
      setShowModalConfirm(false);
      try {
          const response = await axios.patch(`${apiUrl}/toxicUsers/generateAudienceSummary`, { toxicUser: toxicUserSelected });
          setIsLoading(false);
          if (response.data.updatedUser) {
              getToxicUsers();
              setAudienceSummary(response.data.updatedUser?.llmSummaryOfTheLikelyAudienceProfile);
          } else {
              setAudienceSummary ("Error to generate audience summary, please try again in a few minutes.")
              console.error('Error to generate audience summary', response.statusText);
          }
      } catch (error) {
          console.error('Error to generate audience summary', error);
      }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'username',
        header: 'Profile',
        size: 140,
        filterFn: 'includesString',
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Avatar 
              src={row.original.profile_image_url || placeholderImg}
              loading='lazy'
            />
            <Link
              href={`https://x.com/${row.original.username}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event) => event.stopPropagation()}
            >
              {row.original.username}
            </Link>
          </Box>
        ),
      },
      {
        accessorKey: 'name',
        id: 'Name',
        header: 'Name',
        size: 130,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'partisanship',
        id: 'Partisanship',
        header: 'Partisanship',
        size: 30,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'llmProfile',
        id: 'Profile Summary',
        header: 'Profile Summary',
        size: 300,
        filterFn: (row, columnId, filterValue) => {
            const value = row.original.llmProfile;
        
            if (filterValue.toLowerCase() === 'empty') {
                return value === null || value === undefined || value === '';
            }
            if (value === null || value === undefined || value === '') {
                return false;
            }
            return value.toLowerCase().includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '250px' }}>
              {cell.getValue()}
            </div>
          </Box>
        ),
      },
      {
        accessorKey: 'llmSummaryOfTheLikelyAudienceProfile',
        id: 'Audience Summary',
        header: 'Audience Summary',
        size: 300,
        filterFn: (row, columnId, filterValue) => {
            const value = row.original.llmSummaryOfTheLikelyAudienceProfile;

            if (filterValue.toLowerCase() === 'empty') {
                return value === null || value === undefined || value === '';
            }
            if (value === null || value === undefined || value === '') {
                return false;
            }
            return value.toLowerCase().includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '250px' }}>
              {cell.getValue()}
            </div>
          </Box>
        ),
      },
      {
        accessorKey: 'active',
        id: 'active',
        header: 'State',
        size: 50,
        Cell: ({ row }) => (
            <>
                {row.original.active === false ? <TableLabel style = {{color: 'red'}}>Inactive</TableLabel> : <TableLabel style = {{color: 'green'}}>Active</TableLabel>}
            </>
        )
      },
      {
        accessorKey: 'addedManually',
        id: 'addedManually',
        header: 'Added Manually',
        size: 30,
        Cell: ({ row }) => (
          <>
            {row.original?.addedManually ? "Yes" : "No"}
          </>
        )
      },
      {
        accessorKey: 'Delete',
        header: 'Delete',
        size: 50,
        enableSorting: false,
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) =>{event.stopPropagation(); handleDelete(row)}}
          >
            Delete
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: profile,
    initialState: {
      density: 'compact',
      columnPinning: { left: ['profile'] },
    },
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    muiTableBodyRowProps: ({ row }) => ({
        onClick: () => handleRowClick(row.original),
        style: { cursor: 'pointer' },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
        <Box
            sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
            }}
        >
            {!isLoading ?
                <Button onClick={handleExportAll} startIcon={<FileDownload />}>
                    Export
                </Button>
                : <Spinner />}
        </Box>
    ),
  });

  const handleExportAll = () => {
    handleExportData();
  };

  const handleExportData = async (dateRange) => {
    setIsLoading(true);
    try {
      let response = await axios.get(`${apiUrl}/toxicusers/evaluate`, {
        params: {
          dateRange: JSON.stringify(dateRange)
        }
      });

      const accountsEvaluated = response.data.map(item => ({
          x_handle: item.x_handle,
          partisanship: item.partisanship,
          add_date: item.add_date,
          added_manually: item.added_manually,
          state: item.state,
          posts_analyzed: item.posts_analyzed,
          tpc_count: item.tpc_count,
          tpc_views: item.tpc_views,
          cs_count: item.cs_count,
          cs_views: item.cs_views,
      })
      );

      if (accountsEvaluated.length) {
        const csvData = generateCsv(csvConfigAccounts)(accountsEvaluated);
        download(csvConfigAccounts)(csvData, 'accountsEvaluated');
      } else {
        alert('There are no posts on your current selection. Please make another selection.');
      }
      setIsLoading(false);
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  return (
    <>
      <Menu />
      <Container>
        <Header title={'Accounts to Watch'} />
        <CenterHorizontally style={{ margin:"40px 0px"}}>
          <Button onClick={handleModal} variant="contained" color="primary" >
            Add Profile
          </Button>
        </CenterHorizontally>
        {showModal && message === "" &&
          <Modal openModal={showModal && message === ""}
                 title={editingId !== '' ? "Toxic User Info" : "Add account to watch"}
                 subtitle={editingId !== '' ? "" : "Add a new X profile that SMD will monitor for toxic political content"}
                 showActions={editingId === ''}
                 handleCloseModal={handleCancel}
                 handleSubmit={handleCreateProfile}
                 isDisabled={!newProfile || isLoading} 
                 buttonAction={'Create Profile'}
           >
            {isLoading ? <Spinner /> :
              <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                <AddProfileForm onSubmit={editingId !== '' ? handleUpdateProfile : handleAddProfile}>
                  <TextField
                    label="Profile"
                    type="text"
                    value={newProfile}
                    onChange={(e) => setNewProfile(e.target.value.trim())}
                    required
                    autoComplete="off"
                  />
                </AddProfileForm>
                <AddProfileForm style={{marginLeft: '3px'}}>
                    <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                        <FormLabel component="legend" style={{ textAlign: 'left'}}>Partisanship</FormLabel>
                        <RadioGroup
                            name="partisanship"
                            value={partisanship}
                            onChange={(e) => setPartisanship(e.target.value)}
                            row
                            style={{margin: "5px 0px"}}
                        >
                            <FormControlLabel value="center" control={<Radio />} label="Center" />
                            <FormControlLabel value="left" control={<Radio />} label="Left" />
                            <FormControlLabel value="right" control={<Radio />} label="Right" />
                        </RadioGroup>
                    </FormControl>
                    <span style={{fontSize:'12px', textAlign:'left'}}>
                      {!toxicUserSelected || toxicUserSelected?.partisanship === partisanship ? '' : 'Partisanship changed.'}
                    </span>
                </AddProfileForm>
                {editingId !== '' ? (
                    <>
                    <AddProfileForm style={{ marginLeft: '3px' }}>
                        <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                            <FormLabel component="legend" style={{ textAlign: 'left' }}>State</FormLabel>
                            <RadioGroup
                                name="state"
                                value={toxicUserIsActive}
                                onChange={(e) => setToxicUserIsActive(e.target.value === 'true')}
                                row
                                style={{ margin: "5px 0px" }}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Active" />
                                <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                            </RadioGroup>
                        </FormControl>
                        <span style={{ fontSize: '12px', textAlign: 'left' }}>
                            {toxicUserSelected?.active === toxicUserIsActive
                                ? ''
                                : toxicUserIsActive
                                    ? 'This account will now be actively monitored.'
                                    : 'This account will no longer be actively monitored.'}
                        </span>
                    </AddProfileForm>
                    <CenterHorizontally>
                    <SmallBox>
                        <Label style={{ gap: '30px' }} >
                            <CampaignOutlined style={{ color: '#3498db', marginRight: '-18px' }} /> Profile Summary
                            <Button onClick={handleGenerateProfileSummary} variant="contained" color="primary" disabled={isLoading}>
                                {!profileSummary || profileSummary === "" ? "Generate" : "Regenerate"}
                            </Button>
                        </Label>
                        <WhiteContainer>
                            <AddSummaryForm  onSubmit={handleAddProfileSummary}>
                                <TextField
                                    label="Profile Summary"
                                    type="text"
                                    value={profileSummary}
                                    onChange={(e) => setProfileSummary(e.target.value)}
                                    multiline
                                />
                            </AddSummaryForm>
                        </WhiteContainer>
                    </SmallBox>
                    </CenterHorizontally>
                    <CenterHorizontally>
                    <SmallBox>
                        <Label style={{ gap: '30px' }} >
                            <PersonOutlineOutlined style={{ color: '#3498db', marginRight: '-18px' }} /> Audience Summary
                            <Button onClick={handleGenerateAudienceSummary} variant="contained" color="primary" disabled={isLoading}>
                                {!audienceSummary || audienceSummary === "" ? "Generate" : "Regenerate"}
                            </Button>
                        </Label>
                        <WhiteContainer>
                            <AddSummaryForm onSubmit={handleAddAudienceSummary}>
                                <TextField
                                    label="Audience Summary"
                                    type="text"
                                    value={audienceSummary}
                                    onChange={(e) => setAudienceSummary(e.target.value)}
                                    multiline
                                />
                            </AddSummaryForm>
                        </WhiteContainer>
                    </SmallBox>
                    </CenterHorizontally>                    
                    </>
                ) : (
                    <span style={{ marginBottom: '20px', display: 'block' }}>
                        Profile summaries and Audience summaries will automatically be generated for all new watchlist profiles.
                    </span>
                )}
              </div>
             }
             {editingId !== '' && (
                 <Button style={{ marginTop: '10px' }} onClick={() => { handleUpdateProfile() }} variant="contained" color="primary" disabled={isLoading}>
                     Close
                 </Button>)
             }
          </Modal>
        }
        {showModalConfirm &&
          <Modal openModal={showModalConfirm} showActions={false} subtitle={showModalInfo? 'Are you sure to regenerate this summary?' : 'Are you sure to delete?'} >
            <BoxesContainer>
              {!showModalInfo && 
              <CenterText>
                <label>
                  <strong>{deletingRow.original.username}</strong>
                </label>
              </CenterText>
              }
            </BoxesContainer>
            <BoxesContainer>
              <MarginRight>
                <Button onClick={!showModalInfo? handleConfirmDelete : regeneratingProfile? generateProfileSummary : generateAudienceSummary} variant="contained" color="primary" >
                  Yes
                </Button>
              </MarginRight>
              <Button onClick={handleCancelDelete} variant="contained" color="primary" >
                No
              </Button>
            </BoxesContainer>
          </Modal>
        }
        {message !== "" &&
          <Modal openModal={message !== ""} showActions={false}>
            <BoxesContainer>
              <label>
                {message}
              </label>
            </BoxesContainer>
            <BoxesContainer>
              <Button onClick={handleCancel} variant="contained" color="primary" >
                OK
              </Button>
            </BoxesContainer>
          </Modal>
        }
        <MarginHorizontal>
          <MaterialReactTable table={table} />
        </MarginHorizontal>
      </Container>
    </>
  );
};

export default CreateToxicProfile;
