import axios from 'axios';
import { store } from '../app/store';
import { setSessionExpired } from '../features/authSlice';

axios.interceptors.request.use(
    async (config) => {
        if (!config.url.toString().includes('/auth')) {
            const state = store.getState();
            const token = state.auth.user.token;
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.config && !error.config.url.includes("twitterApi")) {
            if (error.response && error.response.status === 401) {
                store.dispatch(setSessionExpired());
            }
        }
        return Promise.reject(error);
    }
  );

