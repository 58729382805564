import styled from 'styled-components';
import { Select, MenuItem } from '@mui/material';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 15px 0 0 0;
`

const CustomSelect = styled(Select)({
    width: '150px',
    color: 'red',
    marginTop: 20,
    background: '#ddd',
    height: 25,
    '& .MuiMenuItem-root': {
        color: 'red',
        padding: 20,
    },
});

const Selector = ({optionChange, disabled, value, impact}) => {
    
    //Función en el componente padre que usa el valor seleccionado
    const handleChange = (e) => {
        optionChange(e.target.value);  
    };

    return (
        <Container>
            <CustomSelect
                value={value}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disabled={disabled}
            >
                {impact ? (
                    [
                        <MenuItem key='me' value='me'>Me</MenuItem>,
                        <MenuItem key='all' value='all'>All</MenuItem>
                    ]
                ) : (
                    [
                        <MenuItem key='last week' value='last week'>Last Week</MenuItem>,
                        <MenuItem key='last month' value='last month'>Last Month</MenuItem>,
                        <MenuItem key='all' value='all'>All time</MenuItem>
                    ]
                )}
            </CustomSelect>
        </Container>
    );
};

export default Selector;
