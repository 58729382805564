import { useState } from 'react';
import styled from 'styled-components';
import { RadioGroup, Radio, FormControlLabel, Popover, Typography, Button } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

const Container = styled.div`
    background-color: #f5f5f5;
    border: 0.5px solid #3498db;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    position: relative;
    min-width: 200px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const WhiteContainer = styled.div`
    background-color: #fff;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: justify;
    width: 100%;
`;

const EditableAnswer = styled(({ isToxic, ...rest }) => <textarea {...rest} />)`
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    height: 150px;
    line-height: 1.2;
    margin: 0;
    padding: 10px;
    width: 100%;
    resize: none;
    pointer-events: ${({ isToxic }) => (isToxic ? 'none' : 'auto')};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-right:5px;
`;

const ColumnComponent = styled.div`
    display: flex;
    flex-direction: column;
`;

// Componente para usar en la pagina correspondiente
// Recibe funciones para enviar el texto ingresado, mostrar la toxicidad y limpiar los datos.
const MeasureBox = ({ onSubmit, toxicities, clearToxicity }) => {
    const [tweetText, setTweetText] = useState('');
    const [selectedGPT, setSelectedGPT] = useState('gpt-3.5-turbo');
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);


    // Maneja el evento del radioButton para actualizar el tipo de GPT seleccionado
    const handleChange = (event) => {
        setSelectedGPT(event.target.value);
    };

    // Maneja el evento del texto ingresado
    const handleTextChange = (e) => {
        const text = e.target.value;
        setTweetText(text);
    };

    // Llama a función pasada con argumentos ingresados (gpt y texto)
    const handleSubmit = () => {
        onSubmit(tweetText, selectedGPT);
    };

    // Limpia los datos de toxicidad, texto ingresado y tipo de GPT seleccionado
    const handleClear = () => {
        clearToxicity();
        setTweetText('');
        setSelectedGPT('gpt-3.5-turbo');
    };

    const handleHelpButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpen(false); 
    };

    return (
        <Container>
            <WhiteContainer>
                <EditableAnswer
                    value={tweetText}
                    onChange={handleTextChange}
                    maxLength={280}
                    isToxic={toxicities !== ''}
                />
            </WhiteContainer>
            <ButtonContainer>
                {toxicities === '' && (<RadioGroup
                    aria-label="options-selector"
                    name="options-selector"
                    value={selectedGPT}
                    onChange={handleChange}
                    style={{ marginLeft: "15px" }}
                >
                    <FormControlLabel value="gpt-3.5-turbo" control={<Radio />} label="GPT-3.5-Turbo" />
                    <FormControlLabel value="gpt-4o" control={<Radio />} label="GPT-4o" />
                    <FormControlLabel value="gpt-4-turbo" control={<Radio />} label="GPT-4-Turbo" />
                </RadioGroup>)}
                {toxicities !== '' && (
                    <ColumnComponent>
                        <span>GPT toxicity: <strong>{toxicities.toxicity === null ? "0" : toxicities.toxicity}</strong></span>
                        <br></br>
                        <span>Perspective toxicity: <strong>{toxicities.toxicityPerspective === null ? "0" : toxicities.toxicityPerspective}</strong></span>
                    </ColumnComponent>
                )}
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography sx={{ p: 1, fontSize: '14px', maxWidth: '200px' }}>Get an evaluation tweet contents or other text (not a URL) for toxicity, from an LLM and Perspective.</Typography>
                </Popover>
                <ColumnComponent>
                <HelpOutline fontSize='small' aria-describedby={open ? 'help-popover' : undefined} onClick={(e) => handleHelpButtonClick(e)} style={{ color: '#3498db', alignSelf:'flex-end', cursor: 'pointer' }} />
                <Button disabled={tweetText.length === 0} onClick={toxicities !== '' ? handleClear : handleSubmit}
                        variant='contained'
                        style={{
                            padding: '5px 10px', marginTop: '45px', width: '150px'
                        }}
                    >
                    {toxicities !== '' ? "CLEAR" : "EVALUATE"}
                </Button>
                </ColumnComponent>
            </ButtonContainer>
        </Container>
    );
};

export default MeasureBox;
