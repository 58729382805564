import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/authSlice';
import filtersReducer from '../features/filtersSlice';
import toastsReducer from '../features/toastSlice';
import {
    persistStore,
    persistReducer,
    REHYDRATE,
    FLUSH,
    PAUSE,
    PURGE,
    REGISTER,
    PERSIST
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};

const rootReducer = combineReducers({auth: userReducer, filters: filtersReducer, toasts: toastsReducer});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [REHYDRATE, FLUSH, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export let persistor = persistStore(store);

//persistor.purge();