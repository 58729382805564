import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Menu from '../components/Menu';
import { Box, keyframes, Button, IconButton } from '@mui/material';
import Blog from '../components/Blog';
import { ArrowDownward } from '@mui/icons-material';

const Logo = styled(Box)`
  width: 560px;
  height: auto;
  max-width: 100%;
  margin-left: 40px;
  animation: slideIn 1.5s ease-out;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media (max-width: 600px) { // Adjust width for mobile
    width: 85%;
    margin-left: 25px;
  }
`

const StyledBottomArrow = styled.div`
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #525252;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    z-index: 1000;
    animation: bounce 2s infinite;
    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
        40% { transform: translateY(-10px); }
        60% { transform: translateY(-5px); }
    }
`;

const Home = () => {
    const [showButton, setShowButton] = useState(true);

    const handleScroll = () => {
        const isBottom =
            window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
        setShowButton(!isBottom);
    };

    const handleScrollDown = () => {
        window.scrollBy({ top: 400, behavior: "smooth" });
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <Menu />
            <Box
                sx={{
                    position: 'relative',
                    height: '40vh',
                    backgroundImage: 'url(/images/background1.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Logo
                    component="img"
                    src="/images/logoCHP.png"
                    alt="Logo"
                />
                <Button variant="contained" href="/recent-toxic-content-dashboard" sx={{ marginLeft: '40px', marginTop: '40px' }}>GET STARTED</Button>
            </Box >
            <Blog />
            {showButton && (
                <StyledBottomArrow>
                    <IconButton onClick={handleScrollDown}>
                        <ArrowDownward sx={{color: 'white'}} />
                    </IconButton>
                </StyledBottomArrow>
            )}
            {/*
            <Box sx={{
                backgroundColor: '#ECEFF1',
                height: '100vh',
                width: '100vw', // Ajusta el ancho para que ocupe todo el ancho de la pantalla
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    width: 700,
                    textAlign: 'center'
                }}>
                    <Typography variant="h2" sx={{
                        color: 'GrayText',
                        fontSize: '24px',
                        fontWeight: '400',
                        fontFamily: 'unset',
                        fontStyle: 'italic',
                        animation: `${zoomIn} 2s ease-in-out`,
                    }}>
                        <strong>"United in Diversity: Building Bridges of Understanding"</strong><br />
                        <br />At Civic Health Project, we believe that the true strength of a community lies in its ability to embrace diversity and find common ground. We are dedicated to reducing partisan polarization, fostering respectful dialogue, and promoting collaboration among all voices. Together, we can transform division into unity and build a more harmonious and understanding future.
                    </Typography>
                </Box>
            </Box>
                */}
        </>
    );
};

export default Home;