import axios from 'axios';
import { apiUrl } from '../utils/common';  

const trackEvent = async (eventType, details, email) => {
  try {
    await axios.post(`${apiUrl}/tracking/trackEvent`, {
      eventType,
      details,
      email
    });
  } catch (error) {
    console.error('Error enviando evento:', error);
  }
};

export default trackEvent;