import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import {
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete
} from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import Menu from '../components/Menu';
import Modal from '../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../features/authSlice';
import dayjs from 'dayjs';


const Container = styled(Paper)`
  max-width: 100%;
  margin: 0;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2 !important;
`;

const MainContent = styled.div`
  flex: 1;
  height: 500px;
  margin-top: 30px;
`;

const AddUserForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 10px;
`;

const UserScreen = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('');
  const [partisanship, setPartisanship] = useState('center')
  const [editUserId, setEditUserId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isAddUserButtonDisabled, setIsAddUserButtonDisabled] = useState(true);
  const [showModalConfirm, setShowModalConfirm] = useState(null);
  const [deletingRow, setDeletingRow] = useState(null);
  const [twitterAccounts, setTwitterAccounts] = useState([]);
  const [stylesData, setStylesData] = useState([]);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const fetchData = async () => {
      setStylesData(await getStyles());
      await getTags();
      await getUsers();
    };
  
    fetchData();
  }, []);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'newUserEmail') {
      setNewUserEmail(value.toLowerCase());
    } else if (name === 'newUserRole') {
      setNewUserRole(value);
    } else if (name === 'partisanship') {
        setPartisanship(value);
    }
  };

  async function getUsers() {
    try {
      const response = await axios.get(`${apiUrl}/users/getUsers`)
      const data = await response.data;
      setUserData(data);
    } catch (error) {
      console.error('Error al obtener los Users:', error);
    }
  };

  const getStyles = async () => {
    try {
        const res = await axios.get(`${apiUrl}/writingStyles/getStyles`);
        const data= await res.data;
        
        return data;
    } catch(err) {
        console.error('Failed to fetch styles: ', err)
    }
  };

  const getTags = async () => {
    try {
        const res = await axios.get(`${apiUrl}/tags/getTags`);
        const data= await res.data;
        setTagsData(data);
        return data;
    } catch(err) {
        console.error('Failed to fetch tags: ', err)
    }
};

  useEffect(() => {
    setIsAddUserButtonDisabled(!username || !newUserEmail || !newUserRole);
  }, [username, newUserEmail, newUserRole]);

  const handleAddUser = async () => {
    if (!username || !newUserEmail || !newUserRole) {
      setNameError('Please fill in all fields.');
      setEmailError('');
      return;
    }
    if (!isEmailValid(newUserEmail)) {
      setEmailError('Please enter a valid email address.');
      setNameError('');
      return;
    }

    await createTags();
    const newTags = await getTags();
    const tagNames = tags.map(tag => tag.name.toLowerCase());
    const filteredTags = newTags.filter(newTag => tagNames?.includes(newTag.name.toLowerCase()));
    
    setNameError('');
    setEmailError('');

    try {
      const newUserObj = {
        username: username,
        email: newUserEmail,
        role: newUserRole,
        twitterAccount: twitterAccounts,
        partisanship: partisanship,
        styles: selectedStyles.map(obj => obj._id),
        tags: filteredTags.map(obj => obj._id),
      };
      
      const userExists = userData.find(user => user.email === newUserEmail);
      if (userExists) {
        setEmailError('User with this email already exists.');
        return;
      }
      setIsAddUserButtonDisabled(true);
      const response = await axios.post(`${apiUrl}/users/addUser`, newUserObj);

      if (response) {
        await getUsers();
        handleCancel();
      } else {
        console.error('Error al crear un nuevo usuario:', response.statusText);
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
    setIsAddUserButtonDisabled(false);
  };
  
  const createTags = async () => {
    // Filter out the new tags (without _id)
    const newTags = tags.filter(tag => !tag._id);

    if (newTags.length > 0) {
        try {
            const response = await axios.post(`${apiUrl}/tags/CreateTags`, newTags);
            const createdTags = response.data;

            // Update the tags list with newly created tags having _id
            setTags(prevTags => {
                const updatedTags = prevTags.map(tag => {
                    const createdTag = createdTags.find(ct => ct.name === tag.name);
                    return createdTag ? createdTag : tag;
                });
                return updatedTags;
            });

        } catch (err) {
            console.error('Failed to create tags: ', err);
        }
    }
  };

  const handleEdit = async (row) => {
    if (stylesData?.length < 1 || !stylesData) {       
        const styles = await getStyles();
        setSelectedStyles(styles.filter(obj => row.styles?.includes(obj._id)));
      } else {
        setSelectedStyles(stylesData.filter(obj => row.styles?.includes(obj._id)));
      }
    
      if (tagsData.length < 1 || !tagsData) {       
        const tags = await getTags();
        setTags(tags.filter(obj => row.tags?.includes(obj._id)));
      } else {
        setTags(tagsData.filter(obj => row.tags?.includes(obj._id)));
      }
    setIsDialogOpen(true);
    setUsername(row.username);
    setNewUserEmail(row.email);
    setNewUserRole(row.role);
    setPartisanship(row.partisanship || 'center')
    setTwitterAccounts(row.twitterAccount || []);
    setEditUserId(row._id);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();

    if (!username || !newUserEmail || !newUserRole) {
      setNameError('Please fill in all fields.');
      setEmailError('');
      return;
    }
    if (!isEmailValid(newUserEmail)) {
      setEmailError('Please enter a valid email address.');
      setNameError('');
      return;
    }

    await createTags();
    const newTags = await getTags();
    const tagNames = tags.map(tag => tag.name.toLowerCase());
    const filteredTags = newTags.filter(newTag => tagNames?.includes(newTag.name.toLowerCase()));

    const updatedUserObj = {
      id: editUserId,
      username,
      newUserEmail,
      newUserRole,
      twitterAccounts,
      partisanship,
      styles: selectedStyles.map(obj => obj._id),
      tags: filteredTags.map(obj => obj._id),
    };
    
    try {
      setIsAddUserButtonDisabled(true);
      const response = await axios.patch(`${apiUrl}/Users/`, updatedUserObj);
      
      if (response) {
        if (updatedUserObj.id === user._id) {
          dispatch(updateUser(updatedUserObj))
        }
        await getUsers();
        handleCancel();
      } else {
        console.error('Error updating user', response.statusText);
      }
    } catch (error) {
      console.error('Error while updating user:', error);
    }
    setIsAddUserButtonDisabled(false);
  };

  const handleChangeStyles = (event, newValue) => {
    setSelectedStyles(newValue);
  };
  
  const handleTagChange = (event, newValue) => {
    // Separate new tags (free text) from existing tags
    const existingTags = newValue.filter(tag => tag._id);
    const newTags = newValue.filter(tag => !tag._id).map(tag => ({ name: typeof tag === 'string' ? tag : tag.name }));
    // Combine existing tags and new tags
    setTags([...existingTags, ...newTags]);
};

  const handleDelete = async (row) => {
    setShowModalConfirm(true);
    setDeletingRow(row);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setUsername('');
    setNewUserEmail('');
    setNewUserRole('');
    setTwitterAccounts([]);
    setPartisanship ('center');
    setEditUserId(null);
    setNameError('');
    setEmailError('');
    setIsAddUserButtonDisabled(true);
    setShowModalConfirm(false);
    setDeletingRow(null);
    setSelectedStyles([]);
    setTags([]);
  };

  const handleConfirmDelete = async () => {
    const userId = deletingRow._id;

    try {
      const response = await axios.delete(`${apiUrl}/Users/${userId}`);

      if (response) {
        getUsers();
        setShowModalConfirm(false);
      } else {
        console.error('Error al eliminar el usuario:', response.statusText);
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.mainTwitterAccount,
        id: 'mainTwitterAccount',
        header: 'X Acc.',
        size: 150,
      },
      {
        accessorFn: (row) => row.email,
        id: 'email',
        header: 'Email',
        size: 200,
      },
      {
        accessorFn: (row) => row.interventionCount,
        id: 'interventionCount',
        header: 'Total Interventions',
        size: 100,
      },
      {
        accessorFn: (row) => (row.lastInterventionDate ? dayjs(row.lastInterventionDate).format('h:mmA - MMM D, YYYY'): null),
        id: 'lastInterventionDate',
        header: 'Last Intervention',
        size: 200,
      },
      {
        accessorFn: (row) => row.totalEngagementScore,
        id: 'totalEngagementScore',
        header: 'Total Engagement Score',
        size: 50,
      },
      {
        accessorFn: (row) => row.role,
        id: 'role',
        header: 'Role',
        size: 100,
      },
      {
        accessorFn: (row) => (row.verified ? 'Yes' : 'No'),
        id: 'verified',
        header: 'Verified',
        size: 100,
      },
      {
        accessorFn: (row) => (
            <Button style={{width:'145px'}} variant="contained" onClick={() => handleEdit(row)}>Edit</Button>
        ),
        id: 'edit',
        header: 'Edit',
        size: 50,
        enableSorting: false,
      },
      {
        accessorFn: (row) => (
            <Button style={{width:'145px'}} variant="contained" onClick={() => handleDelete(row)}>Delete</Button>
        ),
        id: 'delete',
        header: 'Delete',
        size: 50,
        enableSorting: false,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: userData,
    initialState: {
      density: 'compact',
      columnPinning: { left: ['username'] },
    },
  });

  return (
    <>
    <Menu />
    <Container>
      <Header title='User Screen' />
      <Button style={{width:'145px', alignSelf: 'center', margin: '40px 0px 20px'}} variant="contained" onClick={() => setIsDialogOpen(true)}>
        Add User
      </Button>
      <MainContent>
          <div style={{margin: '0px 50px'}}>
            <MaterialReactTable table={table} elevation={4}/>
          </div>
      </MainContent>  
        <Modal openModal={isDialogOpen} title={editUserId !== null ? 'Edit User' : 'Add User'} showActions={true} handleCloseModal={handleCancel} handleSubmit={editUserId !== null ? handleUpdateUser : handleAddUser} isDisabled={isAddUserButtonDisabled} buttonAction={editUserId !== null ? 'SAVE CHANGES' : 'ADD USER'} >
            <AddUserForm>
              <TextField
                label="Name User"
                name="username"
                value={username}
                onChange={handleInputChange}
                error={!!nameError}
                helperText={nameError}
              />
              <TextField
                label="Email"
                type="email"
                name="newUserEmail"
                value={newUserEmail}
                onChange={handleInputChange}
                error={!!emailError}
                helperText={emailError}
              />
              <FormControl>
                <InputLabel>Partisanship</InputLabel>
                <Select
                  value={partisanship}
                  onChange={handleInputChange}
                  label="partisanship"
                  name="partisanship"
                >
                  <MenuItem value="center">Center</MenuItem>
                  <MenuItem value="left">Left</MenuItem>
                  <MenuItem value="right">Right</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Role</InputLabel>
                <Select
                  value={newUserRole}
                  onChange={handleInputChange}
                  label="Role"
                  name="newUserRole"
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="collaborator">Counterspeaker</MenuItem>
                </Select>
              </FormControl>
              <Autocomplete
                    multiple
                    options={stylesData}
                    disableCloseOnSelect               
                    filterSelectedOptions
                    getOptionLabel={(option) => option.name}
                    value={selectedStyles}
                    onChange={handleChangeStyles}
                    renderInput={(params) => (
                          <TextField {...params} label='Styles' />
                    )}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
              />
              <Autocomplete
                    multiple
                    options={tagsData}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.name}
                    value={tags}
                    onChange={handleTagChange}
                    renderInput={(params) => <TextField {...params} label="Tags" />}
                    freeSolo
                    style={{ maxWidth: '350px' }}
              />
          </AddUserForm>
      </Modal>
      <Modal openModal={showModalConfirm} showActions={false} subtitle={'Are you sure to delete?'} >
            <div style={{marginBottom: '20px'}}>
            <label>
              <strong>{deletingRow?.username}</strong>
            </label>
            </div>
            <ButtonContainer>
              <Button
                style={{ width: '145px', marginBottom: '10px' }}
                onClick={handleConfirmDelete} variant="contained"
              >
                Yes
              </Button>
              <Button
                style={{ width: '145px', marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}
                onClick={handleCancel} variant="contained" color="primary"
              >
                No
              </Button>
            </ButtonContainer>
        </Modal>

    </Container>
    </>  
  );
};

export default UserScreen;