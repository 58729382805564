import { useMemo, useEffect, useState, useRef } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { Box, Link, Switch, TextField, MenuItem, Button, Autocomplete, Avatar } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components';
import axios from 'axios';
import dayjs from 'dayjs';
import Header from '../components/Header';
import { apiUrl } from '../utils/common';
import Menu from '../components/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilters, clearPagination, updateFilters, updatePagination } from '../features/filtersSlice';
import { updateUser } from '../features/authSlice';
import placeholderImg from '../assets/images/user-placeholder.png';

const Container = styled.div`
    margin: 20px 50px;
    @media (max-width: 768px) {
        margin: 20px 20px;
    }
`;

const HeaderContainer = styled.div`
    margin-top: 50px;
`;

const TableContainer = styled.div`
    margin: 0px 0 0 0;
`;

const FieldWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    padding: 0;
`;

const FieldLabel = styled.div`
    text-align: left;
    font-size: 13px;
    margin-right: 5px;
`;
const FieldValue = styled.div`
    text-align: center;
    display: flex;
    margin: 5px;
`;
const FiltersContainer = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 0px;
    }
`;

const Home = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [addedFilters, setAddedFilters] = useState(false);
    const [topics, setTopics] = useState([]);
    const [toxicUsers, setToxicUsers] = useState([]);
    const blockingUsers = useSelector((state) => state.auth.user?.blockedUsers) ?? [];
    const blockingUserIds = blockingUsers.map(user => user.userId);
    const storedFilters = useSelector((state) => state.filters?.filters) ?? {};
    const [filters, setFilters] = useState({
        selectedPeriod: storedFilters?.selectedPeriod ?? 'yesterday',
        addedManually: storedFilters?.addedManually ?? false,
        intervened: storedFilters?.intervened ?? 'notIntervened',
        selectedTopics: storedFilters?.selectedTopics ?? [],
        selectedToxicUser: storedFilters?.selectedToxicUser ?? 'all', 
        partisanship: storedFilters?.partisanship ?? 'all',
        minimumToxicScore: storedFilters?.minimumToxicScore ?? 5,
        blockingUsers: storedFilters?.blockingUsers ?? blockingUserIds
    });
    const [rowCount, setRowCount] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');
    const savedFilters = useSelector((state) => state.filters?.savedFilters);
    const savedPagination = useSelector((state) => state.filters?.pagination);
    const [auxPagination, setAuxPagination] = useState();
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const isInitialRender = useRef(true);
    const [postsIds, setPostsIds] = useState([]);
    const resetPagination = { pageIndex: 0, pageSize: 10 };
    const [pagination, setPagination] = useState(savedPagination? savedPagination : resetPagination);
    const [isAddBlockingUsers, setIsAddBlockingUsers] = useState(false);

    const cleanUsername = (username) => {
        return username.replace(/[\uD800-\uDFFF]./g, '');
    };

    const handleAddedFilters = () => {
        setAddedFilters(true);
        setPagination(resetPagination);
        dispatch(updateFilters(filters));
        fetchData(filters);
    };

    const handleFiltersUpdate = (filterName, filterValue) => {
        setFilters({
            ...filters,
            [filterName]: filterValue
        })
    }

    //Función para limpiar los filtros asignados
    const handleClearFilters = () => {
        setAddedFilters(false);
        const clearedFilters = {
            selectedPeriod: 'yesterday',
            addedManually: false,
            intervened: 'notIntervened',
            selectedTopics: [],
            selectedToxicUser: 'all', 
            partisanship: 'all',
            minimumToxicScore: 5,
            blockingUsers: blockingUserIds
        }
        dispatch(clearFilters(clearedFilters));
        setPagination(resetPagination);
        dispatch(clearPagination());
        setFilters(clearedFilters);
        fetchData(clearedFilters);
        setIsAddBlockingUsers(false)
    };

    //Funcion para obtener los post segun los parametros asignados
    const fetchData = async (
        filters
        ) => {
        setIsLoading(true);
        const { 
            selectedPeriod,
            addedManually,
            intervened,
            minimumToxicScore,
            partisanship,
            selectedToxicUser,
            selectedTopics,
            blockingUsers} = filters;

        if (selectedTopics?.length) {
            let formattedTopics = selectedTopics.map(obj => obj._id);
            var topicsIds = formattedTopics.join(','); 
        }

        await axios
            .get(`${apiUrl}/posts/recentPosts`, {
                params: {
                    selectedPeriod,
                    addedManually,
                    intervened,
                    minimumToxicScore,
                    partisanship,
                    selectedToxicUser,
                    topics: topicsIds ?? selectedTopics,
                    page: isInitialRender.current && savedPagination ? savedPagination?.pageIndex + 1 : pagination?.pageIndex + 1,
                    pageSize: isInitialRender.current && savedPagination ? savedPagination?.pageSize : pagination?.pageSize,
                    search: globalFilter ?? '',
                    userId: userId,
                    blockingUsers: blockingUsers
                },
            })
            .then((response) => {
                setData(response.data.data);
                setRowCount(response.data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('There was an error.', error);
                setIsLoading(false);
            });

            //Trae todos los Ids de los posts para los filtros aplicados
            await axios.get(`${apiUrl}/posts/getPostsIdsByFilters`, {
                params: {
                    selectedPeriod,
                    addedManually,
                    intervened,
                    minimumToxicScore,
                    partisanship,
                    selectedToxicUser,
                    topics: topicsIds ?? selectedTopics,
                    search: globalFilter ?? '',
                    userId: userId,
                    blockingUsers: blockingUsers
                },
            })
            .then((res) => {
                setPostsIds(res.data);
            })
            .catch((error) => {
                console.error('There was an error.', error);
            });
    };

    useEffect(() => {
        getUser();
        getTopics();
        getToxicUsers();
        setAuxPagination(savedPagination);
    }, []);

    useEffect(() => {
        if (storedFilters !== null) {
            //Por el momento se desactiva para el demo
            //setAddedFilters(true);
            fetchData(storedFilters);
        } else {
            fetchData(filters);
        }

        if (isInitialRender.current === false) {
            dispatch(updatePagination(pagination));
        }
    }, [pagination.pageIndex, pagination.pageSize, globalFilter]);

    const getUser = async () => {
        try {
            const res = await axios.get(`${apiUrl}/users/${userId}`)
            const user = res.data;
            dispatch(updateUser(user));
        } catch (err) {
            console.error('Failed to fetch user.', err)
        }
    }

    const getTopics = async () => {
        try {
            const res = await axios.get(`${apiUrl}/topics`)
            setTopics(res.data);
        } catch(err) {
            console.error('Failed to fetch topics', err);
        }
    }

    const getToxicUsers = async () => {
        try {
            const res = await axios.get(`${apiUrl}/toxicUsers/getAllToxicUsers`)
            setToxicUsers(res.data);
        } catch(err) {
            console.error('Failed to fetch topics', err);
        }
    }

    const handleTopicChange = (event, newValue) => {
        handleFiltersUpdate('selectedTopics', newValue)
    }

    //Al ir a tweetDashboard envia tambien el array con los ids de los posts
    const handleRowClick = async (id) => {
        if (id) {
            navigate(`/tweet-dashboard/${id}`, { state: postsIds });
        } else {
            console.error('Invalid postId:', id);
        }
    };

    const handlePaginationChange = (updater) => {
        setPagination((currentPagination) => {
            if (isInitialRender.current) {
                isInitialRender.current = false;
                return savedPagination || currentPagination;
            }

            if (savedPagination && savedPagination !== resetPagination) {
                currentPagination = savedPagination
                const updatedPagination = typeof updater === 'function' ? updater(currentPagination) : updater;
                if (updatedPagination.pageIndex === 0 && auxPagination?.pageIndex === savedPagination.pageIndex === 1|| !auxPagination ) {
                    return savedPagination
                }
                return updatedPagination;
            }
            return resetPagination;
        });
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                accessorKey: 'createdAt',
                header: 'Post Time',
                size: 50,
                Cell: ({ row }) => (
                    <Link
                        href={`https://x.com/${row.original.userDetails?.username}/status/${row.original.twitterPostId}`}
                        onClick={(e) => e.stopPropagation()}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {dayjs(row.original.twitterCreatedAt).format(
                            'MMM D, h:mmA'
                        )}
                    </Link>
                ),
            },
            {
                accessorKey: 'toxicScoreMax',
                header: 'Toxicity',
                size: 40,
                Cell: ({ row }) => (
                    <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                row.original.toxicScoreMax > 5
                                    ? theme.palette.error.dark
                                    : row.original.toxicScoreMax >= 0
                                    ? theme.palette.warning.dark
                                    : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '3ch',
                            p: '0.25rem',
                            justifyContent: 'center',
                            display: 'flex',
                            margin: '0 10px',
                        })}
                    >
                        {row.original.toxicScoreMax}
                    </Box>
                ),
            },
            {
                accessorKey: 'userDetails.partisanship',
                header: 'Partisanship',
                size: 40,
                Cell: ({ row }) => (
                    <div style={{ margin: '0 30px' }}>
                        {row.original.userDetails.partisanship}
                    </div>
                ),
            },
            {
                accessorKey: 'userDetails.name',
                header: 'Post Author',
                size: 120,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Avatar
                            src={row.original.userDetails?.profile_image_url || placeholderImg} 
                            loading='lazy'
                        />
                        <Link
                            href={`https://x.com/${row.original.userDetails?.username}`}
                            onClick={(e) => e.stopPropagation()}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {cleanUsername(row.original.userDetails?.name)}
                        </Link>
                    </Box>
                ),
            },
            {
                accessorKey: 'content',
                header: 'Text',
                size: 800,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '600px' }}>
                            {row.original.content}
                        </div>
                    </Box>
                ),
            },
            // {
            //     accessorKey: 'likes',
            //     header: 'Likes',
            //     size: 40,
            //     Cell: ({ row }) => (
            //         <div style={{ textAlign: 'center', marginRight: '40px' }}>
            //             {row.original.postsToxicMonitor?.likes}
            //         </div>
            //     ),
            // },
            // {
            //     accessorKey: 'shares',
            //     header: 'Shares',
            //     size: 40,
            //     Cell: ({ row }) => (
            //         <div style={{ textAlign: 'center', marginRight: '40px' }}>
            //             {row.original.postsToxicMonitor?.shares}
            //         </div>
            //     ),
            // },
            // {
            //     accessorKey: 'comments',
            //     header: 'Comments',
            //     size: 40,
            //     Cell: ({ row }) => (
            //         <div style={{ textAlign: 'center', marginRight: '40px' }}>
            //             {row.original.postsToxicMonitor?.comments}
            //         </div>
            //     ),
            // },
            // {
            //     accessorKey: 'impressions',
            //     header: 'Impressions',
            //     size: 40,
            //     Cell: ({ row }) => (
            //         <div style={{ textAlign: 'center', marginRight: '40px' }}>
            //             {row.original.postsToxicMonitor?.impressions}
            //         </div>
            //     ),
            // },
        ];

        if (addedFilters && isAddBlockingUsers) {
            baseColumns.push({
                accessorKey: 'userId',
                header: (
                    <div style={{ textAlign: 'center' }}>
                        <div>Blocking</div>
                        <div>User</div>
                    </div>
                ),
                size: 40,
                Cell: ({ row }) => {
                    const isBlocked = blockingUserIds.includes(row.original.userId);
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                                color: 'red',
                                fontWeight: '500',
                                fontSize: '14px'
                            }}
                        >
                            <div style={{ margin: '0 15px' }}>
                                {isBlocked ? 'True' : ''}
                            </div>
                        </Box>
                    );
                },
            });
        }

        return baseColumns;
    }, [addedFilters, isAddBlockingUsers, blockingUsers]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: {
            density: 'compact',
            columnPinning: { left: ['user'] },
        },
        enablePagination: true,
        manualPagination: true,
        enableSorting: false,
        enableColumnFilters: false,
        rowCount,
        state: { pagination: savedPagination || pagination, isLoading, globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: handlePaginationChange,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => handleRowClick(row.original._id),
            style: { cursor: 'pointer' }, // Opcional: Cambia el cursor a un puntero para mejorar la UX
        }),
        muiTableContainerProps: {
            sx: {
                maxWidth: '100vw',
                overflowX: 'auto',
                wordWrap: 'break-word',
            },
        },
    });
    
    return (
        <>
            <Menu />
            <HeaderContainer>
                <Header title='Recent toxic political posts' />
            </HeaderContainer>
                <>
                    <FiltersContainer>
                        <FieldWrapper>
                            <FieldLabel>Post Time </FieldLabel>
                            <FieldValue>
                                <TextField
                                    select
                                    value={filters?.selectedPeriod ?? 'yesterday' }
                                    onChange={(e) => 
                                        handleFiltersUpdate('selectedPeriod', e.target.value)
                                    }
                                    InputProps={{
                                        style: {
                                            background: 'white',
                                            height: 30,
                                            width: 150,
                                            alignItems: 'center',
                                        },
                                    }}
                                    disabled={addedFilters}
                                >
                                    <MenuItem value='yesterday'>Last 24 Hours</MenuItem>
                                    <MenuItem value='last week'>Last Week</MenuItem>
                                    <MenuItem value='last month'>Last Month</MenuItem>
                                    <MenuItem value='all'>All</MenuItem>
                                </TextField>
                            </FieldValue>
                        </FieldWrapper>
                        {/* <FieldWrapper>
                            <FieldLabel>Minimum Toxicity </FieldLabel>
                            <FieldValue>
                                <TextField
                                    select
                                    value={filters?.minimumToxicScore ?? 5}
                                    onChange={(e) =>
                                        handleFiltersUpdate('minimumToxicScore', e.target.value)
                                    }
                                    disabled={addedFilters}
                                    InputProps={{
                                        style: {
                                            background: 'white',
                                            height: 30,
                                            width: 70,
                                            alignItems: 'center',
                                        },
                                    }}
                                >
                                    <MenuItem value='0'>All Posts</MenuItem>
                                    <MenuItem value='1'>1</MenuItem>
                                    <MenuItem value='2'>2</MenuItem>
                                    <MenuItem value='3'>3</MenuItem>
                                    <MenuItem value='4'>4</MenuItem>
                                    <MenuItem value='5'>5</MenuItem>
                                    <MenuItem value='6'>6</MenuItem>
                                    <MenuItem value='7'>7</MenuItem>
                                    <MenuItem value='8'>8</MenuItem>
                                    <MenuItem value='9'>9</MenuItem>
                                    <MenuItem value='10'>10</MenuItem>
                                </TextField>
                            </FieldValue>
                        </FieldWrapper> */}
                        <FieldWrapper>
                            <FieldLabel>Partisanship </FieldLabel>
                            <FieldValue>
                                <TextField
                                    select
                                    value={filters?.partisanship ?? 'all'}
                                    onChange={(e) =>
                                        handleFiltersUpdate('partisanship', e.target.value)
                                    }
                                    disabled={addedFilters}
                                    InputProps={{
                                        style: {
                                            background: 'white',
                                            height: 30,
                                            width: 100,
                                            alignItems: 'center',
                                        },
                                    }}
                                >
                                    <MenuItem value='all'>All</MenuItem>
                                    <MenuItem value='left'>Left</MenuItem>
                                    <MenuItem value='right'>Right</MenuItem>
                                </TextField>
                            </FieldValue>
                        </FieldWrapper>
                        {/* <FieldWrapper>
                            <FieldLabel>Post Author </FieldLabel>
                            <FieldValue>
                                <TextField
                                    select
                                    value={filters?.selectedToxicUser ?? 'all'}
                                    onChange={(e) =>
                                        handleFiltersUpdate('selectedToxicUser', e.target.value)
                                    }
                                    disabled={addedFilters}
                                    SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              maxHeight: 200, // Adjust this value as needed
                                              overflow: 'auto',
                                            },
                                          },
                                        },
                                      }}
                                    InputProps={{
                                        style: {
                                            background: 'white',
                                            height: 30,
                                            width: 200,
                                            alignItems: 'center',
                                        },
                                    }}
                                >   
                                    <MenuItem value="all">All</MenuItem>
                                    {toxicUsers.map((user) => (
                                        <MenuItem key={user._id} value={user._id}>{user.name}</MenuItem>
                                    ))}
                                </TextField>
                            </FieldValue>
                        </FieldWrapper> */}
                        <FieldWrapper>
                            <FieldLabel>Responded</FieldLabel>
                            <FieldValue>
                                <TextField
                                    select
                                    value={filters?.intervened ?? 'notIntervened'}
                                    onChange={(e) =>
                                        handleFiltersUpdate('intervened', e.target.value)
                                    }
                                    disabled={addedFilters}
                                    InputProps={{
                                        style: {
                                            background: 'white',
                                            height: 30,
                                            width: 160,
                                            alignItems: 'center',
                                        },
                                    }}
                                >
                                    <MenuItem value='all'>All</MenuItem>
                                    <MenuItem value='intervened'>Have Responded</MenuItem>
                                    <MenuItem value='notIntervened'>Have Not Responded</MenuItem>
                                </TextField>
                            </FieldValue>
                        </FieldWrapper>
                        {blockingUsers?.length > 0 && (
                            <FieldWrapper>
                                <FieldLabel>Include Blocking-Users Posts</FieldLabel>
                                <FieldValue>
                                    <Switch
                                        onChange={(e) =>{
                                            handleFiltersUpdate('blockingUsers', e.target.checked ? [] : blockingUserIds);
                                            setIsAddBlockingUsers(e.target.checked)
                                        }}
                                        checked={filters?.blockingUsers?.length === 0}
                                        disabled={addedFilters}
                                    />
                                </FieldValue>
                            </FieldWrapper>
                        )}
                        <FieldWrapper>
                            <FieldLabel>Include User-Added Posts</FieldLabel>
                            <FieldValue>
                                <Switch
                                    onChange={(e) => 
                                        handleFiltersUpdate('addedManually', e.target.checked)
                                    }
                                    checked={filters?.addedManually}
                                    disabled={addedFilters}
                                />
                            </FieldValue>
                        </FieldWrapper>
                        <FieldWrapper>
                            <FieldValue>
                            <Autocomplete
                                multiple
                                options={topics}
                                disableCloseOnSelect
                                filterSelectedOptions
                                disabled={addedFilters}
                                getOptionLabel={(option) => option.name}
                                value={filters?.selectedTopics ?? []}
                                onChange={handleTopicChange}
                                style={{ width: 250, marginTop: '10px', backgroundColor: 'white' }}
                                renderInput={(params) => (
                                    <TextField {...params} label='Topics' />
                                )}
                            />
                            </FieldValue>
                        </FieldWrapper>
                        <FieldWrapper>
                            <FieldValue>
                                {addedFilters && (
                                    <Button onClick={() => handleClearFilters()} size="small" color="secondary" variant="outlined" startIcon={<ClearIcon />}>
                                        Reset
                                    </Button>
                                )}
                            </FieldValue>    
                            <FieldValue>
                                {!addedFilters && (
                                <Button onClick={() => handleAddedFilters()} size="small" variant="contained" endIcon={<CheckIcon />}>
                                    Apply
                                </Button>
                                )}
                            </FieldValue>
                        </FieldWrapper>
                    </FiltersContainer>
                </>
            <Container>
                <TableContainer>
                    <MaterialReactTable table={table} />
                </TableContainer>
            </Container>
        </>
    );
};

export default Home;
           
