import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Menu from '../components/Menu';
import { Button, Switch, TextField, MenuItem, TableContainer, Table, TableRow, TableCell, TableBody, TableHead, Paper, Avatar, Tooltip, Typography } from '@mui/material';
import { apiUrl } from '../utils/common';
import axios from 'axios';

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
`;
const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    padding: 0 20px;
`;
const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    &:last-child {
        justify-content: flex-end;
    }
`;
const FieldLabel = styled.div`
    text-align: left;
    font-weight: bold;
`;
const FieldValue = styled.div`
    text-align: right;
`;

const HEALTH = {
    good: '#008450',
    regular: '#EFB700',
    bad: '#B81D13'
}

const AdminSettings = () => {
    const [config, setConfig] = useState({
        minimumToxicScore: '',
        relevantPostThresholdPercentage: '',
        cronJobsRunning: false,
        gptVersion: 'gpt-3.5-turbo',
        cronJobs: [],
    });
    const [cronJobInitStatus, setCronJobInitStatus] = useState(false)
    const [postCollected, setPostCollected] = useState(0)
    const [postMetricCollected, setPostMetricCollected] = useState(0)

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setConfig({
            ...config,
            [name]: newValue
        });
    };

    const getConfig = async () => {
        try {
            let config = await axios.get(`${apiUrl}/config`)
             if(config){
                setConfig(config?.data[0])
                setCronJobInitStatus(config?.data[0]?.cronJobsRunning)
                setPostCollected(config?.data?.postsDocs)
                setPostMetricCollected(config?.data?.toxicPostsMetrics)
             }
        } catch (error) {
            console.log('✌️error --->', error);
        }
    }

    const saveConfig = async (e) => {
        e.preventDefault();
        try {
            const minimumToxicScore = document.getElementsByName('minimumToxicScore')[0].value;
            const relevantPostThresholdPercentage = document.getElementsByName('relevantPostThresholdPercentage')[0].value;
            const cronJobsRunning = document.getElementsByName('cronJobsRunning')[0].checked;
            const gptVersion = document.getElementsByName('gptVersion')[0].value;

            // Set the gathered values to the config state
            setConfig({
                ...config,
                minimumToxicScore,
                relevantPostThresholdPercentage,
                cronJobsRunning,
                gptVersion
            });

            let result = await axios.post(`${apiUrl}/config`, {
                minimumToxicScore,
                relevantPostThresholdPercentage,
                cronJobsRunning,
                gptVersion
            });

            if(result){
                getConfig() 
            }
        } catch (error) {
            console.log('✌️error --->', error);
        }

    }

    useEffect(() => {
        getConfig();
    }, [])

    return (
        <>
            <Menu />
            <Container>
                <ColWrapper>
                    <FieldWrapper>
                        <FieldLabel>Minimum Toxic Score: </FieldLabel>
                        <FieldValue>
                            <TextField
                                variant='outlined'
                                name='minimumToxicScore'
                                onChange={handleInputChange}
                                InputProps={{
                                    style: {
                                        background: 'white',
                                        height: 30,
                                        width: 80,
                                        alignItems: 'center',
                                    },
                                }}
                                value={config?.minimumToxicScore}
                                disabled={cronJobInitStatus}
                            />
                        </FieldValue>
                    </FieldWrapper>
                    <FieldWrapper>
                        <FieldLabel>Threshold Percentage: </FieldLabel>
                        <FieldValue>
                            <TextField
                                variant='outlined'
                                name='relevantPostThresholdPercentage'
                                onChange={handleInputChange}
                                InputProps={{
                                    style: {
                                        background: 'white',
                                        height: 30,
                                        width: 80,
                                        alignItems: 'center',
                                    },
                                }}
                                value={config?.relevantPostThresholdPercentage}
                                disabled={cronJobInitStatus}
                            />
                        </FieldValue>
                    </FieldWrapper>
                    <FieldWrapper>
                        <FieldLabel>Start Cronjobs: </FieldLabel>
                        <FieldValue>
                            <Switch name={"cronJobsRunning"} checked={config?.cronJobsRunning} onChange={handleInputChange} />
                        </FieldValue>
                    </FieldWrapper>
                    <FieldWrapper>
                        <FieldLabel>GPT Version: </FieldLabel>
                        <FieldValue>
                            <TextField
                                select
                                name='gptVersion'
                                onChange={handleInputChange}
                                InputProps={{
                                    style: {
                                        background: 'white',
                                        height: 30,
                                        width: 150,
                                        alignItems: 'center',
                                    },
                                }}
                                value={config?.gptVersion}
                                disabled={cronJobInitStatus}
                            >
                                <MenuItem value='gpt-3.5-turbo'>GPT-3.5-Turbo</MenuItem>
                                <MenuItem value='gpt-4o'>GPT-4o</MenuItem>
                            </TextField>
                        </FieldValue>
                    </FieldWrapper>
                    <FieldWrapper>
                        <Button sx={{ height: 30 }} variant='contained' onClick={(e) => saveConfig(e)}>
                            Save
                        </Button>
                    </FieldWrapper>
                </ColWrapper>
            </Container>
            <Container>
                <ColWrapper>  
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell align="right">Start Time</TableCell>
                                    <TableCell align="right">End Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {config?.cronJobs?.map((cronJob, i) => (
                                    <>
                                    <TableRow
                                        key={cronJob._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="right">{i + 1}</TableCell>
                                        <TableCell align="center">{cronJob.status ? <Tooltip title={<React.Fragment>
                                            <Typography color="inherit">CronJob Health</Typography>
                                            Green: <em>All cronjobs are running smoothly.</em><br/>
                                            Yellow: <em>API rate limit has been exceeded.</em><br/>
                                            Red: <em>Errors detected in cronjobs.</em>
                                        </React.Fragment>} placement="right"><Avatar sx={{ width: 24, height: 24, bgcolor: HEALTH[cronJob.health] }}> </Avatar></Tooltip> : 'False'}</TableCell>
                                        <TableCell align="right">{cronJob.createdAt}</TableCell>
                                        <TableCell align="right">{cronJob.updatedAt !== cronJob.createdAt ? cronJob.updatedAt : null}</TableCell>
                                    </TableRow>
                                    {cronJob.status && (
                                        <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="left" colSpan={3}>Post collected: {postCollected} /
                                            Metrics collected: {postMetricCollected}</TableCell>
                                        </TableRow>
                                    )}
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ColWrapper>
            </Container>
        </>
    );
};

export default AdminSettings;
