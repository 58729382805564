import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import client from '../utils/contentfulClient';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const Container = styled.div`
    box-sizing: border-box;
    padding: 30px 25px;
    width: 100%;
`

const ArticleWrapper = styled.div`
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    margin: 10px 0 30px 0;
    padding: 25px 20px;
`
const ArticleTitle = styled.h2`

`
const ArticleDate = styled.span`
    font-size: 12px;
`

const NoContentMsg = styled.div`
    box-sizing: border-box;
    color: gray;
    font-style: italic;
    padding: 30px;
    text-align: center;
    width: 100%;
`

const Blog = () => {
    const { tag: urlTag } = useParams();
    const user = useSelector((state) => state.auth.user);
    const [tags, setTags] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);

    useEffect(() => {
        if (!urlTag) {
            getUserTags();
        }
    }, []);

    useEffect(() => {
        getBlogEntries();
    }, [tags, urlTag])

    const getUserTags = async () => {
        try {
            const res = await axios.get(`${apiUrl}/tags/getTags`);
            const filteredData = res.data.filter(item => user.tags.includes(item._id));
            setTags(filteredData);
        } catch (error) {
            console.error('Error fetching user tags', error);
        }
    }

    const getBlogEntries = async () => {
        try {
            const response = await client.getEntries({
                content_type: 'blogPost',
            });

            const items = response.items;
            // Check if sorting field exists
            const sortingFieldExists = items.some(item => item.fields.sortingOrder !== undefined);
            // Order by sortingField criteria
            if (sortingFieldExists) items.sort((a, b) => (a.fields.sortingOrder > b.fields.sortingOrder) ? 1 : -1);
            // Filter items by tags
            const userTags = tags.map(tag => tag.name)
            const filteredItems = filterBlogEntries(items, userTags, urlTag);
            setFilteredEntries(filteredItems);
        } catch (error) {
            console.error('Error fetching blog entries', error);
        }
    }

    const filterBlogEntries = (data, userTags, urlTag) => {
        return data
        .map(item => {
        const blogTags = item.metadata.tags.map(tag => tag.sys.id);
        return { ...item, blogTags };
        })
        .filter(item => 
            urlTag ? item.blogTags.includes(urlTag) :
            item.blogTags.length === 0 || item.blogTags.some(blogTag => userTags.includes(blogTag)));
    };

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <strong>{text}</strong>,
            [MARKS.ITALIC]: (text) => <em>{text}</em>,
            [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
        },
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const image = node.data.target.fields.file.url;
                const alt = node.data.target.fields.title || 'Image';
                return <img src={image} alt={alt} style={{ maxWidth: '100%', height: 'auto' }} />;
            }
        }
    };

    return (
        <Container style={{background: urlTag? '' : '#ddd'}}>
            {filteredEntries && filteredEntries.length ? filteredEntries.map((entry) => (
                <ArticleWrapper key={entry.sys.id}>
                    <ArticleTitle>{entry.fields.title}</ArticleTitle>
                    {documentToReactComponents(entry.fields.body, options)}
                    <ArticleDate>{dayjs(entry.fields.publishDate).format('h:mmA - MMM D, YYYY')}</ArticleDate>
                </ArticleWrapper>
            )) : (
                <NoContentMsg>There are no articles yet.</NoContentMsg>
            )}
        </Container>
    )
};

export default Blog;
