import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';

const Container = styled.div`
    padding: 0px 10px;
    box-sizing: border-box;
    width: 100%;
`;

const Tweet = styled.div`
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Sombreado */
    margin: 0 auto;
    max-width: 550px;
    min-width: 300px;
    width: 100%;
`;

const ToxicTweet = ({ tweetId }) => {
    const tweetRef = useRef(null);
    const isTweetLoadedRef = useRef(false);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const element = tweetRef.current;

        const loadTweet = async () => {
            try {
                if (window.twttr && element && !isTweetLoadedRef.current) {
                    setLoading(true);
                    element.innerHTML = '';
                    await window.twttr.widgets.createTweet(
                        tweetId,
                        element,
                        {
                            theme: 'light',
                        },
                    );
                    isTweetLoadedRef.current = true;
                    setLoading(false);
                    cleanUpExtraTweet();
                }
            } catch (error) {
                console.error('Failed to load tweet:', error);
                setLoading(false);
            }
        };

        loadTweet();

        const cleanUpExtraTweet = () => {
            if (element.children.length > 1) {
                element.removeChild(element.lastChild);
            }
        };

        return () => {
            if (element) {
                element.innerHTML = '';
                isTweetLoadedRef.current = false;
            }
        };
    }, [tweetId]);

    return (
        <Container>
            {isLoading && <Spinner />}
            <Tweet ref={tweetRef} />
        </Container>
    );
};

export default ToxicTweet;
