import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Menu from '../components/Menu';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Selector from '../components/Selector';
import { useLocation } from 'react-router-dom';

const HeaderContent = styled.div`
    margin-top: 30px;
`;

const Wrapper = styled.div`
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    margin: 20px;
    padding: 0px 12px 18px 12px;    
`;

const Title = styled.h2`
    font-size: 18px;
    color: #3498db;
    padding-top:15px;
`;

const InterventionsList = styled.div`
    margin-top: 10px;
`;

const MailRow = styled(({ newEngagement, selectedOption, reply, ...rest }) => <div {...rest} />)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color:${({ newEngagement, selectedOption, reply }) => (newEngagement && selectedOption === "me" && reply > 0? '#e0f7fa' : 'white')};
    cursor: pointer;
    font-weight: ${({ newEngagement, selectedOption, reply }) => (newEngagement && selectedOption === "me" && reply > 0? 'bold' : 'normal')};
    
    &:hover {
        background-color: #f1f1f1;
    }
`;

const TextRow = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
`;

const AlternativeText = styled.div`
    text-align: left;
    color: #555;
    margin-right: 25px;
`;

const RowContent = styled.div`
    flex: 5;
    text-align: left;    
`;

const EngagementText = styled.div`
    flex: 3;
    text-align: right;
    color: #888;
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const MyImpact = () => {
    const [interventions, setInterventions] = useState([]);
    const [totalInterventions, setTotalInterventions] = useState();
    const [totalEngagement, setTotalEngagement] = useState();
    const [bestInterventions, setBestInterventions] = useState({});
    const [rankingInfo, setRankingInfo] = useState({});
    const [hasNewEngagement, setHasNewEngagement] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const user = useSelector((state) => state.auth.user)
    const location = useLocation();
    const initialOption = location.state?.selectValue || 'me';
    const [selectedOption, setSelectedOption] = useState(initialOption);

    useEffect(() => {
        const fetchData = async () => {
            const url = new URL(`${apiUrl}/metrics/getImpact`);
            url.searchParams.set('page', `${pagination.pageIndex}`);
            url.searchParams.set('pageSize', `${pagination.pageSize}`);
            if (selectedOption === "me") {
                url.searchParams.set('userId', `${user._id}`);
            }
            
            await axios
                .get(url)
                .then((response) => {
                    setTotalPages(response.data.totalPages ? response.data.totalPages : 0);
                    setInterventions(response.data.data ? response.data.data : []);
                    setTotalInterventions(response.data.totalCount ? response.data.totalCount : 0);
                    setTotalEngagement(response.data.engagement.totalEngagement ? response.data.engagement.totalEngagement : 0);
                    setBestInterventions(response.data.engagement.maxEngagementIntervention ? response.data.engagement.maxEngagementIntervention : {});
                    setRankingInfo(response.data.myRanking ? response.data.myRanking : {})
                })
                .catch((error) => {
                    console.error('There was an error.', error);
                });
        };
        fetchData();
    }, [pagination.pageIndex, pagination.pageSize, selectedOption]);

    const handlePreviousPage = () => {
        setPagination(prev => ({
            ...prev,
            pageIndex: Math.max(prev.pageIndex - 1, 0)
        }));
    };

    const handleNextPage = () => {
        setPagination(prev => ({
            ...prev,
            pageIndex: Math.min(prev.pageIndex + 1, totalPages - 1)
        }));
    };

    const handleUpdateIntervention = async (intervention, index) => {
        if (intervention.engaged === true && selectedOption === "me") {
            setInterventions(prevInterventions => {
                const updatedInterventions = [...prevInterventions];
                updatedInterventions[index].engaged = false;
                return updatedInterventions;
            });
            await axios.patch(`${apiUrl}/interventions/updateIntervention`, intervention);
        }
        window.open(intervention.responseUrl, '_blank');
    };

    useEffect(() => {
        setHasNewEngagement(interventions.some(intervention => intervention.engaged));
    }, [interventions]);

    const InterventionsComponent = () => {
        return (
            <InterventionsList>
                {interventions.map((intervention, index) => {
                    const metrics = intervention?.monitorData?.metrics?.intervention || {};
                    const engagementScore = intervention?.monitorData?.metrics?.engagement || null;
                    const metricsArray = [];

                    if (metrics?.impressions) metricsArray.push(`${metrics.impressions} ${metrics.impressions === 1 ? 'impression' : 'impressions'}`);
                    if (metrics?.likes) metricsArray.push(`${metrics.likes} ${metrics.likes === 1 ? 'like' : 'likes'}`);
                    if (metrics?.comments) metricsArray.push(`${metrics.comments} ${metrics.comments === 1 ? 'reply' : 'replies'}`);
                    if (metrics?.shares) metricsArray.push(`${metrics.shares} ${metrics.shares === 1 ? 'retweet' : 'retweets'}`);
                    if (metrics?.quote) metricsArray.push(`${metrics.quote} ${metrics.quote === 1 ? 'quote tweet' : 'quote tweets'}`);
                    if (engagementScore) metricsArray.push(`engagement score: ${engagementScore}`);
                    if (metricsArray.length === 0) {
                        metricsArray.push("engagement pending");
                    }

                    const interventionText = intervention?.interventionText || "";
                    const words = interventionText.split(' ');
                    const textWithoutFirstWord = words[0].startsWith('@') ? words.slice(1).join(' ') : interventionText;

                    return (
                        <MailRow key={index} newEngagement={intervention?.engaged} reply={metrics?.comments} selectedOption={selectedOption} onClick={() => handleUpdateIntervention(intervention, index)}>
                            <AlternativeText>{new Date(intervention?.createdAt).toLocaleDateString()}</AlternativeText>

                            <RowContent>
                                "{textWithoutFirstWord}"
                                <AlternativeText style={{ marginTop: '5px' }}>
                                {selectedOption === "all" &&
                                    (intervention?.userData?.mainTwitterAccount
                                        ? intervention.userData.mainTwitterAccount
                                        : intervention?.userData?.username)
                                }
                                {selectedOption === "all" && " "}
                                in reply to {intervention?.toxicUserDetails?.username}
                                </AlternativeText>
                            </RowContent>

                            <EngagementText>
                                {metricsArray.join(', ')}
                            </EngagementText>
                        </MailRow>
                    );
                })}
            </InterventionsList>
        );
    };

    const BestInterventionComponent = () => {
        let interventionsToShow = [];

        if (selectedOption === "me" && bestInterventions?.length > 0) {
            interventionsToShow.push(bestInterventions[0]); // Mostrar solo la primera intervención
        } else if (bestInterventions?.length > 0) {
            interventionsToShow = bestInterventions; // Mostrar todas las intervenciones
        }
    
        return (
            <Wrapper>
                <Title>Best {selectedOption === "me" ? "Outcome" : "Outcomes"}:</Title>
                {interventionsToShow.map((intervention, index) => {
                    const interventionText = intervention?.interventionText || "";
                    const words = interventionText.split(' ');
                    const textWithoutFirstWord = words[0].startsWith('@') ? words.slice(1).join(' ') : interventionText;

                    return (
                        <div key={index}>
                            <strong>"{textWithoutFirstWord}"</strong>
                            <EngagementText style={{ textAlign: 'left', marginTop: '7px' }}>
                                {selectedOption === "all" &&
                                    (intervention?.userData?.mainTwitterAccount
                                        ? intervention.userData.mainTwitterAccount
                                        : intervention?.userData?.username)
                                }
                                {selectedOption === "all" && " "}
                                in reply to {intervention?.toxicUserDetails?.username}
                            </EngagementText>
                            <EngagementText style={{ textAlign: 'left', marginTop: '5px', marginBottom: '18px' }}>
                                {formatMetrics(intervention?.monitorData?.metrics?.intervention)}
                                {intervention.engagement !== undefined ? `, engagement score: ${intervention.engagement}` : ''}
                            </EngagementText>
                        </div>
                    );
                })}
            </Wrapper>
        );
    };
    
    const formatMetrics = (metrics) => {
        const metricsArray = [];
        if (metrics?.impressions) metricsArray.push(`${metrics.impressions} ${metrics.impressions === 1 ? 'impression' : 'impressions'}`);
        if (metrics?.likes) metricsArray.push(`${metrics.likes} ${metrics.likes === 1 ? 'like' : 'likes'}`);
        if (metrics?.comments) metricsArray.push(`${metrics.comments} ${metrics.comments === 1 ? 'reply' : 'replies'}`);
        if (metrics?.shares) metricsArray.push(`${metrics.shares} ${metrics.shares === 1 ? 'retweet' : 'retweets'}`);
        if (metrics?.quote) metricsArray.push(`${metrics.quote} ${metrics.quote === 1 ? 'quote tweet' : 'quote tweets'}`);
        if (metricsArray.length === 0) {
            metricsArray.push("engagement pending");
        }
    
        return metricsArray.join(', ');
    };
    

    const handleSelectedOptionChange = (value) => {
        setSelectedOption(value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            pageIndex: 0,
        }));
    };

    return (
        <>
            <Menu />
            <Selector optionChange={handleSelectedOptionChange} value={selectedOption} impact={true} />
            <HeaderContent>
                <Header title={selectedOption === "me" ? "My Impact" : "Our Impact"} />
            </HeaderContent>
                <Wrapper>
                    <Title>Total Achievement{selectedOption === "all" ? " in the Last Week" : ""}:</Title>
                    <TextRow>
                        <strong style={{ marginRight: '15px' }}>Total Interventions:</strong>{totalInterventions}
                    </TextRow>
                    <TextRow>
                        <strong style={{ marginRight: '15px' }}>Total Engagement Score:</strong>{totalEngagement}
                    </TextRow>
                </Wrapper>
            
            <BestInterventionComponent />

            {selectedOption === "me" &&
                <Wrapper>
                    <Title>Ranking:</Title>
                    <TextRow>
                        <strong style={{ marginRight: '15px' }}>Rank:</strong>
                        {rankingInfo.userRank === 1 ? '1st' : rankingInfo.userRank === 2 ? '2nd' : rankingInfo.userRank === 3 ? '3rd' : `${rankingInfo.userRank}th`} out of {rankingInfo.totalUsers} users
                    </TextRow>
                </Wrapper>
            }

            <Wrapper>
                <Title> {selectedOption === "me"? "Interventions:" : "Recent Interventions"} </Title>
                {hasNewEngagement && selectedOption === "me" && (
                    <TextRow>
                        <ErrorOutlineOutlinedIcon style={{ color: '#3498db', marginRight: '5px', marginTop: '12px' }}>
                        </ErrorOutlineOutlinedIcon>
                        <p>Click on highlighted rows to see the engagement with your post. Liking and politely interacting with replies and retweets/quote tweets of your message may further increase your reach.</p>
                    </TextRow>
                )}
                <InterventionsComponent />

                <PaginationContainer>
                    <Button disabled={pagination.pageIndex === 0 || interventions.length === 0} onClick={handlePreviousPage}>
                        previous
                    </Button>
                    <Button disabled={pagination.pageIndex === totalPages - 1 || interventions.length === 0} onClick={handleNextPage}>
                        next
                    </Button>
                </PaginationContainer>
            </Wrapper>
        </>
    );
};

export default MyImpact;
