import { useEffect, useState } from 'react';
import styled from 'styled-components';
import OtherPost from '../components/OtherPost';
import PossibleAnswers from '../components/PossibleAnswers';
import PostColumn from '../components/PostColumn';
import Menu from '../components/Menu';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { useSelector } from 'react-redux';
import Modal from '../components/Modal';
import { Button } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex:1;
  justify-content:space-between;
  background: #f2f2f2;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ColWrapper = styled.div`
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.5);
  padding-top: 12px;
  border-right: 0.5px solid #3498db;
  padding-bottom: 30px;
  box-sizing: border-box;
  min-width: 320px;
  width: ${({ width }) => width};
  @media (max-width: 768px) {
    width: 100%;
  }
`

const BlockedMessage = styled.div`
  width: 100%;
  font-size: 16px;
  margin: 20px 0;
`

const TweetDashboard = () => {
  const location = useLocation();
  // Added default postId so the page will load in any case, delete when the real homepage is done
  const postId = location.pathname.split("/")[2];
  const [post, setPost] = useState({});
  const [toxicUserId, setToxicUserId] = useState('');
  const [userPostsList, setUserPostsList] = useState([]);
  const [interventionList, setInterventionList] = useState ([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const [showButtonContext, setShowButtonContext] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [content, setContent] = useState('');
  const [context, setContext] = useState([]);
  const filters = useSelector((state) => state.filters?.filters);
  const myUserId = useSelector((state) => state.auth.user._id);
  const [postsIds, setPostsIds] = useState ();
  const user = useSelector((state) => state.auth.user);
  const blockingUsers = useSelector((state) => state.auth.user?.blockedUsers) ?? [];
  const blockingUserIds = blockingUsers.map(user => user.userId);
  const [isBlocked, setIsBlocked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [toxicUserIntervened, setToxicUserIntervened] = useState({isIntervened: true})
 

  const getMainPost = async () => {
    try {
      const res = await axios.get(`${apiUrl}/posts/getSinglePost/${postId}`);
      setPost(res.data);
      setToxicUserId(res.data.userDetails?._id);
      checkBlockedUser(res.data);
    } catch (err) {
      console.error('There was an error.', err);
    }
  }

  const checkBlockedUser = (post) => {
    const postId = post.userDetails?._id;
    if (blockingUserIds.includes(postId)) {
        setIsBlocked(true);
        setOpenModal(true);
    }
  }
  
  const getUserPostsList = async() => {
    if (toxicUserId) {
      try {
        const res = await axios.get(`${apiUrl}/posts/getPostsByUser/${toxicUserId}`, {
          params: {
            currentPostId: postId,
          }
        })
        setUserPostsList(res.data)
        setLoading(false)
      } catch(err) {
        console.error('Failed to fetch user posts: ', err)
      }
    }
  }

  const getInterventionList = async() => {
    if (postId) {
      try {
        const res = await axios.get(`${apiUrl}/interventions/getInterventionsByPost`, {
          params: { postId }
        });
        setInterventionList(res.data)
        setLoading(false)
      } catch(err) {
        console.error('Failed to fetch Intervention list: ', err)
      }
    }
  }

  const clickIntervention = (interventionUrl) => {
    window.open(interventionUrl, '_blank');
}

  const getPostsIds = async () =>{
    let postIndex;
    let arrayIds;
    try {
    //Entra si viene de otro TweetDashboard
    if (location.state?.postsIds) {
          setPostsIds(location.state.postsIds);   
          arrayIds = location.state.postsIds;
    } //Entra si viene de la Home
    else if (location.state !== null){
            setPostsIds(location.state);
            arrayIds = location.state;
    } //Entra si viene de url
    else {
        const response = await axios.get(`${apiUrl}/posts/getPostsIdsByFilters`, {
            params: {
              selectedPeriod: filters?.selectedPeriod ?? 'yesterday',
              addedManually: filters?.addedManually ?? false,
              intervened: filters?.intervened ?? 'notIntervened',
              minimumToxicScore: filters?.minimumToxicScore ?? 5,
              partisanship: filters?.partisanship ?? 'all',
              selectedToxicUser: filters?.selectedToxicUser ?? 'all',
              selectedTopics: filters?.selectedTopics ?? [],
              userId: myUserId,
              blockingUsers: filters?.blockingUsers ?? []
            }
        });
        setPostsIds( response.data );  
        arrayIds = response.data;
    }
       
        //Busca el indice del post en el array, corrobora que esté o que no sea el último para mostrar el boton de siguiente
        
        postIndex = arrayIds?.findIndex(item => item === postId);
        if (postIndex >= arrayIds?.length - 1 || postIndex === -1 || (!postIndex && postIndex!==0)) {
            setShowButton(false);
        } else {
            setShowButton(true);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };  
  
    useEffect(() => {
        getMainPost();
        //getUserPostsList();
        isToxicUserIntervened();
        getInterventionList();
        setShowButtonContext(true);
        checkBlockedUser(post)
    }, [postId, toxicUserId]);

    useEffect(() => {
        getPostsIds();
    }, [postId]);
  
  const handleClick = (postId) => {
    navigate(`/tweet-dashboard/${postId}`, { state: { postsIds } })
  }

  //Funcion para pasar al siguiente Tweet
  const handleNextTweet = async () => {     
    //Informa la posicion del post en el array
    let postIndex = postsIds?.findIndex(item => item === postId)

    //incremento el indice para pasar al siguiente Id
    postIndex++;
    //Nuevo id del siguiente tweet
    let nextTweetId = postsIds[postIndex];
    //Si hay nuevo id, lleva al siguiente tweet
    if (nextTweetId) {
        handleClick(nextTweetId);
    } else {
        setShowButton(false);
    }
  };

  //Funcion para dar contexto al Tweet
  const handleTweetContext = async () => {
      setShowButtonContext(false);
      setShowSpinner(true);
      //si el post ya tiene contexto no lo vuelve a generar
      try {
          const response = await axios.post(`${apiUrl}/posts/generateContext`, { post });
          const data = response.data;
          if (data.context) {
            setContent(data.content);
            setContext(data.context);
          } else  {
            setContent(data);
          }
      } catch (error) {
          console.error("Error fetching context:", error);
      }
      setShowSpinner(false);
  };

  //Function to get if a post was intervened
  const isToxicUserIntervened = async () => {
    if(!toxicUserId || !myUserId) return;
    try {
        const res = await axios.get(`${apiUrl}/toxicUsers/${toxicUserId}/isIntervenedBy/${myUserId}`);
        if (res.data) {
            setToxicUserIntervened(res.data);
        }
    } catch (err) {
        console.error('Failed fetching interventions: ', err);
    }
  };

  return (
    <>
      <Menu />
      {loading ? (
        <Spinner/>
      ) : (
        <Container>
          <ColWrapper width="40%">
            <PostColumn
              post={post}
              handleTweetContext={handleTweetContext}
              showButtonContext={showButtonContext}
              content={content}
              context={context}
              showSpinner={showSpinner}
              toxicUserIntervened={toxicUserIntervened}
            />
          </ColWrapper>
          <ColWrapper width="30%">
            {toxicUserId && (
              <PossibleAnswers
                postId={postId}
                post={post}
                twitterPostId={post?.twitterPostId}
                postsIds={postsIds}
                handleNextTweet={handleNextTweet}
                showButton={showButton}
                toxicUserId={toxicUserId}
                isBlocked={isBlocked}
              />
            )}
          </ColWrapper>
          <ColWrapper width="30%">
            <OtherPost interventionList={interventionList} />
          </ColWrapper>
        </Container>)}
        {openModal && (
          <Modal
          openModal={openModal}
          showActions={false}
          maxwidth={'400px'}>
            <BlockedMessage>
              <h3>This user has blocked you.</h3>
            </BlockedMessage>
            <Button variant="contained" onClick={() => setOpenModal(false)}>Close</Button>
          </Modal>
        )}
    </>
  )
}

export default TweetDashboard
