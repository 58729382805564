import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl } from '../utils/common';

export const userLogin = createAsyncThunk(
    'auth/login',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${apiUrl}/auth/login/success`,
                { withCredentials: true }
            );
            return data.user;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
