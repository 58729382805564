import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import Header from './Header';
import { useEffect, useState } from 'react';

const ChartContainer = styled.div`
    box-sizing: border-box;
    height: 450px;
    margin-top: 25px;
    padding: 0 15px;
`;

const NoDataMessage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TooltipContainer = styled.div`
    background-color: white !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`

const TooltipLabel = styled.p`
    font-weight: 600;
    margin: 0 0 5px;
`

const VerticalChart = ({title, data}) => {
    const [formattedData, setFormattedData] = useState([]);
    const COLORS = ['#add8e6', '#b19cd9', '#ffb6a7', '#ffd966', '#8fd8a8', '#fde8cd', '#f4a6a7', '#89c4f4', '#c3c6c9'];

    useEffect(() => {
        if (data && data.length > 0) {
            const hasEngagement = data.some(item => item.engagementPercentage && item.engagementPercentage !== 0);

            if (hasEngagement) {
                const formatted = data.reduce((acc, curr) => {
                    acc[0][curr.name] = curr.percentage;
                    acc[1][curr.name] = curr.engagementPercentage;
                    return acc;
                }, [{ name: 'Interventions' }, { name: 'Engagement' }]);
                setFormattedData(formatted);
            } else {
                setFormattedData([]);
            }
        }
    }, [data]);
    
    return (
        <>
            <Header titleFontSize={21} title={title} />
            <ChartContainer>
                {formattedData.length === 0 ? (
                    <NoDataMessage>No data to display.</NoDataMessage>
                ) : (
                    <ResponsiveContainer width='100%' height='100%'>
                    <BarChart width={400} height={300} data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 100]} tickFormatter={(tick) => `${tick.toFixed(0)}%`} />
                    <Tooltip
                        content={({ payload, label }) => {
                            if (payload && payload.length) {
                                const reversedPayload = [...payload].reverse();
                                return (
                                    <TooltipContainer>
                                        <TooltipLabel className="label">{`${label}`}</TooltipLabel>
                                        {reversedPayload.map((entry, index) => (
                                            <div key={`item-${index}`} style={{ color: entry.color }}>
                                                {`${entry.name}: ${entry.value.toFixed(2)}%`}
                                            </div>
                                        ))}
                                    </TooltipContainer>
                                );
                            }
                            return null;
                        }}
                    />
                    {Object.keys(formattedData[0] || {}).filter(key => key !== 'name').map((key, index) => (
                        <Bar key={index} dataKey={key} stackId="a" fill={COLORS[index % COLORS.length]} />
                    ))}
                    </BarChart>
                    </ResponsiveContainer>
                )}
            </ChartContainer>
        </>
    );
};

export default VerticalChart;
