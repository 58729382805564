import { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Header from '../components/Header';
import Selector from '../components/Selector';
import Menu from '../components/Menu';
import { apiUrl } from '../utils/common';
import { useSelector } from 'react-redux';
import Spinner from '../components/Spinner';
import Chart from '../components/Chart';


const Container = styled.div`
    background: #f2f2f2;
    width: 100%;
    height: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
`;

const ColWrapper = styled.div`
    padding: 30px 0;
    box-sizing: border-box;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const AdminDashboard = () => {

    const [periodEvolution, setPeriodEvolution] = useState('last week');
    const [postEvolution, setPostEvolution] = useState(null)
    const [toxicUserEvolution, setToxicUserEvolution] = useState(null)
    const [interventionsEvolution, setInterventionsEvolution] = useState(null)
    const [loading, setLoading] = useState(true);
    const [isFirstTime, setIsFirstTime] = useState(true);
    const userId = useSelector((state) => state.auth.user._id);


    const handlePeriodEvolutionChange = (value) => {
        setPeriodEvolution(value);
    };


    const getPostEvolution = async () => {
        await axios
        .get(`${apiUrl}/metrics/getPostsEvolution`, {
            params: {
                selectedPeriod: periodEvolution,
                userId: userId,
            },
        })
        .then((response) => {
            setPostEvolution(response.data)
        })
        .catch((error) => {
            console.error('There was an error.', error);
        });
    }

    const getToxicUserEvolution = async () => {
        await axios
        .get(`${apiUrl}/metrics/getToxicUserEvolution`, {
            params: {
                selectedPeriod: periodEvolution,
                userId: userId,
            },
        })
        .then((response) => {
            setToxicUserEvolution(response.data)
        })
        .catch((error) => {
            console.error('There was an error.', error);
        });
    }

    const getPostIntervenedEvolution = async () => {
        await axios
        .get(`${apiUrl}/metrics/getPostIntervenedEvolution`, {
            params: {
                selectedPeriod: periodEvolution,
                userId: userId,
            },
        })
        .then((response) => {
            setInterventionsEvolution(response.data)
        })
        .catch((error) => {
            console.error('There was an error.', error);
        });
    }

    const getMetricsData = async () => {
        if(isFirstTime) setLoading(true); 
        await getPostEvolution();
        await getToxicUserEvolution();
        await getPostIntervenedEvolution();
        if(isFirstTime) setLoading(false); 
        setIsFirstTime(false);
    }

    useEffect(() => {
        getMetricsData();
    }, [periodEvolution])

    return (
        <>
            <Menu />
            {loading ? (
                <Spinner/>
            ) : (
                    <>
                        <Selector optionChange={handlePeriodEvolutionChange} value={periodEvolution} />
                        <Container>
                            <ColWrapper style={{ width: '33%' }}>
                                <Header title={'Toxic Posts'} />
                                <Chart data={postEvolution} grid dataKey='count' stroke='green' />
                            </ColWrapper>
                            <ColWrapper style={{ width: '33%' }}>
                                <Header title={'Toxic users'} />
                                <Chart data={toxicUserEvolution} grid dataKey='count' stroke='green' />
                            </ColWrapper>
                            <ColWrapper style={{ width: '33%' }}>
                                <Header title={'Posts Intervened'} />
                                <Chart data={interventionsEvolution} grid dataKey='count' stroke='green' />

                            </ColWrapper>
                        </Container>
                    </>
            )}
        </>
    );
};

export default AdminDashboard;
