import { createClient } from 'contentful';

const apiKey = process.env.REACT_APP_CONTENTFUL_API_KEY;
const spaceID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const SPACE_ID = spaceID
const ACCESS_TOKEN = apiKey;
const ENVIRONMENT = 'master'

const client = createClient({
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN,
    environment: ENVIRONMENT
})

export default client;