import styled from 'styled-components';
import Menu from '../components/Menu';
import Header from '../components/Header';
import { Button, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Modal from '../components/Modal';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import axios from 'axios';
import { apiUrl } from '../utils/common';

const Container = styled.div`
    max-width: 100%;
    margin: 0;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
`;
const MainContentWrapper = styled.div`
    text-align: center;
    margin-top: 40px;
    padding: 0 50px 50px 50px;
`

const Styles = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [data, setData] = useState([]);
    const [rowItem, setRowItem] = useState({});

    const getStyles = async () => {
        try {
            const res = await axios.get(`${apiUrl}/writingStyles/getStyles`);
            setData(res.data);
        } catch(err) {
            console.error('Failed to fetch styles: ', err)
        }
    }

    useEffect(() => {
        getStyles();
    }, [])

    const handleCloseModal = () => {
        setOpenModal(false);
        setOpenDeleteConfirmModal(false);
        setName('');
        setDescription('');
    };

    const handleSubmit = async () => {
        const newStyle = {
            name: name,
            description: description,
        }

        try {
            const res = await axios.post(`${apiUrl}/writingStyles/createStyle`, newStyle)
            if (res) {
                setOpenModal(false);
                setName('');
                setDescription('');
                getStyles();
            }
        } catch(err) {
            console.error('Failed to create new style: ', err)
        }
    };

    const handleEdit = async (row) => {
        setOpenModal(true);
        setEditMode(true);
        setName(row.original.name);
        setDescription(row.original.description);
        setRowItem(row.original);
    }

    const updateStyle = async() => {
        const updatedStyle = {
            name: name,
            description: description,
        }

        try {
            const res = await axios.patch(`${apiUrl}/writingStyles/updateStyle/${rowItem._id}`, updatedStyle)
            if (res) {
                setOpenModal(false);
                setName('');
                setDescription('');
                getStyles();
            }
        } catch(err) {
            console.error('Failed to update style: ', err)
        }
    }

    const handleOpenDeleteConfirm = (row) => {
        setOpenDeleteConfirmModal(true)
        setRowItem(row.original)
    }

    const deleteStyle = async() => {
        try {
            const res = await axios.delete(`${apiUrl}/writingStyles/deleteStyle/${rowItem._id}`)
            if (res) {
                getStyles();
                setOpenDeleteConfirmModal(false);
            }
        } catch(err) {
            console.error('Failed to delete style: ', err)
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Style Name'
            },
            {
                accessorKey: 'description',
                header: 'Style Description'
            },
        ],
        []
    )

    const table = useMaterialReactTable({
        data,
        columns,
        enableSorting: false,
        enableFullScreenToggle: false,
        enableFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableRowActions: true,
        enableColumnActions: false,
        initialState: {
            density: 'comfortable',
            columnPinning: {
                right: ['mrt-row-actions'],
            }
        },
        renderRowActions: ({ row }) => (
            <>
                <Button
                    variant="contained"
                    onClick={() => handleEdit(row)}
                >
                    Edit
                </Button>
                <Button
                    sx={{ml: 1}}
                    variant="contained"
                    onClick={() => handleOpenDeleteConfirm(row)}
                >
                    Delete
                </Button>
            </>
        )
    });

    return (
        <div>
            <Menu />
            <Container>
                <Header title='Writing styles' />
                <MainContentWrapper>
                    <Button sx={{mb: 4, width: 150}} onClick={() => {setOpenModal(true); setEditMode(false)}} size='medium' variant='contained'>
                        Add style
                    </Button>
                    <MaterialReactTable table={table}></MaterialReactTable>
                </MainContentWrapper>
                <Modal
                    openModal={openModal}
                    title={editMode ? 'Edit Style' : 'Add Style'}
                    showActions={true}
                    buttonAction={editMode ? 'Edit' : 'Submit'}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={editMode ? updateStyle : handleSubmit}
                    isDisabled={!name || !description || (name === rowItem.name && description === rowItem.description) }
                >
                    <TextField
                        label='Name'
                        type='text'
                        variant='outlined'
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                        fullWidth
                    />
                    <TextField
                        label='Description'
                        type='text'
                        variant='outlined'
                        sx={{mt: 2, mb: 2}}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        required
                        fullWidth
                    />
                </Modal>
                <Modal
                    openModal={openDeleteConfirmModal}
                    subtitle={`Are you sure you want to delete this style?`}
                    showActions={true}
                    buttonAction={'Delete'}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={deleteStyle}
                />
            </Container>
        </div>
    );
};

export default Styles;