import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
`;

const LoginFailed = () => {
  return (
    <Container>
        <h2>
        You are not allowed to sign-in. <br />
        Please, contact the admin.
        </h2>
        <Link to="/login">Go back to login page</Link>
    </Container>
  )
}

export default LoginFailed