import styled from 'styled-components';
import Menu from '../components/Menu';
import Header from '../components/Header';
import { Button, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Modal from '../components/Modal';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import axios from 'axios';
import { apiUrl } from '../utils/common';

const Container = styled.div`
    max-width: 100%;
    margin: 0;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
`;
const MainContentWrapper = styled.div`
    text-align: center;
    margin-top: 40px;
    padding: 0 50px 50px 50px;
`
const StyledButton =styled(Button)`
    width: 150px;
`

const Topics = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [data, setData] = useState([]);
    const [rowItem, setRowItem] = useState({});

    const getTopics = async () => {
        try {
            const res = await axios.get(`${apiUrl}/topics`);
            setData(res.data);
        } catch(err) {
            console.error('Failed to fetch topics: ', err)
        }
    }

    useEffect(() => {
        getTopics();
    }, [])

    const handleCloseModal = () => {
        setOpenModal(false);
        setOpenDeleteConfirmModal(false);
        setName('');
        setDescription('');
    };

    const handleSubmit = async () => {
        const newTopic = {
            name: name,
            description: description,
        }

        try {
            const res = await axios.post(`${apiUrl}/topics`, newTopic)
            if (res) {
                setOpenModal(false);
                setName('');
                setDescription('');
                getTopics();
            }
        } catch(err) {
            console.error('Failed to create new topic: ', err)
        }
    };

    const handleEdit = async (row) => {
        setOpenModal(true);
        setEditMode(true);
        setName(row.original.name);
        setDescription(row.original.description);
        setRowItem(row.original);
    }

    const updateTopic = async() => {
        const updatedTopic = {
            name: name,
            description: description,
        }

        try {
            const res = await axios.patch(`${apiUrl}/topics/${rowItem._id}`, updatedTopic)
            if (res) {
                setOpenModal(false);
                setName('');
                setDescription('');
                getTopics();
            }
        } catch(err) {
            console.error('Failed to update topic: ', err)
        }
    }

    const handleOpenDeleteConfirm = (row) => {
        setOpenDeleteConfirmModal(true)
        setRowItem(row.original)
    }

    const deleteTopic = async() => {
        try {
            const res = await axios.delete(`${apiUrl}/topics/${rowItem._id}`)
            if (res) {
                getTopics();
                setOpenDeleteConfirmModal(false);
            }
        } catch(err) {
            console.error('Failed to delete topic: ', err)
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Topic Name'
            },
            {
                accessorKey: 'description',
                header: 'Topic Description'
            },
        ],
        []
    )

    const table = useMaterialReactTable({
        data,
        columns,
        enableSorting: false,
        enableFullScreenToggle: false,
        enableFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableRowActions: true,
        enableColumnActions: false,
        initialState: {
            density: 'comfortable',
            columnPinning: {
                right: ['mrt-row-actions'],
            }
        },
        renderRowActions: ({ row }) => (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(row)}
                >
                    Edit
                </Button>
                <Button
                    sx={{ml: 1}}
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenDeleteConfirm(row)}
                >
                    Delete
                </Button>
            </>
        )
    });

    return (
        <div>
            <Menu />
            <Container>
                <Header title='Topics' />
                <MainContentWrapper>
                    <StyledButton sx={{mb: 4}} onClick={() => {setOpenModal(true); setEditMode(false)}} size='medium' variant='contained'>
                        Add topic
                    </StyledButton>
                    <MaterialReactTable table={table}></MaterialReactTable>
                </MainContentWrapper>
                <Modal
                    openModal={openModal}
                    title={editMode ? 'Edit Topic' : 'Add topic'}
                    showActions={true}
                    buttonAction={editMode ? 'Edit' : 'Submit'}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={editMode ? updateTopic : handleSubmit}
                    isDisabled={!name || !description || (name === rowItem.name && description === rowItem.description) }
                >
                    <TextField
                        label='Name'
                        type='text'
                        variant='outlined'
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                        fullWidth
                    />
                    <TextField
                        label='Description'
                        type='text'
                        variant='outlined'
                        sx={{mt: 2, mb: 2}}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        required
                        fullWidth
                    />
                </Modal>
                <Modal
                    openModal={openDeleteConfirmModal}
                    subtitle={`Are you sure you want to delete this topic?`}
                    showActions={true}
                    buttonAction={'Delete'}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={deleteTopic}
                />
            </Container>
        </div>
    );
};

export default Topics;
