import React from 'react';
import styled from 'styled-components';

const BarWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 10px;
  border-radius: 10px;
  background-color: #ccc;
  overflow: hidden;
  margin: 5px 0px;
`;

const ToxicityBarFill = styled(({ score, typeScore, ...rest }) => <div {...rest} />)`
  position: relative;
  height: 100%;
  ${(props) => getFillWidthandColor(props.score, props.typeScore)}
`;

const Line = styled.div`
  position: absolute;
  height: 100%;
  width: 0.2px;
  background-color: black;
`;

const getFillWidthandColor = (score, typeScore) => {
  if (typeScore === 'GptScore') {
    if (score >= -10 && score < 0) {
      const fillPercentage = (score + 10) * (100 / 20); 
      return `
        width: ${fillPercentage / 4}%; 
        background-color: #99cc66;
      `;
    } else if (score === 0) {
      return `
        width: 50%;
        background-color: #eff700;
      `;
    } else if (score > 0 && score <= 10) {
      const fillPercentage = score * 5; 
      return `
        width: ${49 + fillPercentage}%; 
        background-color: ${score < 5 ? '#ffa500' : '#cc6666'};
      `;
    }

  } else {
    if (score === 0) {
      return `
        width: 0%;
        background-color: none;
      `;
    } else if (score > 0 && score <= 10) {
      const percentage = score * 10; 
      return `
        width: ${percentage}%;
        background-color: ${score < 5 ? '#ffa500' : '#cc6666'};
      `;
    }
  }
  
  return `
    width: 0%;
    background-color: none;
  `;
};

const ToxicityBar = ({ score, typeScore }) => {
  return (
    <BarWrapper>
      <ToxicityBarFill score={score} typeScore={typeScore}>
        {<Line style={{ left: '15px' }} />}
        {<Line style={{ left: '30px' }} />}
        {<Line style={{ left: '45px' }} />}
        {<Line style={{ left: '60px' }} />}
        {<Line style={{ left: '75px' }} />}
        {<Line style={{ left: '90px' }} />}
        {<Line style={{ left: '105px' }} />}
        {<Line style={{ left: '120px' }} />}
        {<Line style={{ left: '135px' }} />}
      </ToxicityBarFill>
    </BarWrapper>
  );
};

export default ToxicityBar;