const formatText = text => {
    let sentences = text.split('.').map(sentence => sentence.trim() + '.');

    if (sentences[sentences.length - 1] === '.') {
        sentences.pop(); 
    } else {
        sentences[sentences.length - 1] = sentences[sentences.length - 1].slice(0, -1); 
    }

    return sentences.join('\n\n');
};

export default formatText;